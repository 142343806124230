import axios, { AxiosRequestConfig, AxiosResponse, RawAxiosResponseHeaders } from 'axios';
import { injectable } from "inversify";
import { ILocalStore } from '../../../core/utilities/LocalStore';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { IDataService } from "./abstraction/IDataService";
import { history } from './History';
import { TelemetryLogger } from '../../../components/Logger/AppInsights';
import { ICustomProperties } from '@microsoft/applicationinsights-web';
import { API_BASE_URL } from '../../../utils/constants';
import { config } from 'process';

// const localStore = container && container.get<ILocalStore>(TYPES.ILocalStore); //Need to uncomment
export const inMemoryToken: Map<string, string> = new Map();
export let inMemoryControllerGuid: string = "";

@injectable()
export class AxiosDataService implements IDataService {
    private _baseUri: string = API_BASE_URL;
    private logger: TelemetryLogger;
    private clientId: string = "";
    private actionName: string = "";
    private requestInformation: ICustomProperties = {};
   

    // private _pageSize: number=10;
    constructor(clientId?: string, initializeResponseInterceptor?: boolean) {
        //if (clientId && localStore.get(clientId)) {
        //    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStore.get(clientId);
        //}
       
        this.clientId = clientId ? clientId : "";
        this.requestInformation = { clientId: clientId };

        this.logger = TelemetryLogger.getInstance();

        if (clientId && inMemoryToken.has(clientId)) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + inMemoryToken.get(clientId);
        }

        let self = this;
        axios.defaults.withCredentials = true;
        axios.interceptors.request.use(function (config: AxiosRequestConfig) {  
            let cookieValue = null;
            let name = 'XSRF-TOKEN';
            if (document.cookie && document.cookie !== '') {
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            if(cookieValue)
            {
                config.headers["X-XSRF-TOKEN"] = cookieValue
            }                           
            if (clientId) {
                config.headers["CONTROLLER-GUID"] = inMemoryControllerGuid;
            }
            return config;
        }, function (error: any) {
            return Promise.reject(error);
      });

        if (initializeResponseInterceptor) {

            axios.interceptors.response.use(function (response: any) {
                self.actionName = response?.config.url;                                
                if (response.status === 401) {
                    //redirect to login url
                    // localStore && localStore.remove('loggedIn');
                    history.push('/invalid');
                    self.logger.trackWarning(`${self.actionName} failed for client: ${self.clientId}, error: UnAuthorized`, { "ClientId": self.clientId });
                    throw new Error('UnAuthorized');
                }
                else if (response.status === 200) {
                   if (response?.data === 'invalidlink') {
                        history.push('/invalid');
                        throw new Error('Invalid link');
                    }
                    self.logger.trackTrace(`${self.actionName} succeeded for client: ${self.clientId}`, self.requestInformation);
                }
                return response;
            }, function (error: any) {
                if (!error.config?.__isRetryRequest && error.response) {
                    error.config.__isRetryRequest = true;
                    self.actionName = error.response.config?.url;

                    if (error.response.status == "400" || error.response.status == "401" || error.response.status == "403") {
                        self.logger.trackWarning(`${self.actionName} failed for client: ${self.clientId}, response: ${error.response.data}, errorCode: ${error.response.status}, errorMessage: ${error.message}`, { "ClientId": self.clientId });
                        // localStore && localStore.remove('loggedIn');
                        history.push('/invalid');
                    }
                    else if (error.response.status == "307") {
                        self.logger.trackWarning(`${self.actionName} failed for client: ${self.clientId}, response: ${error.response.data}, errorCode: ${error.response.status}, errorMessage: ${error.message}`, { "ClientId": self.clientId });
                        window.location.href = error.response.data;
                    }
                    else {
                        self.logger.trackError(`Request failed for ${self.actionName}, client: ${self.clientId}, response: ${error.response.data}, errorCode: ${error.response.status}, errorMessage: ${error.message}, error: ${JSON.stringify(error)}`, { "ClientId": self.clientId });
                    }
                } else {
                    self.actionName = error.response?.config.url ?? error.config?.url;
                    if (error.message === "Network Error" || error.code === "ECONNABORTED") {
                        self.logger.trackWarning(`Invalid response ${self.actionName} failed for client: ${self.clientId}, errorMessage: ${error.message}`, { "ClientId": self.clientId });
                    } else {
                        self.logger.trackError(`Invalid response ${self.actionName} failed for client: ${self.clientId}, errorMessage: ${error.message}, error: ${JSON.stringify(error)}`, { "ClientId": self.clientId });
                    }
                }
                return Promise.reject(error);
            });
        }
     }




    set(baseUri: string, pageSize: number) {
        this._baseUri = baseUri;
        //this._pageSize = pageSize;
    }

    appendCustomProperties(requestParams: ICustomProperties) {

        for (const key in requestParams) {

            this.requestInformation[key] === undefined && (this.requestInformation[key]=requestParams[key]);

        }
        return;
    }

    getPaged<T>(page: number, uriPart?: string | undefined, requestParams?: ICustomProperties): any {


        this.requestInformation.uri = uriPart ? uriPart : "";

        requestParams && this.appendCustomProperties(requestParams);

        return axios.get<T>(this._baseUri + uriPart);
    }

    get<T>(uriPart?: string | undefined, data?: any, disableCache?: boolean, requestParams?: ICustomProperties): any {

        if (disableCache) {
            axios.defaults.headers['Pragma'] = 'no-cache';
        }
        this.requestInformation.uri = uriPart ? uriPart : "";
        requestParams && this.appendCustomProperties(requestParams);

         return axios.get<T>(this._baseUri + uriPart, data);
       
    }

    post<T>(uriPart: string | undefined, data?: any, config?: AxiosRequestConfig, mapResult?: boolean, requestParams?: ICustomProperties): any {
        this.requestInformation.uri = uriPart ? uriPart : "";
        requestParams && this.appendCustomProperties(requestParams);
        return axios.post<T>(this._baseUri + uriPart, data, config);
    }

    postJson<T>(data: any, uriPart?: string | undefined, config?: any, mapResult?: boolean | undefined, requestParams?: ICustomProperties): any {
        this.requestInformation.uri = uriPart ? uriPart : "";
        requestParams && this.appendCustomProperties(requestParams);
        return axios.post<T>(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'application/json;utf-8' } });
    }

    put<T>(uriPart: string | undefined, data?: any, config?: AxiosRequestConfig, mapResult?: boolean, requestParams?: ICustomProperties): any {
        this.requestInformation.uri = uriPart ? uriPart : "";
        requestParams && this.appendCustomProperties(requestParams);
        return axios.put<T>(this._baseUri + uriPart, data, config);
    }
    putJson<T>(data: any, uriPart?: string | undefined, config?: any, mapResult?: boolean | undefined, requestParams?: ICustomProperties): any {
        this.requestInformation.uri = uriPart ? uriPart : "";
        requestParams && this.appendCustomProperties(requestParams);
        return axios.put<T>(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'application/json;utf-8' } });
    }

    delete(id: number, uriPart?: string | undefined, requestParams?: ICustomProperties): any {
        this.requestInformation.uri = uriPart ? uriPart : "";
        requestParams && this.appendCustomProperties(requestParams);
        return axios.delete(this._baseUri + uriPart);

    }

    deleteExtended(data: any, uriPart?: any): any {
        throw new Error("Method not implemented.");
    }
}
export function initializeAxios(clientId?: string, initializeResponseInterceptor?: boolean): AxiosDataService { return new AxiosDataService(clientId, initializeResponseInterceptor); };

export function storeTokenInMemory(clientId: string, token: string) { inMemoryToken.set(clientId, token); };

export function storeControllerIdInMemory(controllerId: string) { inMemoryControllerGuid = controllerId; };
