import Footer from "src/components/Layout/Footer";
import OtpInputControl from "../Controls/OTPInputControl";
import React from "react";
import HeaderComp from "src/components/Header/HeaderComp";
import Skeleton from "react-loading-skeleton";
import { SvgIconAccessCode } from "../Icons/BrandIcons";
import { ButtonComponent } from "cp-common-ui-components";

interface OTPMobileProps {
  match: any;
  history: any;
  headerInfoState: any;
  OTPPageState: any;
  otpVal: string;
  continueState: boolean;
  isInitialOTPSent: boolean;
  otpError: {
    error: boolean;
    message: string;
  };
  handleOTPChange: (otp: string) => void;
  requestCode: () => void;
  verifyOTP: () => void;
}

export class OTPMobile extends React.Component<OTPMobileProps, any> {
  continueRef: any = null;

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {}

  render() {
    let continueClass = "button-primary button-medium rounded-pill disabled float-right";
    if (this.props.continueState) {
      continueClass = "button-primary button-medium rounded-pill float-right";
      this.continueRef.focus();
    }

    let phoneNo: string = this.props.OTPPageState.mobileNo;
    let lastNum: string = "";

    if (phoneNo && phoneNo.length !== 0) {
      lastNum = "{xxx}xxx-" + phoneNo.substr(6);
    }

    return (
      <>
        <div className="layout-container d-flex align-items-center justify-content-center">
          <div className="row">
            <div className="col">
              <div className="row justify-content-center">
                <SvgIconAccessCode />
              </div>
              <div className="row justify-content-center margin-top-20">
                <h5 className="otp-page-title">Request Access Code</h5>
              </div>
              <div className="row justify-content-center margin-top-10">
                {!this.props.isInitialOTPSent ? (
                  <div
                    className="margin-top-10 text-center"
                    data-test-auto="ABDA293F-1EE9-4F6C-8958-CDA18D7C4FD9"
                    style={{ width: "65%" }}
                  >
                    <p>
                      We will send you a One-Time Access Code to your mobile
                      number.
                    </p>
                  </div>
                ) : (
                  <div
                    className="margin-top-10 text-center"
                    data-test-auto="51B29EAF-4CE6-41BD-8B2D-F02652B09E41"
                    style={{ width: "65%" }}
                  >
                    <p>
                      {" "}
                      A One-Time Access Code has been sent to your mobile
                      number: {lastNum}{" "}
                    </p>
                    <p style={{ color: "#CC4A43" }}>Expires in 20 minutes.</p>
                  </div>
                )}
              </div>
              {!this.props.isInitialOTPSent && (
                <div className="row justify-content-center margin-top-20">
                  <div>
                    <ButtonComponent
                      data-test-auto="D8D9D03F-F300-4705-ABE3-76AB53AAA9B2"
                      style={{ width: 250, height: 56 }}
                      id="btnRequestAccessCode"
                      onClick={this.props.requestCode}
                      variant={"primary"}
                      size={"medium"}
                      buttonClassName={"rounded-pill"}
                    >
                      Send Code
                    </ButtonComponent>
                  </div>
                </div>
              )}

              {this.props.isInitialOTPSent && (
                <>
                  <div className="row justify-content-center otp-page-container-input-div margin-top-20"
                  data-test-auto= "BB058EE1-6A10-4B21-81F5-0BF6D4AE7BB0">
                    <OtpInputControl
                      otp={this.props.otpVal}
                      setOtp={this.props.handleOTPChange}
                      isInvalid={this.props.otpError.error}
                      errorText={this.props.otpError.message}
                      numInputs={6}
                    />
                  </div>
                  <div className="row justify-content-center margin-top-20">
                    <div className="col-flex align-items-center text-center">
                      <div className="spam-text">
                        If you did not receive it yet, check your junk/spam.
                      </div>
                      <ButtonComponent
                        id="btn-resend-code"
                        data-test-auto = "1C636E58-5B57-4781-94DD-626DCEDACCF2"
                        onClick={this.props.requestCode}
                        variant={"link"}
                        size={"small"}
                        buttonClassName="resend-btn"
                      >
                        Resend Code
                      </ButtonComponent>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="margin-top-20">
                      <ButtonComponent
                        ref={(ref) => {
                          this.continueRef = ref;
                        }}
                        type="submit"
                        id="btnMFAContinue"
                        data-test-auto = "143BA7E4-375C-4AD6-8784-8562AA038C5B"
                        buttonClassName={continueClass}
                        style={{ width: 250, height: 56 }}
                        onClick={this.props.verifyOTP}
                        variant={"primary"}
                        size={"medium"}
                      >
                        Confirm
                      </ButtonComponent>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default OTPMobile;
