import * as React from "react";
import HeaderComp from "../../Header/HeaderComp";
import Skeleton from "react-loading-skeleton";
import Footer from "src/components/Layout/Footer";
import { ButtonComponent } from "cp-common-ui-components";

export class CoverPage_GR extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      textColor: "white",
      bgColor: "green",
    };
    this.navigate = this.navigate.bind(this);
    this.moveToNext = this.moveToNext.bind(this);
  }

  componentDidMount() {
    this.props.requestGroupClientCover(this.props.match.params.clientId);
    this.props.requestGroupHeaderDetails(this.props.match.params.clientId);
  }

  componentDidUpdate() {
    console.log("in did update", this.props.headerInfoState);
  }

  moveToNext(guid: string) {
    this.props.history.push("/controllerpreview/" + guid);
  }

  navigate() {
    this.props.getGUID(this.props.match.params.clientId, this.moveToNext);
  }

  //only change from client name to client name

  render() {
    let coverBorderColor = this.state.bgColor;
    let textColorCode = this.state.textColor;

    if (this.props.headerInfoState.brandingSettings) {
      coverBorderColor =
        this.props.headerInfoState.brandingSettings.coverPageSetting
          .bgColorCode;
      textColorCode =
        this.props.headerInfoState.brandingSettings.coverPageSetting
          .foreColorCode;
    }

    return (
      <>
        <>
          {this.props.initialCoverState.loaderState === true ? (
            <Skeleton circle={false} height={"100vh"} width={"100vw"} />
          ) : (
            <>
              <HeaderComp
                headerInfo={this.props.headerInfoState}
                pathname={this.props.match.path}
                clientId={this.props.match.params.clientId}
                history={this.props.history}
                backButton={false}
                navigateLink=""
              />
              <div className="welcome-page">
                <div className="layout-container welcome-page d-flex align-items-center justify-content-center">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="welcome-text text-left">
                        <div style={{ color: "#0973BA" }}>Hello again,</div>
                        <div>It's tax season.</div>
                      </div>
                      <div className="client-details-header text-left">
                        <div className="client-name-header"
                        data-test-auto="W4GRMP71REY4Z1DV1YG7L6XDLH">
                          {this.props.initialCoverState.clientName}
                        </div>
                      </div>

                      <div className="margin-top-50">
                        <ButtonComponent
                          data-test-auto="NYG6C9FLML943538RXC2D9XDW8"
                          onClick={this.navigate}
                          variant={"primary"}
                          size={"medium"}
                          buttonClassName={"get-started-button"}
                        >
                          Get Started
                        </ButtonComponent>
                      </div>

                      <div className="margin-top-50">
                        <div>
                          <div className="prepared-by-title text-left"
                          data-test-auto="DKL160X9V0A45ATKDN4REGDFE0">
                            Prepared by
                          </div>
                        </div>
                        <div>
                          {this.props.initialCoverState.logoPath.length !==
                          0 ? (
                            <img
                              className="prepared-by-img margin-top-10 float-left"
                              data-test-auto="VM1Y507X3KAMT985AC3UCPACMW"
                              src={this.props.initialCoverState.logoPath}
                              alt="prepared-by-logo"
                            />
                          ) : (
                            <div className="prepared-by-company-name margin-top-10 text-left"
                            data-test-auto="R3YCTEA0QLM4T6Z3495P4NJ1DR">
                              {this.props.initialCoverState.companyName ||
                                this.props.initialCoverState.contactAddress
                                  ?.name}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <img
                        style={{
                          alignSelf: "center",
                          height: 400,
                          width: 400,
                          padding: 0,
                        }}
                        src={
                          require("../../../assets/images/WelcomeScreenIllustration.svg")
                            .default
                        }
                        alt="welcome-screen-illustration"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        <Footer />
        </>
      </>
    );
  }
}
export default CoverPage_GR;
