import { Next } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Next';
import { Previous } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Previous';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { ICompany } from '../../../core/domain/models/company/Company';
import { ISignedDocument } from '../../../core/domain/models/manualsign/SignedDocument';
import * as CompanyStore from '../../../store/Common/Company/CompanyStore';
import * as DownloadableDocumentsStore from '../../../store/DownloadableDocumentsStore';
import { ManualSignConstants, DelegateePathConstants, Common } from '../../Common/Constants';
import { Body } from '../../Common/StepLayout/Body';
import { Footer } from '../../Common/StepLayout/Footer';
import { StepLayout } from '../../Common/StepLayout/StepLayout';
import { ManualSign } from '../../ManualSign/ManualSign';
import TaxpayerHelper from '../../Helper/TaxpayerHelper';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel'
import * as SignProcessStore from '../../../store/SignProcess/SignProcessStore';
import { IDialogBox } from '../../../core/utilities/ui/DialogBox';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import { IAdditionalEsignDocument } from '../../../core/domain/models/IAdditionalEsignDocument';
import { ITaxReturn } from '../../../core/domain/models/ITaxReturn';
import * as DelegateeSignProcessStore from '../../../store/DelegateeSignProcessStore';
import { Shimmer } from '../../../components/Common/Shimmer/Shimmer';
import { logger } from '../../../routes';
import { PreviousButtonIcon } from 'src/components/Common/Icons/SvgIcons';


type ManualSignCompletedProps = {
    delegateeManualSignData: ISignedDocument[],
    companyData: ICompany,
    delegateeHeaderInfo: IHeaderInfoViewModel,
    match: match;
    history: History;
    additionalEsignDocuments: IAdditionalEsignDocument[];
    taxReturn: ITaxReturn;
}
    & typeof CompanyStore.actionCreators
    & typeof DownloadableDocumentsStore.actionCreators
    & typeof SignProcessStore.actionCreators
    & typeof DelegateeSignProcessStore.actionCreators

interface ManualSignCompletedState {
    isProcessCompleted: boolean;
    isPreviewMode: boolean;
    showRedirectionWarning: boolean;
    isNextButtonDisable: boolean;
}


const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class ManualSignCompleted extends React.Component<ManualSignCompletedProps, ManualSignCompletedState> {
    private _params: any;
    private _manualSign: any;
    private _manualSignData: ISignedDocument[] = [];
    constructor(props: ManualSignCompletedProps) {
        super(props);
        this.state = {
            isProcessCompleted: false,
            isPreviewMode: false,
            showRedirectionWarning: false,
            isNextButtonDisable: false
        }
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        this.getSignProcessStatus(param.clientId);
        this.props.requestCompanyDetails(param.clientId, true, true);
    }

    componentWillReceiveProps() {
        if (this._manualSignData.length <= 0) {
            this._manualSignData = this.props.delegateeManualSignData;
        }

    }

    gotoCompletionWizard = () => {
        let param: any = this.props.match.params;
        this.props.history.push(`${DelegateePathConstants.CompletionWizard}${param.clientId}`);
    }

    private getSignProcessStatus = (clientId: any) => {
        this.props.requestDelegateeCurrentStepAndUserRole(clientId, this.handleRedirect);
    }
    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        this._params = this.props.match.params;
        const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId);
        if (url && url.trim()) {
            this.props.history.push(url);
        }
        this.setState({ isProcessCompleted: true });
    }

    confirmChanges = () => {
        this._params = this.props.match.params;
        logger.trackTrace("Delegatee Manual sign completion pop up opened", { "ClientId": this._params.clientId, "DocumentId": this.props.taxReturn.id, "PreviewMode": this.state.isPreviewMode });
        dialogBox.confirm(
            Common.DialogBox.ConfirmationTitle,
            ManualSignConstants.ConfirmMessage.replace("<COMPANYNAME>", this.props.delegateeHeaderInfo.companyName),
            Common.DialogBox.Cancel,
            Common.DialogBox.Confrim,
            (result: boolean) => {
                logger.trackTrace("Delegatee Manual sign completion pop up result ",
                    { "ClientId": this._params.clientId, "DocumentId": this.props.taxReturn.id, "result": result, "PreviewMode": this.state.isPreviewMode });

                result && this._manualSign && this._manualSign.completeManualSign()
            });
    }


    private onCompletion = () => {
        if (this._manualSignData.length === 0) {
            this.props.delegateeManualSignData.length > 0 ? this.confirmChanges() : this.gotoCompletionWizard();
        }
        else if (this.props.delegateeManualSignData.length === 0) {
            this.gotoCompletionWizard();
        }
        else {
            let done: boolean = true;
            for (let i = 0; i < this.props.delegateeManualSignData.length; i++) {
                if (this._manualSignData.some(x => x.id === this.props.delegateeManualSignData[i].id)) {
                    continue;
                }
                else {
                    done = false;
                    this.confirmChanges();
                }
            }
            done && this.gotoCompletionWizard();
        }

    }

    disableNextButton = (value: boolean) => {
        this.setState({ isNextButtonDisable: value });
    }

    public render() {

        return (
            <StepLayout className={"manual-sign-completed with-footer pt-5"}>
                <Body
                    title={ManualSignConstants.Title}
                    height={100}
                    lg={9}
                    md={12}
                    sm={12}
                    xs={12}
                    containerClassName={"border-top-0"}
                >
                    {this.props.companyData.name != null && this.props.companyData.name != "" ? (
                        <ManualSign
                            match={this.props.match}
                            downloadEfileForms={this.props.delegateeDownloadEfileForms}
                            addSignedDocument={this.props.addDelegateeSignedDocument}
                            deleteSignedDocument={this.props.deleteDelegateeSignedDocument}
                            requestSignedDocuments={this.props.requestDelegateeSignedDocuments}
                            signedDocuments={this.props.delegateeManualSignData}
                            companyInfo={this.props.companyData}
                            requestCompanyDetails={this.props.requestCompanyDetails}
                            completeManualSign={this.props.completeDelegateeManualSign}
                            onCompletion={this.gotoCompletionWizard}
                            ref={(ref: any) => {
                                this._manualSign = ref;
                            }}
                            additionalEsignDocuments={this.props.additionalEsignDocuments}
                            downloadAllAdditionEsignDocuments={this.props.delegateeDownloadAllAdditionEsignDocuments}
                            requestAdditionalEsignDocument={this.props.requestAdditionalEsignDocument}
                            clientName={this.props.delegateeHeaderInfo.clientName}
                            taxReturn={this.props.taxReturn}
                            isDelegatee={true}
                            isPreviewMode={this.state.isPreviewMode}
                            disableNextButton={this.disableNextButton}
                        />
                    ) : (
                        <Shimmer height={200} />
                    )}
                </Body>

                <Footer lg={12} md={12} sm={12} xs={12}>
                    <div className={"steps-footer "}>
                        <Previous
                            text={"Back"}
                            goToPreviousStep={this.gotoCompletionWizard}
                            isActive={true}
                            previousButtonIcon={<PreviousButtonIcon />}
                        />

                        <Next
                            text={"Done"}
                            goToNextStep={this.onCompletion}
                            isActive={true}
                            disableNextButton={this.state.isNextButtonDisable}
                            className="awesome-multi-steps-btn-done"
                            buttonVariant="success"
                            buttonSize="medium"
                        />
                    </div>
                </Footer>
            </StepLayout>
        );
    }
}
