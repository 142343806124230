import { connect } from 'react-redux';
import { actionCreators as GroupedReturnsStore } from '../../store/GroupedReturns/DashboardStore';
import { actionCreators as AccountStore } from '../../store/Common/AccountStore';
import { ApplicationState } from "../../store/index";
import { DashboardPreview } from './DashboardPreview';
import { DocumentStatus } from 'src/core/common/Enums';
import { ICCRecipientModel } from 'src/core/domain/models/ICCRecipientModel';
import { actionCreators } from "../../store/GroupedReturns/DashboardStore"

function mapStateToProps(state: ApplicationState) {
    return {
        dashboardData: state.groupedReturnDashboardData,
        headerInfo: state.headerInfo
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestGroupedDocuments: (clientGuid: string, pageNo: number, pageSize: number, sortBy: string, sortOrder: string
            , filterTaxPayerName: string, filterSignatureStatus: string, filterK1Status: string,
            filterNextPaymentDue: string, filterTaxYear: string, filterInvoiceStatus: string, searchText: string) =>
            dispatch(GroupedReturnsStore.requestGroupedDocuments(clientGuid, pageNo, pageSize, sortBy, sortOrder,
                filterTaxPayerName,
                filterSignatureStatus,
                filterK1Status,
                filterNextPaymentDue,
                filterTaxYear,
                filterInvoiceStatus, searchText)),

        requestCCRecipientDownloadableDocuments: (clientGuid: string, documentId: number, documentStatus: DocumentStatus
        ) => dispatch(GroupedReturnsStore.requestCCRecipientDownloadableDocuments(clientGuid, documentId, documentStatus)),

        forwardDocumentsToRecipients: (clientId: string, recipientInfo: ICCRecipientModel, callback: () => void) =>
            dispatch(GroupedReturnsStore.forwardDocumentsToRecipients(clientId, recipientInfo, callback)),

        updateNextPaymentDue: (clientId: string, documentId: number, formData: string) =>
            dispatch(GroupedReturnsStore.updateNextPaymentDue(clientId, documentId, formData)),

        refreshToken: (clientId: string, failureCallback: () => void) => dispatch(AccountStore.refreshToken(clientId, failureCallback)),
        getSignerData: (clientId: string, updateData: (signerData: any) => void) => dispatch(actionCreators.getSignerData(clientId, updateData))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(DashboardPreview);
