import * as React from "react";
import { Modal } from "react-bootstrap";
import { IDelegateeInfo } from "../../../core/domain/models/IDelegateeSignerModel";
import { CloseIcon } from "../../../components/Common/Icons/SvgIcons";
import PhoneInput from "react-phone-input-2";
import { ButtonComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../../../components/Helper/HelperFunction";

export interface DelegateeAccountModalProps {
    showState: boolean;
    onHide: () => void;
    onSaveNumberClick: (changedMobileNumber: string, changedCountryCode: string) => void;
    delegateeSigner: IDelegateeInfo;
}

interface DelegateeAccountModalState {
    countryCode: string;
    mobileNumber: string;
}

export class DelegateeAccountModal extends React.Component<DelegateeAccountModalProps, DelegateeAccountModalState> {
    constructor(props: any) {
        super(props);

        this.state = {
            countryCode: this.props.delegateeSigner.countryCode ?? "",
            mobileNumber: this.props.delegateeSigner.mobileNumber ?? ""
        };
    }

    onHide = () => {
        this.props.onHide();
    };

    private handleMobileChanges = (fullValue: string, countryObject: CountryData) => {
        this.setState({
            mobileNumber: fullValue.slice(countryObject.dialCode?.length),
            countryCode: `+${countryObject.dialCode}`
        });
    };

    public render() {
        return (
            <div>
                <Modal show={this.props.showState} className="myaccount myaccountModal" onHide={this.onHide}>
                    <Modal.Header className="darkBackground">
                        <Modal.Title>My Account</Modal.Title>
                        <div
                            data-test-auto="8cc2eef8-27ec-494a-9518-5d52ce8a0891"
                            className="closeIcon"
                            onClick={this.props.onHide}
                            tabIndex={0}
                            onKeyDown={(e: any) => handleKeyDown(e, this.props.onHide)}
                        >
                            <CloseIcon />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="myaccount_body">
                            <div className="myaccount_body-headerMainTitle">Mobile Number</div>
                            <div className="myaccount_body-paragraph">
                                An access code will be sent every time you login.
                            </div>
                            <div className="myaccount_body-section1">
                                <label className="myaccount_body-section1--headerText">Enter Mobile Number</label>
                                <div style={{ width: "225px" }} className="mobileSection">
                                    <PhoneInput
                                        containerClass={`inputCCMobile`}
                                        country={"us"}
                                        autoFormat={true}
                                        placeholder="() -"
                                        value={`${this.state.countryCode}${this.state.mobileNumber}`}
                                        onChange={this.handleMobileChanges}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonComponent
                            id="btnHideMyaccountPopUp"
                            onClick={this.onHide}
                            data-test-auto="CEC8781D-ED44-4B24-AF4A-8005A00B95FC"
                            variant={"outline-tertiary"}
                            buttonClassName={"button-margin-right"}
                            size={"medium"}
                        >
                            Cancel
                        </ButtonComponent>
                        <ButtonComponent
                            onClick={() => {
                                this.props.onSaveNumberClick(this.state.mobileNumber, this.state.countryCode);
                            }}
                            data-test-auto="56A7B8B9-02D6-498E-8953-F7F56C4748AE"
                            variant={"primary"}
                            size={"medium"}
                        >
                            Save
                        </ButtonComponent>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default DelegateeAccountModal;
