import { History } from "history";
import * as React from "react";
import { match, RouteComponentProps } from "react-router";
import { IDownloadableDocumentsViewModel } from "../../../../src/core/domain/viewModels/IDownloadableDocumentsViewModel";
import * as TaxDocument from "../../../core/domain/models/ITaxReturn";
import { ISignedDocument } from "../../../core/domain/models/manualsign/SignedDocument";
import { ICommonDataViewModel } from "../../../core/domain/viewModels/ICommonDataViewModel";
import { IHeaderInfoViewModel } from "../../../core/domain/viewModels/IHeaderInfoViewModel";
import { ISummaryViewModel } from "../../../core/domain/viewModels/ISummaryViewModel";
import * as CompanyStore from "../../../store/Common/Company/CompanyStore";
import * as EsignStore from "../../../store/Common/EsignStore";
import * as HelperStore from "../../../store/Common/HelperStore";
import * as TaxDocumentStore from "../../../store/Common/TaxDocumentStore";
import * as DownloadableDocumentStore from "../../../store/DownloadableDocumentsStore";
import * as ManualSignStore from "../../../store/Sign/ManualSignStore";
import * as SignProcessStore from "../../../store/SignProcess/SignProcessStore";
import * as SummaryStore from "../../../store/SummaryStore";
import { CompletedSummaryPage } from "../../CompletedSummary/CompletedSummaryPage";
import { IPaymentTransactionModel } from "../../../core/domain/models/IPaymentTransactionModel";
import * as PaymentStore from "../../../store/PaymentStore";
import * as PayStore from "../../../store/PayStore";
import { ICompany } from "../../../core/domain/models/company/Company";
import * as AccountStore from "../../../store/Common/AccountStore";
import { storeControllerIdInMemory } from "../../../core/services/dataAccess/DataService.Axios";
import * as DelegateeSignerStore from "../../../store/DelegateeSignerStore";
import { IDelegateeInfo } from "../../../core/domain/models/IDelegateeSignerModel";
import { IInvoicePayment } from "../../../core/domain/models/InvoicePaymentModel";
import * as InvoicePaymentStore from "../../../store/InvoicePaymentStore";
import { IClientProcessViewModel } from "../../../core/domain/viewModels/IClientProcessViewModel";
import { logger } from "../../../routes";

export type CompleteWizardComponentProps = {
  summaryData: ISummaryViewModel;
  commonData: ICommonDataViewModel;
  history: History;
  match: match;
  taxReturn: TaxDocument.ITaxReturn;
  headInfoViewModel: IHeaderInfoViewModel;
  signedDocument: ISignedDocument[];
  downloadableDocument: IDownloadableDocumentsViewModel;
  delegateeSigner: IDelegateeInfo;
  paymentData: IPaymentTransactionModel;
  companyData: ICompany;
  invoicePaymentData: IInvoicePayment;
  clientProcessModel: IClientProcessViewModel;
} & typeof SummaryStore.actionCreators &
  typeof HelperStore.actionCreators &
  typeof EsignStore.actionCreators &
  typeof CompanyStore.actionCreators &
  typeof TaxDocumentStore.actionCreators &
  typeof DownloadableDocumentStore.actionCreators &
  typeof ManualSignStore.actionCreators &
  typeof AccountStore.actionCreators &
  typeof SignProcessStore.actionCreators &
  typeof DelegateeSignerStore.actionCreators &
  typeof PaymentStore.actionCreators &
  typeof PayStore.actionCreators &
  typeof InvoicePaymentStore.actionCreators &
  typeof PayStore.actionCreators &
  RouteComponentProps<{}>;

interface CompleteWizardComponentState {}

class CompletedWizardFlow extends React.Component<
  CompleteWizardComponentProps,
  CompleteWizardComponentState
> {
  constructor(props: CompleteWizardComponentProps) {
    super(props);
  }

  componentDidMount() {
    let param: any = this.props.match.params;
    if (param && param.controllerId) {
      storeControllerIdInMemory(param.controllerId);
    }
    this.props.requestHeaderInfo(param.clientId);
    if (
      this.props.commonData &&
      this.props.commonData.taxingAuthorities.length == 0
    ) {
      this.props.requestAllTaxingAuthorities(param.clientId);
    }
    this.props.requestDownloadableDocuments(param.clientId);
    this.props.requestTaxDocument(param.clientId);
    this.props.requestSignedDocuments(param.clientId, true);
    this.props.requestNextSigner(param.clientId);
    this.props.requestCompanyDetails(param.clientId);
    this.props.requestPaymentTransaction(param.clientId);
    this.props.requestStripePublicAPIKey(param.clientId);
    this.props.requestInvoicePaymentDetails(param.clientId);
    logger.trackPageView("Completed Summary Page", {
      ClientId: param.clientId,
    });
  }

  public render() {
    return (
      <CompletedSummaryPage
        commonData={this.props.commonData}
        history={this.props.history}
        match={this.props.match}
        requestSummaryDetails={this.props.requestSummaryDetails}
        summaryData={this.props.summaryData}
        taxReturn={this.props.taxReturn}
        requestSignerDetails={this.props.requestNextSigner}
        sendSigningReminder={this.props.sendSigningReminder}
        downloadSignedEFileDocument={this.props.downloadSignedEFileDocument}
        signedDocument={this.props.signedDocument}
        downloadAllAdditionEsignDocuments={
          this.props.downloadAllAdditionEsignDocuments
        }
        downloadableDocumentsViewModel={this.props.downloadableDocument}
        requestDownloadableDocuments={this.props.requestDownloadableDocuments}
        requestSignedDocuments={this.props.requestSignedDocuments}
        refreshTaxDocument={this.props.requestTaxDocument}
        downloadEFileDocument={this.props.downloadEFileDocument}
        requestCurrentStepAndUserRole={this.props.requestCurrentStepAndUserRole}
        paymentData={this.props.paymentData}
        companyData={this.props.companyData}
        downloadTaxInvoice={this.props.downloadTaxInvoice}
        requestPaymentIntent={this.props.requestPaymentIntent}
        updatePurchaseTransactionStatus={
          this.props.updatePurchaseTransactionStatus
        }
        headerInfo={this.props.headInfoViewModel}
        updateDelegateeDetails={this.props.updateDelegatee}
        cancelDelegation={this.props.cancelDelegation}
        resetLastVisitedSteps={this.props.resetLastVisitedSteps}
        delegateeSigner={this.props.delegateeSigner}
        requestTaxClientDelegateeDetails={
          this.props.requestTaxClientDelegateeDetails
        }
        invoicePaymentUrl={
          this.props.companyData
            ? this.props.companyData.onlinePaymentUrl
            : undefined
        }
        invoicePaymentData={this.props.invoicePaymentData}
        addInvoicePayment={this.props.addInvoicePayment}
        updateInvoicePayment={this.props.updateInvoicePayment}
        deleteInvoicePayment={this.props.deleteInvoicePayment}
        clientProcessModel={this.props.clientProcessModel}
      />
    );
  }
}

export default CompletedWizardFlow;
