import * as React from 'react';
import { match } from 'react-router';
import { DocumentSignatureDataViewModel } from '../../core/domain/viewModels/IDocumentSignatureDataViewModel';
import { PdfView } from '../Esign/Main/PdfView';
import { IDocument } from '../../core/domain/models/esign/Document';
import { EsignManager, IEsignValidationModel } from '../Esign/Main/EsignBase';
import { Main } from '../Esign/Main/Main';
import { History } from 'history';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import * as TaxDocument from '../../core/domain/models/ITaxReturn';
import { ISignerModel } from '../../core/domain/models/ISignerModel';
import * as EsignStore from '../../store/Common/EsignStore';
import * as ManualSignStore from '../../store/Sign/ManualSignStore';
import * as DelegateeSignProcessStore from '../../store/DelegateeSignProcessStore';
import * as DownloadableDocumentsStore from '../../store/DownloadableDocumentsStore';
import { Body } from '../Common/StepLayout/Body';
import { Footer } from '../Common/StepLayout/Footer';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { ManualSignConstants, PathConstants, getRoutePathUrl } from '../Common/Constants';
import { Previous } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Previous';
import { IDownloadableDocumentsViewModel } from '../../../src/core/domain/viewModels/IDownloadableDocumentsViewModel';
import { ISummaryViewModel } from '../../core/domain/viewModels/ISummaryViewModel';
import { Role } from '../../core/common/Enums';
import { logger } from '../../routes';

export const NO_INDEX = -1;

interface ManualSignReviewDocumentsState {
    documentControlsAndData: DocumentSignatureDataViewModel[],
}

type ManualSignReviewDocumentsProps = {
    requestSignatureControls: (clientGuid: string) => void;
    match: match;
    history: History;
    signatureControlsData: IDocument[];
    signerData: ISignerModel[];
    headerInfo: IHeaderInfoViewModel;
    taxReturn: TaxDocument.ITaxReturn;
    downloadableDocumentsViewModel: IDownloadableDocumentsViewModel;
    summaryData: ISummaryViewModel;
}
    & typeof EsignStore.actionCreators
    & typeof ManualSignStore.actionCreators
    & typeof DelegateeSignProcessStore.actionCreators
    & typeof DownloadableDocumentsStore.actionCreators


export class ManualSignReviewDocuments extends React.Component<ManualSignReviewDocumentsProps, ManualSignReviewDocumentsState> {

    private _pdfView: any;

    constructor(props: any, states: ManualSignReviewDocumentsState) {
        super(props, states);
        this.state = {
            documentControlsAndData: [],
        }
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        logger.trackPageView('Completed Summary Manual Sign Review Page', { "ClientId": param.clientId, "DocumentId": this.props.taxReturn.id });
        if (this.props.signatureControlsData.length == 0) {
            this.props.requestPreviewSignatureControls(param.clientId);
        }
        this.props.requestAdditionalEsignDocument(param.clientId);
    }

    static getDerivedStateFromProps(nextProps: ManualSignReviewDocumentsProps, nextState: ManualSignReviewDocumentsState) {
        if (nextProps.signatureControlsData &&
            nextProps.signatureControlsData.length !== nextState.documentControlsAndData.length) {
            return { documentControlsAndData: nextProps.signatureControlsData }
        }
        else {
            return { documentControlsAndData: nextState.documentControlsAndData };
        }
    }

    onCompletion = () => {
        let param: any = this.props.match.params;
        if (this.props.headerInfo.loggedInUserInfo.role.toString() == Role[Role.Delegatee]) {

            this.props.history.push(getRoutePathUrl(PathConstants.DelegateeSignComplete, param.clientId, param.controllerId));
        }
        else {
            this.props.history.push(getRoutePathUrl(PathConstants.CompletionWizard, param.clientId, param.controllerId));
        }
    }

    handleDocumentSignFinish = (documentControlData: DocumentSignatureDataViewModel) => {
        let documentDataList: DocumentSignatureDataViewModel[] = [...this.state.documentControlsAndData];

        const index = documentDataList.findIndex(x => x.id === documentControlData.id);
        if (index == NO_INDEX) {
            documentDataList.push(documentControlData);
        }
        else {
            documentDataList[index] = documentControlData;
        }
        this.setState({ documentControlsAndData: documentDataList });
    }

    handleSigningCompleted = () => {

        const documentData: IDocument[] = this._pdfView.getDocumentsData();
        const validation: IEsignValidationModel = EsignManager.validateEsignData(documentData);

        if (validation.status) {

        }
        else {
            this._pdfView.showValidationMessage(validation.document, validation.page, validation.control);
        }
    }

    getFileNamePrefix = () => {
        return this.props.summaryData.clientName + "_" + this.props.summaryData.taxYear + "_" + this.props.taxReturn.engagementType.toString().substr(1);
    }


    getOtherSignedDocumentFileName = () => {
        return this.getFileNamePrefix() + "_ Other Signed Document.zip";;
    }

    onDownloadClick = () => {
        const _self = this;
        let param: any = this.props.match.params;
        //this.props.downloadEfileForms(param.clientId);

        //setTimeout(function () {
        //    _self.props.downloadableDocumentsViewModel.additionalEsignDocuments &&
        //        _self.props.downloadableDocumentsViewModel.additionalEsignDocuments.length > 0
        //        && _self.props.delegateeDownloadAllAdditionEsignDocuments(param.clientId, _self.getOtherSignedDocumentFileName());

        //}, 2000);
        //}
        //else {
        this.props.downloadEfileForms(param.clientId);

        setTimeout(function () {
            _self.props.downloadableDocumentsViewModel.additionalEsignDocuments &&
                _self.props.downloadableDocumentsViewModel.additionalEsignDocuments.length > 0
                && _self.props.downloadAllAdditionEsignDocuments(param.clientId, _self.getOtherSignedDocumentFileName());

        }, 2000);
        //}
    }

    public render() {
        const param: any = this.props.match.params;
        return (
            <StepLayout className={"with-footer manualSign-pdf-viewer no-padding"} lg={12} md={6} sm={6} xs={6}
                title={ManualSignConstants.ReviewDocuments}
                showDownloadButton={true} handleDownload={this.onDownloadClick}
                customDownloadTooltip={ManualSignConstants.DownloadDocumentButtonText}
                enlargeDownloadIcon={true}
                enlargeBtnSize={true}
            >
                <Body
                    height={100}
                    lg={12} md={12} sm={12} xs={12}
                >
                    <Main>
                        <PdfView
                            documents={this.props.signatureControlsData}
                            onSigningCompleted={this.handleSigningCompleted}
                            headerInfo={this.props.headerInfo}
                            ref={(ref: PdfView) => this._pdfView = ref}
                            spouseName={TaxpayerHelper.getSecondSignerName(this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn,
                                this.props.signerData, param.clientId)}
                            hideNavigationControl={true}
                            hideSignatureControls={true}
                        />
                    </Main>
                </Body>

                <Footer lg={12} md={12} sm={12} xs={12}>
                    <div className={"steps-footer"}>
                        <Previous
                            text={"Back"} goToPreviousStep={this.onCompletion} isActive={true} />
                    </div>
                </Footer>

            </StepLayout>
        );
    }
}
