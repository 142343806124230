import * as React from 'react';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import PhoneInput from 'react-phone-input-2';

export type SpouseEmailProps =
    {
        handleSpouseMailChange(mail: string): void,
        handleMobileChanges(fullValue: string, countryObject: CountryData): void,
        spouseName: string,
        spouseEmail: string,
        spouseMobileNumber: string,
        spouseCountryCode: string
    }

export interface ISpouseEmailState {
    countryCode: string;
    mobileNumber: string;
}

export class SpouseEmail extends React.Component<SpouseEmailProps, ISpouseEmailState> {

    constructor(props: any) {
        super(props);

        this.state = {
            countryCode: props.spouseCountryCode,
            mobileNumber: props.spouseMobileNumber,
        }
    }

    private onSpouseMailChange = (e: any) => {
        this.props.handleSpouseMailChange(e.target.value);
    }

    public render() {
        return (
            <div className="esign-spouse">
                <div className="spouse-header">
                    Spouse's Signature Required
                </div>
                <div className="content-paragraph">
                    Please provide your spouse's email address. It can be the same as yours if you share an email account. They will receive a similar request to sign their forms electronically.
                </div>
                <div data-test-auto="00CBB4CE-B89F-4E91-B4F9-E20CE46C5BAA">
                    {this.props.spouseName ?
                        (<div>
                            <div className="row">
                                <div className="col-lg-12 spouse-name">
                                    <span className='label'>Spouse's Name: </span>
                                    <span className='value'>{this.props.spouseName}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 spouse-email">
                                    <label className='labelEmail' htmlFor='spouseEmailAddress'>Email Address</label>
                                    <input
                                        id='spouseEmailAddress'
                                        type="text"
                                        data-test-auto="2C580DDD-1425-4242-A2B2-F371B968ACEF"
                                        className="form-control"
                                        value={this.props.spouseEmail}
                                        onChange={(e) => this.onSpouseMailChange(e)}
                                    />
                                </div>
                                <div className="col-lg-6 spouse-mobileNumber">
                                    <label className="labelMobileNumber" htmlFor='spouseMobile'>Mobile</label>
                                    <div style={{ width: '225px' }} className="mobileSection" >
                                        <PhoneInput
                                            inputProps={{ id: 'spouseMobile' }}
                                            containerClass={`inputCCMobile`}
                                            country={'us'}
                                            autoFormat={true}
                                            placeholder="() -"
                                            value={`${this.props.spouseCountryCode}${this.props.spouseMobileNumber}`}
                                            onChange={this.props.handleMobileChanges}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                        : <Shimmer height={50} />}
                </div>
            </div>);
    }
}