import * as React from "react";
import { Shimmer } from "../../../components/Common/Shimmer/Shimmer";
import { WelcomeSummaryConstants, CompletedSummaryConstant } from "../../../components/Common/Constants";
import { WelcomeMessage } from "../../../components/CompletedSummary/Parts/WelcomeMessage";
import { SignProcessSteps } from "../../Home/TaxpayerSignFlowManager";
import {
    SvgIconReviewDocumentsSummary,
    SvgIconSignDocumentsSummary,
    SvgIconDistributeK1sSummary,
    SvgIconTaxPaymentsSummary,
    SvgIconPaperFileDocumentsSummary
} from "../../Common/Icons/SvgIcons";
import { IVoucher } from "../../../core/domain/models/IGroup";
import { logger } from "../../../routes";
import { history } from "../../../core/services/dataAccess/History";
import { ButtonComponent } from "cp-common-ui-components";
import { TileComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../../../components/Helper/HelperFunction";

export interface WelcomeProps {
    taxYear: number;
    clientName: string;
    hasShareholders: boolean;
    hasPaperFiles: boolean;
    goToStep: (stepNumber: SignProcessSteps) => void;
    goToStepById: (id: any) => void;
    vouchers: IVoucher[];
}

export class Welcome extends React.Component<WelcomeProps, {}> {
    constructor(props: WelcomeProps) {
        super(props);
    }

    componentDidMount() {
        const params: any = history;
        let clientId: string = "";
        if (params) {
            clientId = params.location?.pathname?.split("/").pop();
        }
        logger.trackTrace("Summary Page View", { ClientId: clientId });
    }

    validateVouchers = (vouchers: IVoucher[]): boolean => {
        for (var i = 0; i < vouchers.length; i++) {
            if (vouchers[i].amount > 0) {
                return true;
            }
        }
        return false;
    };

    getNoOfSteps = (hasShareholders: boolean, hasVoucher: boolean, hasPaperFile: boolean): number => {
        let stepsCount = 2; //Review and Sign Step
        if (hasShareholders) {
            //Distribute Step
            stepsCount++;
        }
        if (hasVoucher) {
            //Pay Step
            stepsCount++;
        }
        if (hasPaperFile) {
            //PaperFile Step
            stepsCount++;
        }
        return stepsCount;
    };

    getStepsDivClass = (noOfSteps: number): string => {
        switch (noOfSteps) {
            case 1:
                return "col-lg-12 col-md-12";
            case 2:
                return "col-lg-6 col-md-6";
            case 3:
                return "col-lg-4 col-md-4";
            case 4:
                return "col-lg-3 col-md-3";
            case 5:
                return "col-lg-3 col-md-3";
            default:
                return "col-lg-4 col-md-4";
        }
    };

    public render() {
        let isValidVouchers: boolean = this.props.vouchers && this.validateVouchers(this.props.vouchers);

        let noOfSteps = this.getNoOfSteps(this.props.hasShareholders, isValidVouchers, this.props.hasPaperFiles);

        return (
            <div id="welcomeMessage" data-test-auto="43EFC177-DFB7-4DCA-9CA0-36F39098289F">
                {this.props.clientName ? (
                    <div className="welcome-container">
                        <WelcomeMessage
                            customClassName='welcome-message-ready'
                            message={CompletedSummaryConstant.WelcomeMessage.replace(
                                "<TAXPAYERNAME>",
                                this.props.clientName
                            )}
                        />
                        <div style={{ marginTop: "5px" }}>
                            <WelcomeMessage
                                customClassName='welcome-message-ready'
                                message={WelcomeSummaryConstants.WelcomeMessage.replace(
                                    "<TAXYEAR>",
                                    this.props.taxYear.toString()
                                )}
                            />
                        </div>
                    </div>
                ) : (
                    <Shimmer height={200} />
                )}

                {this.props.clientName ? (
                    noOfSteps > 4 ? (
                        <div className="cards-container">
                            <div className="welcome-page-step-img-btn cards-content">
                                <div
                                    className="cards"
                                    tabIndex={0}
                                    onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(SignProcessSteps.Invoice))}
                                >
                                    <TileComponent
                                        onClick={() => this.props.goToStep(SignProcessSteps.Invoice)}
                                        tileContent={<SvgIconReviewDocumentsSummary
                                            data-test-auto="62D257EA-93C3-41E6-9E47-464110924398"
                                            className="icon---summary-review-documents"
                                        />}
                                        tileDetail={"Review Documents"}
                                    />
                                </div>
                                <div
                                    className="cards"
                                    tabIndex={0}
                                    onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(SignProcessSteps.SignMethod))}
                                >
                                    <TileComponent
                                        onClick={() => this.props.goToStep(SignProcessSteps.SignMethod)}
                                        tileContent={<SvgIconSignDocumentsSummary
                                            className="icon---summary-sign-documents"
                                            data-test-auto="EXZYX16V8VT451HPFZ6FLC7V6H"
                                        />}
                                        tileDetail={"Sign Documents"}
                                    />
                                </div>
                                <div
                                    className="cards"
                                    tabIndex={0}
                                    onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(SignProcessSteps.PaperFileMessage))}
                                >
                                    <TileComponent
                                        onClick={() => this.props.goToStep(SignProcessSteps.PaperFileMessage)}
                                        tileContent={<SvgIconPaperFileDocumentsSummary
                                            data-test-auto="80B13E77-CBEF-4828-A7DE-6ACD70A1EA11"
                                            className="icon---summary-paperfile-documents"
                                        />}
                                        tileDetail={"Print and Mail Paper File Returns"}
                                    />
                                </div>
                                <div
                                    className="cards"
                                    tabIndex={0}
                                    onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(SignProcessSteps.DistributeSelection))}
                                >
                                    <TileComponent
                                        onClick={() => this.props.goToStep(SignProcessSteps.DistributeSelection)}
                                        tileContent={<SvgIconDistributeK1sSummary
                                            data-test-auto="80B13E77-CBEF-4828-A7DE-6ACD70A1EA11"
                                            className="icon---distribute-k1s"
                                        />}
                                        tileDetail={"Distribute K-1s"}
                                    />
                                </div>
                                <div
                                    className="cards"
                                    tabIndex={0}
                                    onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(SignProcessSteps.Pay))}
                                >
                                    <TileComponent
                                        onClick={() => this.props.goToStep(SignProcessSteps.Pay)}
                                        tileContent={<SvgIconTaxPaymentsSummary
                                            data-test-auto="9CD8CB75-5602-4393-831E-4627EC43FDF1"
                                            className="icon---summary-make-payments"
                                        />}
                                        tileDetail={"Make Payments"}
                                    />
                                </div>
                            </div>
                            <div className="summary-start-step-container flex-align-center">
                                <ButtonComponent
                                    data-test-auto="87742DFF-5468-44D2-B959-090842533E9E"
                                    onClick={() => this.props.goToStep(SignProcessSteps.PreparerMessage)}
                                    variant={"primary"}
                                    buttonClassName={"summary-begin-small-btn"}
                                    size={"medium"}
                                >
                                    Get Started
                                </ButtonComponent>
                            </div>
                        </div>
                    ) : (
                        <div className="cards-container">
                            <div
                                className="welcome-page-step-img-btn cards-content"
                                data-test-auto="55D2107E-AFAF-4A21-BAB1-A66DEB6AA8CD"
                            >
                                <div
                                    data-test-auto="PPJQW037X0PMJGHV10WPAQA5TW"
                                    className="cards"
                                    tabIndex={0}
                                    onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(SignProcessSteps.Invoice))}
                                >
                                    <TileComponent
                                        onClick={() => this.props.goToStep(SignProcessSteps.Invoice)}
                                        tileContent={<SvgIconReviewDocumentsSummary
                                            data-test-auto="62D257EA-93C3-41E6-9E47-464110924398"
                                            className="icon---summary-review-documents"
                                        />}
                                        tileDetail={"Review Documents"}
                                    />
                                </div>
                                <div
                                    className="cards"
                                    tabIndex={0}
                                    onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(SignProcessSteps.SignMethod))}
                                >
                                    <TileComponent
                                        onClick={() => this.props.goToStep(SignProcessSteps.SignMethod)}
                                        tileContent={<SvgIconSignDocumentsSummary
                                            className="icon---summary-sign-documents"
                                            data-test-auto="EXZYX16V8VT451HPFZ6FLC7V6H"
                                        />}
                                        tileDetail={"Sign Documents"}
                                    />
                                </div>
                                {this.props.hasPaperFiles && (
                                    <div
                                        data-test-auto="2F5V0W3F0MN4J1X7EHCN2P39MR"
                                        className="cards"
                                        tabIndex={0}
                                        onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(SignProcessSteps.PaperFileMessage))}
                                    >
                                        <TileComponent
                                            onClick={() => this.props.goToStep(SignProcessSteps.PaperFileMessage)}
                                            tileContent={<SvgIconPaperFileDocumentsSummary
                                                data-test-auto="80B13E77-CBEF-4828-A7DE-6ACD70A1EA11"
                                                className="icon---summary-paperfile-documents"
                                            />}
                                            tileDetail={"Print and Mail Paper File Returns"}
                                        />
                                    </div>
                                )}
                                {this.props.hasShareholders && (
                                    <div
                                        data-test-auto="7F5V0W3F0MN4J1X7EHCN2P39MW"
                                        className="cards"
                                        tabIndex={0}
                                        onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(SignProcessSteps.DistributeSelection))}
                                    >
                                        <TileComponent
                                            onClick={() => this.props.goToStep(SignProcessSteps.DistributeSelection)}
                                            tileContent={<SvgIconDistributeK1sSummary
                                                data-test-auto="80B13E77-CBEF-4828-A7DE-6ACD70A1EA11"
                                                className="icon---distribute-k1s"
                                            />}
                                            tileDetail={"Distribute K-1s"}
                                        />
                                    </div>
                                )}
                                {isValidVouchers && (
                                    <div
                                        data-test-auto="V5TJR4QH0P84V2YUJ1EPZNJ934"
                                        className="cards"
                                        tabIndex={0}
                                        onKeyDown={(e: any) => handleKeyDown(e, () => this.props.goToStep(SignProcessSteps.Pay))}
                                    >
                                        <TileComponent
                                            onClick={() => this.props.goToStep(SignProcessSteps.Pay)}
                                            tileContent={<SvgIconTaxPaymentsSummary
                                                data-test-auto="9CD8CB75-5602-4393-831E-4627EC43FDF1"
                                                className="icon---summary-make-payments"
                                            />}
                                            tileDetail={"Make Payments"}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="summary-start-step-container flex-align-center">
                                <ButtonComponent
                                    data-test-auto="87742DFF-5468-44D2-B959-090842533E9E"
                                    onClick={() => this.props.goToStep(SignProcessSteps.PreparerMessage)}
                                    variant={"primary"}
                                    buttonClassName={"summary-begin-btn"}
                                    size={"medium"}
                                >
                                    Get Started
                                </ButtonComponent>
                            </div>
                        </div>
                    )
                ) : (
                    <Shimmer height={500} />
                )}
            </div>
        );
    }
}
