import * as TaxDocument from "../../core/domain/models/ITaxReturn";
import { ClientType, DocumentStatus } from "../../core/common/Enums";
import {
  ClientTypesNumber,
  ISignerModel,
} from "../../core/domain/models/ISignerModel";
import { IClientProcessViewModel } from "../../core/domain/viewModels/IClientProcessViewModel";
import { Role } from "../../core/common/Enums";
import {
  PathConstants,
  DelegateePathConstants,
  getRoutePathUrl,
} from "../Common/Constants";
import { SignProcessSteps } from "../Home/TaxpayerSignFlowManager";
import { Permissions } from "../../components/Common/Constants";
import { ClientTypes } from "../../core/domain/models/ITaxClient";
import {
  DocumentGroups,
  IVoucher,
  IGroup,
  TransmittalStatus,
} from "../../core/domain/models/IGroup";
import { VIEW_TYPE } from "../../utils/constants";
import { StateImages, StateUSA } from "../Common/Icons/StateImages";
import Moment from "moment/moment";
import { IPayScreenViewModel } from "src/core/domain/viewModels/IPayScreenViewModel";

const NO_INDEX = -1;

export default class TaxpayerHelper {
  static isMarried = (taxReturn: TaxDocument.ITaxReturn) => {
    return TaxDocument.isMutual(taxReturn) &&
      taxReturn != null &&
      taxReturn != undefined &&
      taxReturn.spouse != null &&
      taxReturn.spouse != undefined
      ? true
      : false;
  };

  static getFirstSignerId = (
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[]
  ) => {
    return signerData && signerData.length > 0
      ? signerData[0].clientGuid
      : taxReturn.taxpayer && taxReturn.taxpayer.clientGuid
      ? taxReturn.taxpayer.clientGuid
      : "";
  };

  static isFirstSignerLoggedIn = (
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[],
    loggedInUserGuid: string
  ) => {
    const firstSignerId = TaxpayerHelper.getFirstSignerId(
      taxReturn,
      signerData
    );
    return loggedInUserGuid === firstSignerId;
  };

  static getSecondSignerType(
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[],
    loggedInUserGuid: string
  ) {
    const firstSignerId = TaxpayerHelper.getFirstSignerId(
      taxReturn,
      signerData
    );
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return ClientType.Undefined;
    } else {
      if (
        taxReturn.taxpayer.clientGuid === loggedInUserGuid &&
        taxReturn.taxpayer.clientGuid === firstSignerId
      ) {
        return ClientType.Spouse;
      } else if (
        taxReturn.spouse.clientGuid === loggedInUserGuid &&
        taxReturn.spouse.clientGuid === firstSignerId
      ) {
        return ClientType.Taxpayer;
      } else {
        return ClientType.Undefined;
      }
    }
  }

  static getSecondSignerId = (
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[],
    loggedInUserGuid: string
  ) => {
    const firstSignerId = TaxpayerHelper.getFirstSignerId(
      taxReturn,
      signerData
    );
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return 0;
    } else {
      if (
        taxReturn.taxpayer.clientGuid === loggedInUserGuid &&
        taxReturn.taxpayer.clientGuid === firstSignerId
      ) {
        return taxReturn.spouse.id;
      } else if (
        taxReturn.spouse.clientGuid === loggedInUserGuid &&
        taxReturn.spouse.clientGuid === firstSignerId
      ) {
        return taxReturn.taxpayer.id;
      } else {
        return 0;
      }
    }
  };

  static getSecondSignerGuid = (
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[],
    loggedInUserGuid: string
  ) => {
    const firstSignerId = TaxpayerHelper.getFirstSignerId(
      taxReturn,
      signerData
    );
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return "";
    } else {
      if (
        taxReturn.taxpayer.clientGuid === loggedInUserGuid &&
        taxReturn.taxpayer.clientGuid === firstSignerId
      ) {
        return taxReturn.spouse.clientGuid;
      } else if (
        taxReturn.spouse.clientGuid === loggedInUserGuid &&
        taxReturn.spouse.clientGuid === firstSignerId
      ) {
        return taxReturn.taxpayer.clientGuid;
      } else {
        return "";
      }
    }
  };

  static getSecondSignerEmail(
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[],
    loggedInUserGuid: string,
    controllerId?: string
  ) {
    const firstSignerId = TaxpayerHelper.getFirstSignerId(
      taxReturn,
      signerData
    );
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return "";
    } else {
      if (
        controllerId &&
        TaxpayerHelper.anyOneSignerDeceased(taxReturn, signerData) &&
        taxReturn.spouse
      ) {
        return taxReturn.spouse.email;
      } else {
        if (
          taxReturn.taxpayer.clientGuid === loggedInUserGuid &&
          taxReturn.taxpayer.clientGuid === firstSignerId
        ) {
          return taxReturn.spouse.email;
        } else if (
          taxReturn.spouse.clientGuid === loggedInUserGuid &&
          taxReturn.spouse.clientGuid === firstSignerId
        ) {
          return taxReturn.taxpayer.email;
        } else {
          return "";
        }
      }
    }
  }

  static getSecondSignerMobileNumber(
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[],
    loggedInUserGuid: string,
    controllerId?: string
  ) {
    const firstSignerId = TaxpayerHelper.getFirstSignerId(
      taxReturn,
      signerData
    );
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return "";
    } else {
      if (
        controllerId &&
        TaxpayerHelper.anyOneSignerDeceased(taxReturn, signerData) &&
        taxReturn.spouse
      ) {
        return taxReturn.spouse.mobileNumber === undefined
          ? ""
          : taxReturn.spouse.mobileNumber;
      } else {
        if (
          taxReturn.taxpayer.clientGuid === loggedInUserGuid &&
          taxReturn.taxpayer.clientGuid === firstSignerId
        ) {
          return taxReturn.spouse.mobileNumber === undefined
            ? ""
            : taxReturn.spouse.mobileNumber;
        } else if (
          taxReturn.spouse.clientGuid === loggedInUserGuid &&
          taxReturn.spouse.clientGuid === firstSignerId
        ) {
          return taxReturn.taxpayer.mobileNumber === undefined
            ? ""
            : taxReturn.taxpayer.mobileNumber;
        } else {
          return "";
        }
      }
    }
  }

  static getSecondSignerMobileCountryCode(
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[],
    loggedInUserGuid: string,
    controllerId?: string
  ) {
    const firstSignerId = TaxpayerHelper.getFirstSignerId(
      taxReturn,
      signerData
    );
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return "";
    } else {
      if (
        controllerId &&
        TaxpayerHelper.anyOneSignerDeceased(taxReturn, signerData) &&
        taxReturn.spouse
      ) {
        return taxReturn.spouse.countryCode === undefined
          ? ""
          : taxReturn.spouse.countryCode;
      } else {
        if (
          taxReturn.taxpayer.clientGuid === loggedInUserGuid &&
          taxReturn.taxpayer.clientGuid === firstSignerId
        ) {
          return taxReturn.spouse.countryCode === undefined
            ? ""
            : taxReturn.spouse.countryCode;
        } else if (
          taxReturn.spouse.clientGuid === loggedInUserGuid &&
          taxReturn.spouse.clientGuid === firstSignerId
        ) {
          return taxReturn.taxpayer.countryCode === undefined
            ? ""
            : taxReturn.taxpayer.countryCode;
        } else {
          return "";
        }
      }
    }
  }

  static getSecondSignerSSN(
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[],
    loggedInUserGuid: string
  ) {
    const firstSignerId = TaxpayerHelper.getFirstSignerId(
      taxReturn,
      signerData
    );
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return "";
    } else {
      if (
        taxReturn.taxpayer.clientGuid === loggedInUserGuid &&
        taxReturn.taxpayer.clientGuid === firstSignerId
      ) {
        return taxReturn.spouse.ssn;
      } else if (
        taxReturn.spouse.clientGuid === loggedInUserGuid &&
        taxReturn.spouse.clientGuid === firstSignerId
      ) {
        return taxReturn.taxpayer.ssn;
      } else {
        return "";
      }
    }
  }

  static getSecondSignerName(
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[],
    loggedInUserGuid: string,
    controllerId?: string
  ) {
    const firstSignerId = TaxpayerHelper.getFirstSignerId(
      taxReturn,
      signerData
    );
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return "";
    } else {
      if (
        controllerId &&
        TaxpayerHelper.anyOneSignerDeceased(taxReturn, signerData) &&
        taxReturn.spouse
      ) {
        return taxReturn.spouse.name;
      } else {
        if (
          taxReturn.taxpayer.clientGuid === loggedInUserGuid &&
          taxReturn.taxpayer.clientGuid === firstSignerId
        ) {
          return taxReturn.spouse.name;
        } else if (
          taxReturn.spouse.clientGuid === loggedInUserGuid &&
          taxReturn.spouse.clientGuid === firstSignerId
        ) {
          return taxReturn.taxpayer.name;
        } else {
          return "";
        }
      }
    }
  }

  static isNextSignerDeceased = (
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData?: ISignerModel[]
  ) => {
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return false;
    }
    if (signerData && signerData.length > 0) {
      if (signerData[0].signerType == ClientTypesNumber.Taxpayer) {
        return taxReturn && taxReturn.spouse && taxReturn.spouse.isDeceased;
      }
      if (signerData[0].signerType == ClientTypesNumber.Spouse) {
        return taxReturn && taxReturn.taxpayer && taxReturn.taxpayer.isDeceased;
      }
    } else {
      if (
        taxReturn.documentSettings.deliverySettings.deliverTo ==
        ClientTypes.Taxpayer
      ) {
        return taxReturn && taxReturn.spouse && taxReturn.spouse.isDeceased;
      }
      if (
        taxReturn.documentSettings.deliverySettings.deliverTo ==
        ClientTypes.Spouse
      ) {
        return taxReturn && taxReturn.taxpayer && taxReturn.taxpayer.isDeceased;
      }
    }
    return false;
  };

  static isTaxpayerDeceased = (
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData?: ISignerModel[]
  ) => {
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return false;
    }
    if (signerData && signerData.length > 0) {
      return taxReturn && taxReturn.taxpayer && taxReturn.taxpayer.isDeceased;
    }
    return false;
  };

  static bothSignerDeceased(
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[]
  ) {
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return false;
    }
    if (signerData && signerData.length > 0) {
      return (
        taxReturn &&
        taxReturn.spouse &&
        taxReturn.spouse.isDeceased &&
        taxReturn &&
        taxReturn.taxpayer &&
        taxReturn.taxpayer.isDeceased
      );
    }
    return false;
  }

  static anyOneSignerDeceased(
    taxReturn: TaxDocument.IMarriedJointTaxReturn,
    signerData: ISignerModel[]
  ) {
    if (!TaxpayerHelper.isMarried(taxReturn)) {
      return false;
    }
    if (signerData && signerData.length > 0) {
      return (
        (taxReturn && taxReturn.spouse && taxReturn.spouse.isDeceased) ||
        (taxReturn && taxReturn.taxpayer && taxReturn.taxpayer.isDeceased)
      );
    }

    return false;
  }

  static isPreviewMode(clientProcessState: IClientProcessViewModel) {
    return clientProcessState.role.toString() === Role[Role.CPA].toString();
  }

  static isVoucherExist(taxReturn: TaxDocument.ITaxReturn) {
    const formGroup: IGroup = taxReturn.formGroups.find(
      (x) => x.group === DocumentGroups.Vouchers
    ) || {
      group: DocumentGroups.Vouchers,
      forms: [],
    };
    let validVouchers = false;
    let vouchers: IVoucher[] = formGroup.forms as IVoucher[];
    for (var i = 0; i < vouchers.length; i++) {
      if (vouchers[i].amount > 0) {
        validVouchers = true;
        break;
      }
    }
    return validVouchers;
  }

  static isVoucherReminderDisabled = (payScreenData: IPayScreenViewModel) => {
    if (payScreenData.vouchers && payScreenData.vouchers.length > 0) {
      if (
        payScreenData.vouchers.every(
          (m) => Moment.utc(m.dueDate) < Moment.utc(new Date())
        ) ||
        payScreenData.vouchers.every((m) => m.isPaid === true)
      ) {
        return true;
      }
    } else {
      return true;
    }
  };

  static isPreviewModeWithWritePermission(
    clientProcessState: IClientProcessViewModel
  ) {
    return (
      clientProcessState.role.toString() === Role[Role.CPA].toString() &&
      clientProcessState.permission !== Permissions.Read
    );
  }

  static getSignFlowRedirectUrl(
    clientProcessState: IClientProcessViewModel,
    clientId: string,
    controllerId?: string
  ) {
    if (
      (clientProcessState.isDelegated &&
        (clientProcessState.currentstep.lastVisitedStep ===
          SignProcessSteps.Pay ||
          clientProcessState.currentstep.lastVisitedStep ===
            SignProcessSteps.SignComplete)) ||
      clientProcessState.isSigned === true ||
      clientProcessState.currentstep.lastVisitedStep ===
        SignProcessSteps.SignerProcessComplete
    ) {
      return getRoutePathUrl(
        PathConstants.CompletionWizard,
        clientId,
        controllerId
      );
    } else if (
      clientProcessState.role.toString() === Role[Role.CPA].toString()
    ) {
      return `${PathConstants.PreviewFlow}${clientId}`;
    }
    return "";
  }

  static getSignCompletedRedirectUrl(
    clientProcessState: IClientProcessViewModel,
    clientId: string,
    controllerId?: string
  ) {
    let index = NO_INDEX;

    if (clientProcessState.currentstep.visitedSteps) {
      index = clientProcessState.currentstep.visitedSteps.findIndex(
        (x: SignProcessSteps) => x == SignProcessSteps.SignerProcessComplete
      );
    }

    if (
      !clientProcessState.isSigned &&
      index === NO_INDEX &&
      !clientProcessState.isDelegated
    ) {
      if (clientProcessState.role.toString() !== Role[Role.CPA].toString()) {
        return `${PathConstants.SignFlow}${clientId}`;
      } else if (clientProcessState.permission === Permissions.Read) {
        return `${PathConstants.PreviewFlow}${clientId}`;
      }
    }
    return "";
  }

  static getDelegateeSignFlowRedirectUrl(
    clientProcessState: IClientProcessViewModel,
    clientId: string
  ) {
    if (
      clientProcessState.currentstep.lastVisitedStep ===
        SignProcessSteps.SignComplete ||
      clientProcessState.currentstep.lastVisitedStep ===
        SignProcessSteps.SignerProcessComplete ||
      clientProcessState.isSigned === true
    ) {
      return `${DelegateePathConstants.CompletionWizard}${clientId}`;
    }
    return "";
  }

  static ifDelegateeExistsAndSigned(
    isDelegated: boolean,
    isSigned: boolean
  ): boolean {
    if (isDelegated) {
      return isSigned;
    }
    return true;
  }
  static getDocumentStatus(status: string): DocumentStatus {
    switch (status) {
      case "USERSIGNED":
        return DocumentStatus.USERSIGNED;
      case "DELIVERED":
        return DocumentStatus.DELIVERED;
      case "DELETED":
        return DocumentStatus.DELETED;
      case "RECALLED":
        return DocumentStatus.RECALLED;
      default:
        return DocumentStatus.None;
    }
  }

  static isTransmittalUpdated = (taxReturn: TaxDocument.ITaxReturn) => {
    let transmittals =
      taxReturn &&
      taxReturn.formGroups.filter((val: any) => {
        return val.group === DocumentGroups.Transmittals;
      });

    if (transmittals.length !== 0 && transmittals[0].forms.length !== 0) {
      let formData: any = transmittals[0].forms;

      let isAnyPageUpdated = formData.some((formValue: any) => {
        return formValue.transmittalStatus === TransmittalStatus.New;
      });

      return isAnyPageUpdated;
    }

    return false;
  };

  static anyTransmittals = (taxReturn: TaxDocument.ITaxReturn) => {
    let transmittals =
      taxReturn &&
      taxReturn.formGroups.filter((val: any) => {
        return val.group === DocumentGroups.Transmittals;
      })[0];

    return transmittals && transmittals.forms.length > 0;
  };

  static isTrialView() {
    return VIEW_TYPE.toUpperCase() === "TRIAL";
  }

  static getAuthorityImageName = (stateAbbreviation: string) => {
    let imageName = StateImages.find(
      (img) =>
        img.stateUSA === StateUSA[stateAbbreviation as keyof typeof StateUSA]
    )?.imageName;
    return imageName;
  };

  static getAuthorityImageNameByState = (state: string) => {
    let imageName = StateImages.find(
      (img) => img.stateUSA === state
    )?.imageName;
    if (!imageName) {
      imageName = StateImages.find(
        (s) => s.stateUSA.substring(0, s.stateUSA.indexOf("[")) === state
      )?.imageName;
    }
    return imageName;
  };
}
