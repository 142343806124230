import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import Tour, { ReactourStep } from 'reactour';
import { SignProcessSteps } from '../../../components/Home/TaxpayerSignFlowManager';
import { ITutorialInfo } from '../../../core/domain/models/ITutorialInfo';
import { ICommonDataViewModel } from '../../../core/domain/viewModels/ICommonDataViewModel';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import { ISignProcessInfoViewModel } from '../../../core/domain/viewModels/ISignProcessInfoViewModel';
import { ISummaryViewModel, getEstimatedVouchers } from '../../../core/domain/viewModels/ISummaryViewModel';
import { SummaryTurorialConstants } from '../../Common/Constants';
import { EstimatedVoucher } from './EstimatedVoucher';
import { LeftBar } from './LeftBar';
import { Main } from './Main';
import { PaymentAndRefund } from './PaymentAndRefund';
import { Welcome } from './Welcome';
import { logger } from '../../../routes';
import { StepLayout } from 'src/components/Common/StepLayout/StepLayout';
import { ButtonComponent } from 'cp-common-ui-components';

export interface WizardBodyProps {
    summaryData: ISummaryViewModel,
    commonData: ICommonDataViewModel,
    requestSummaryDetails: (clientId: string) => any;
    initiateDocumentStatusPolling?: (retrycount: number, step: number, callback: (step?: number) => void) => void;
    match: match;
    history: History;
    goToStep: (stepNumber: number) => void;
    goToStepById: (id: any) => void;
    showPaymentRefundPrint: boolean;
    isPreviewReady?: boolean;
    signProcessInfo: ISignProcessInfoViewModel,
    tutorialInfo: ITutorialInfo,
    hideSummaryTutorial: (clientId: string, doNotShowNextTime: boolean) => any;
    headerInfo: IHeaderInfoViewModel;
    prepareMessageExist: boolean;
    goToNextStep: () => void;
    isPreviewMode: boolean;
}
export interface WizardBodyState {
    isTourOpen: boolean;
    isDoNotShowCkbChecked: boolean;
    card1Open: boolean;
    card2Open: boolean;
}

const NO_INDEX = -1;

export class WizardBody extends React.Component<WizardBodyProps, WizardBodyState> {//WizardBodyState
    constructor(props: WizardBodyProps) {
        super(props);
        this.state = {
            isTourOpen: true,
            isDoNotShowCkbChecked: false,
            card1Open: true,
            card2Open: false,
        }
    }

	componentDidMount() {
		const param: any = this.props.match.params;
		if (param.clientId && this.props.summaryData.documentId === 0) {
			this.props.requestSummaryDetails(param.clientId);
		}
        logger.trackPageView('Welcome Summary Page', { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
	}

    static getDerivedStateFromProps(nextProps: WizardBodyProps, prevState: WizardBodyState) {
        return { isTourOpen: false };
    }

    goToStep = (step: SignProcessSteps) => {
        if (!this.props.isPreviewReady && this.props.initiateDocumentStatusPolling) {
            this.props.initiateDocumentStatusPolling(0, step, this.props.goToNextStep);
        } else {

            let index = NO_INDEX;
            index = this.props.signProcessInfo.visitedSteps && this.props.signProcessInfo.visitedSteps.indexOf(step);

            if (index != null && index != NO_INDEX) {
                this.props.goToStepById(step);
            }
            else {
                if (this.props.prepareMessageExist) {
                    this.props.goToStepById(SignProcessSteps.PreparerMessage.toString());
                }
                else {
                    this.props.goToNextStep();
                }
            }
        }
    }

    onTutorialDone = () => {
        const param: any = this.props.match.params;
        if (param.clientId) {
            this.props.hideSummaryTutorial(param.clientId, this.state.isDoNotShowCkbChecked);
        }
    }

    onDoNotShowCkbChecked = (event: any) => {
        this.setState({ isDoNotShowCkbChecked: event.target.checked });
    }

    getTaxYearModel = (goTo: any) => {
        return (
            <div>
                <div className="tutorial-header"><span>{SummaryTurorialConstants.TaxYearModal.header}</span></div>
                <div className="tutorial-body"><p>{SummaryTurorialConstants.TaxYearModal.body}</p></div>
                <div className="tutorial-footer">
                    <div className="tutorial-checkbox">
                        <input data-test-auto="2dd867ee-894f-4aaa-8bde-8487e3bf84bf" type="checkbox" id="chkDoNotShowInst" name="chkDoNotShowInst" className='form-check-inline' onChange={this.onDoNotShowCkbChecked} checked={this.state.isDoNotShowCkbChecked} aria-label='Toggle Checkbox' />
                        <span className="tutorial-checkmark"></span>
                        <label className="tutorial-checkbox-label" htmlFor="chkDoNotShowInst"> {SummaryTurorialConstants.TaxYearModal.doNotShowInstruction} </label>

                        <ButtonComponent
                            data-test-auto="8a19e5ba-5523-4391-857d-dea4f4fa262b"
                            onClick={() => { if (this.state.isDoNotShowCkbChecked) { this.onTutorialDone() } else { goTo(1) } }}
                            variant={"primary"}
                            size={"medium"}
                            buttonClassName={"flrt"}
                        >
                            {this.state.isDoNotShowCkbChecked ? SummaryTurorialConstants.Buttons.done : SummaryTurorialConstants.Buttons.next}
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        );
    }

    toggleCards = (cardNumber: number) => {
        if (cardNumber === 1) {
            this.setState({ card1Open: !this.state.card1Open }, () => {
                this.setState({ card2Open: this.state.card1Open ? false : true });
            });
        } else if (cardNumber === 2) {
            this.setState({ card2Open: !this.state.card2Open }, () => {
                this.setState({ card1Open: this.state.card2Open ? false : true });
            });
        }
    }

    public render() {

        const steps: ReactourStep[] = [];
        const estimatedVouchers = getEstimatedVouchers(this.props.summaryData.vouchers, this.props.commonData.taxingAuthorities);

        return (
            <StepLayout className='summary-screen'>
                <div id="summary-review" className="row col-xl-9 col-lg-12 col-md-12 col-sm-12 width-100 summary-step-container">
                    <LeftBar data-test-auto="58D54D95-2CCA-482C-B110-4990338FA73E">
                        <PaymentAndRefund
                            taxYear={this.props.summaryData.taxYear}
                            addedRefunds={this.props.summaryData.addedRefunds}
                            vouchers={this.props.summaryData.vouchers}
                            refunds={this.props.summaryData.refunds}
                            taxingAuthorities={this.props.commonData.taxingAuthorities}
                            showPaymentRefundPrint={this.props.showPaymentRefundPrint}
                            data-test-auto="127CE518-D505-4AE9-BA47-E207BDA7FD47"
                            headerInfo={this.props.headerInfo}
                            card1Open={this.state.card1Open}
                            toggleCards={this.toggleCards}
                            isEstimatedVouchersEmpty={estimatedVouchers.length <= 0}
                        />
                        {
                            estimatedVouchers.length > 0 ?
                            <EstimatedVoucher
                                taxYear={this.props.summaryData.taxYear}
                                vouchers={this.props.summaryData.vouchers}
                                taxingAuthorities={this.props.commonData.taxingAuthorities}
                                data-test-auto="8E209449-6AE0-4C69-8206-69A98972158B"
                                card2Open={this.state.card2Open}
                                toggleCards={this.toggleCards}
                            /> :
                            <></>
                        }
                    </LeftBar>
                    <Main data-test-auto="E3AB8F34-5E77-420C-855D-EAE43876B695">
                        <Welcome clientName={this.props.summaryData.clientName}
                            hasShareholders={this.props.summaryData.hasShareholders}
                            hasPaperFiles={this.props.summaryData.hasPaperFiles}
                            taxYear={this.props.summaryData.taxYear}
                            goToStep={this.goToStep}
                            goToStepById={this.props.goToStepById}
                            data-test-auto="4F0A55EC-2D9C-4A27-9A90-3D161C2BD7DB"
                            vouchers={this.props.summaryData.vouchers}
                        />
                    </Main>
                    <Tour
                        steps={steps}
                        isOpen={this.state.isTourOpen}
                        onRequestClose={() => { }}
                        disableDotsNavigation={true}
                        showNavigation={false}
                        showNumber={false}
                        showCloseButton={false}
                        showButtons={false}
                        closeWithMask={false}
                    />
                </div>
            </StepLayout>
        );
    }
}
