import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../index';
import { actionTypes } from '../ActionTypes';
import { ITaxDocumentModel, initialTaxDocumentModel } from '../../core/domain/models/ITaxDocumentModel';
import { DocumentSignatureDataViewModel } from '../../core/domain/viewModels/IDocumentSignatureDataViewModel';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios'
import { AxiosResponse } from 'axios';
import { StatusType, NotificationAction } from '../Common/NotificationStore';
import { IDocument } from '../../core/domain/models/esign/Document';
import { IDocumentAdapter, DocumentAdapter } from '../../core/services/adapters/ControlDataAdapter'
import * as Constants from '../../components/Common/Constants';
import { IKBATransactionResponse, IBaseServiceResponse, initialKBAResponse, IKBAAnswers } from '../../core/domain/models/IKBA';
import { KBATransactionResultType, SignatureType, ClientType } from '../../core/common/Enums';
import { ISignerControlDataModal } from '../../core/domain/viewModels/ISignerControlDataModal';
import { ISignerModel, initialSignerData } from '../../core/domain/models/ISignerModel';
import { ILoader } from '../../core/utilities/ui/Loader';
import { TYPES } from '../../startup/types';
import { container } from '../../startup/inversify.config';
import { encodeChoiceText, getUserTimeZone } from '../../components/Helper/HelperFunction';
import { IMarriedJointTaxReturn } from '../../core/domain/models/ITaxReturn';
import { actionCreators as documentStoreActions } from './TaxDocumentStore';
import { TelemetryLogger } from '../../components/Logger/AppInsights';
import { IAppInsightCustomProperties } from '../../core/domain/models/IAppInsightCustomProperties';



const logger = TelemetryLogger.getInstance();


interface RequestEsignDocumentAction {
	type: actionTypes.ESIGN_REQUEST;
}

interface ResponseEsignDocumentAction {
	type: actionTypes.ESIGN_RESPONSE;
	data: ITaxDocumentModel;
}

interface FailureEsignDocumentAction {
	type: actionTypes.ESIGN_FAILURE;
	data: ITaxDocumentModel;
}

interface RequestSignatureControlsDataAction {
	type: actionTypes.SIGNATURE_CONTROLS_DATA_REQUEST;
}

interface ResponseSignatureControlsDataAction {
	type: actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE;
	data: IDocument[];
}

interface FailureSignatureControlsDataAction {
	type: actionTypes.SIGNATURE_CONTROLS_DATA_FAILURE;
}

interface RequestCADocumentAction {
	type: actionTypes.CA_REQUEST;
}

interface ResponseCADocumentAction {
	type: actionTypes.CA_RESPONSE;
	data: IKBATransactionResponse;
}

interface FailureCADocumentAction {
	type: actionTypes.CA_FAILURE;
	data: IKBATransactionResponse;
}

interface RequestSignerDetailsAction {
	type: actionTypes.SIGNER_REQUEST;
}

interface ResponseSignerDetailsAction {
	type: actionTypes.SIGNER_RESPONSE;
	data: ISignerModel[];
}

interface FailureSignerDetailsAction {
	type: actionTypes.SIGNER_FAILURE;
	data: ISignerModel[];
}

interface RequestEsignSubmitAction {
	type: actionTypes.SIGN_SUBMIT_REQUEST;
}

interface ResponseEsignSubmitAction {
	type: actionTypes.SIGN_SUBMIT_RESPONSE;
	data: IDocument[];
}

interface FailureEsignSubmitAction {
	type: actionTypes.SIGN_SUBMIT_FAILURE;
	data: IDocument[];
}

interface UpdateChangedSignBehalfSpouseState {
	type: actionTypes.UPDATE_CHANGED_SIGN_BEHALF_SPOUSE_STATE;
	data: boolean;
}

export interface EsignState {
	data: ITaxDocumentModel;
	controlsData: IDocument[];
	signerDetails: ISignerModel[];
	changedSignBehalfSpouse: boolean;
}

export const initialEsignState: EsignState = {
	data: initialTaxDocumentModel,
	controlsData: [],
	signerDetails: [],
	changedSignBehalfSpouse: false,
}

export interface UpdateSignatureSettingsAction {
	type: actionTypes.UPDATE_DOCUMENT_SIGNATURE_SETTINGS_REQUEST;
	clientId: string;
	signatureType: number;
}

export interface IClientResponse {
	isSuccess: boolean,
	errorCode: string,
	errorDescription: string,
	data: any
}

type KnownAction =
	DispatchAction |
	NotificationAction;

type DispatchAction = ResponseEsignDocumentAction
	| RequestEsignDocumentAction
	| FailureEsignDocumentAction
	| RequestSignatureControlsDataAction
	| ResponseSignatureControlsDataAction
	| FailureSignatureControlsDataAction
	| UpdateSignatureSettingsAction
	| RequestCADocumentAction
	| ResponseCADocumentAction
	| FailureCADocumentAction
	| RequestSignerDetailsAction
	| ResponseSignerDetailsAction
	| FailureSignerDetailsAction
	| UpdateChangedSignBehalfSpouseState;

const loader = container.get<ILoader>(TYPES.ILoader);

export const actionCreators = {

	requestSigners: (clientGuid: string,callback?:()=>void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		dispatch({ type: actionTypes.SIGNER_REQUEST });

		

		return initializeAxios(clientGuid).get<ITaxDocumentModel>('api/Esign/GetAllSigners/' + clientGuid)
			.then(function (response: AxiosResponse<ISignerModel[]>) {

				callback && callback();
				dispatch({
					type: actionTypes.SIGNER_RESPONSE, data: response.data
				});
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.Signers,
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.SIGNER_FAILURE, data: state.esignData && state.esignData.signerDetails ? state.esignData.signerDetails : [initialSignerData] });
				
			});
	},

	clientAuthentication: (clientGuid: string, failureCallback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		dispatch({ type: actionTypes.CA_REQUEST });
		

		return initializeAxios(clientGuid).get<IBaseServiceResponse>('api/Esign/ClientAuthentication/' + clientGuid)
			.then(function (response: AxiosResponse<IBaseServiceResponse>) {
				//We may need to refactor this further where we can take out the concrete IKBATransactionResponse below and return IBaseServiceResponse.
				//To achieve it we need a separate reducer to handle IKBATransactionResponse, separate actionCreators and separate DispatchActions
				const kbaResult = response.data as IKBATransactionResponse;
				if (kbaResult.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.error]
					|| kbaResult.transactionStatus.toString() === KBATransactionResultType[KBATransactionResultType.failed]) {
					failureCallback();
						}
				else {
					dispatch({
						type: actionTypes.CA_RESPONSE, data: kbaResult
					});
					
				}
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.ClientAuthentication,
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.CA_FAILURE, data: state.kbaData ? state.kbaData : initialKBAResponse });
			
			});
	},

	clientValidation: (clientGuid: string, data: IKBAAnswers, successCallback: (isNextQuestionSet?: boolean) => void, failureCallback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		dispatch({ type: actionTypes.CA_REQUEST });
		const options = {
			headers: {
				'Accept': 'application/json, text/plain, *',
				'Content-Type': 'application/json; charset=utf-8'
			}
		};

		//Encode the choice text with URI encoding
		encodeChoiceText(data);	
		return initializeAxios(clientGuid).post<IBaseServiceResponse>('api/Esign/ClientValidation/' + clientGuid, data, options)
			.then(function (response: AxiosResponse<IBaseServiceResponse>) {
				//We may need to refactor this further where we can take out the concrete IKBATransactionResponse below and return IBaseServiceResponse.
				//To achieve it we need a separate reducer to handle IKBATransactionResponse, separate actionCreators and separate DispatchActions
				const kbaResult = response.data as IKBATransactionResponse;
				if (kbaResult.transactionStatus.toString() == KBATransactionResultType[KBATransactionResultType.failed].toString() ||
					kbaResult.transactionStatus.toString() == KBATransactionResultType[KBATransactionResultType.error].toString() ||
					kbaResult.transactionStatus.toString() == KBATransactionResultType[KBATransactionResultType.retryExceeded].toString()) {

						failureCallback();
				}
				else if (kbaResult.transactionStatus.toString() == KBATransactionResultType[KBATransactionResultType.questions].toString()) {
						successCallback(true);
					dispatch({
						type: actionTypes.CA_RESPONSE, data: kbaResult
					});
				}
				else {
					successCallback();
				}
			})
			.catch(function (error: any) {
				failureCallback();
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.ClientValidation,
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.CA_FAILURE, data: state.kbaData ? state.kbaData : initialKBAResponse });
			});
	},

	updateSpouseMail: (clientGuid: string, spouseGuid: string, id: string, newMail: string, type: ClientType, step?: boolean, callback?: (step: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		loader.show();
		dispatch({ type: actionTypes.ESIGN_REQUEST });
		const data = { clientId: id, emailAddress: newMail, clientType: type };

	
		return initializeAxios(clientGuid).put('api/Esign/UpdateEmailAddress/' + clientGuid, data)
			.then(function (response: AxiosResponse<ITaxDocumentModel>) {
				loader.hide();
				if (callback) {
					callback(step ? step : false);
				}
				
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.UpadateSpouseMail,
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.ESIGN_FAILURE, data: state.esignData.data });
				
			});
	},

	updateSpouseMobileNumber: (clientGuid: string, id: string, newMobileNumber: string, newCountryCode: string, ssn: string, type: ClientType, step?: boolean, callback?: (step: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		loader.show();
		dispatch({ type: actionTypes.ESIGN_REQUEST });
		

		const data = { clientId: id, mobileNumber: newMobileNumber, countryCode: newCountryCode, ssn: ssn, clientType: type };

		return initializeAxios(clientGuid).put('api/Esign/UpdateMobileNumber/' + clientGuid, data)
			.then(function (response: AxiosResponse<ITaxDocumentModel>) {
				loader.hide();
				let taxReturn = state.taxReturn.taxDocument as IMarriedJointTaxReturn;
				if (type === ClientType.Taxpayer) {
					taxReturn.taxpayer.mobileNumber = newMobileNumber;
					taxReturn.taxpayer.countryCode = newCountryCode;
				}
				else if (type === ClientType.Spouse) {
					taxReturn.spouse.mobileNumber = newMobileNumber;
					taxReturn.spouse.countryCode = newCountryCode;

				}
				documentStoreActions.updateTaxDocument(taxReturn);
				if (callback) {
					callback(step ? step : false);
				}
			
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.UpadateSpouseMail,
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.ESIGN_FAILURE, data: state.esignData.data });
							});
	},

	updateDOB: (clientGuid: string, id: string, dob: string, step: boolean, callback: (step: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		loader.show();
		dispatch({ type: actionTypes.ESIGN_REQUEST });
		const data = { clientId: id, dateOfBirth: dob };

		
		return initializeAxios(clientGuid).put('api/Esign/UpdateDateOfBirth/' + clientGuid, data)
			.then(function (response: AxiosResponse<ITaxDocumentModel>) {
				loader.hide();
				callback(step);
			
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.UpdatingDob,
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.ESIGN_FAILURE, data: state.esignData && state.esignData.data ? state.esignData.data : initialEsignState.data });
			
			});
	},

	requestSignatureControls: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_REQUEST });
		
		return initializeAxios(clientGuid).get<ITaxDocumentModel>('api/Esign/GetDocumentSignatureDataAsync/' + clientGuid)
			.then(function (response: AxiosResponse<DocumentSignatureDataViewModel[]>) {
				const documentAdapter: IDocumentAdapter = DocumentAdapter.create();

			
				dispatch({
					type: actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE, data: documentAdapter.convertToClientModel(response.data)
				});
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.SignedDocumentError,
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_FAILURE });
				
			});
	},

	requestSignBehalfSpouseSignatureControls: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		
		dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_REQUEST });
		return initializeAxios(clientGuid).get<ITaxDocumentModel>('api/Esign/GetDocumentSignatureDataAsync/SignOnBehalfSpouse/' + clientGuid)
			.then(function (response: AxiosResponse<DocumentSignatureDataViewModel[]>) {
				const documentAdapter: IDocumentAdapter = DocumentAdapter.create();

				
				dispatch({
					type: actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE, data: documentAdapter.convertToClientModel(response.data)
				});
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.SignedDocumentError,
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_FAILURE });
				});
	},

	requestPreviewSignatureControls: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_REQUEST });
		
		return initializeAxios(clientGuid).get<ITaxDocumentModel>('api/Esign/GetPreviewDocumentSignatureDataAsync/' + clientGuid)
			.then(function (response: AxiosResponse<DocumentSignatureDataViewModel[]>) {
				const documentAdapter: IDocumentAdapter = DocumentAdapter.create();

				dispatch({
					type: actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE, data: documentAdapter.convertToClientModelWithDisable(response.data)
				});
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.SignedDocumentError,
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_FAILURE });
					});
	},

	requestSignBehalfSpousePreviewSignatureControls: (clientGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_REQUEST });
		
		return initializeAxios(clientGuid).get<ITaxDocumentModel>('api/Esign/GetPreviewDocumentSignatureDataAsync/SignOnBehalfSpouse/' + clientGuid)
			.then(function (response: AxiosResponse<DocumentSignatureDataViewModel[]>) {
				const documentAdapter: IDocumentAdapter = DocumentAdapter.create();

				dispatch({
					type: actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE, data: documentAdapter.convertToClientModel(response.data)
				});
				})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.SignedDocumentError,
					statusType: StatusType.Error
				});
				dispatch({ type: actionTypes.SIGNATURE_CONTROLS_DATA_FAILURE });
				});
	},

	updateDocumentSignatureSettingModel: (clientId: string, signatureType: number, callback: any, failureCallback?:()=> void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const state = getState();
		loader.show();
		dispatch({ type: actionTypes.UPDATE_DOCUMENT_SIGNATURE_SETTINGS_REQUEST, clientId: clientId, signatureType: signatureType });

	

		return initializeAxios(clientId).put<number>('api/Esign/UpdateEsignFormSelectionAsync/' + signatureType + '/' + clientId)
			.then(function (response: AxiosResponse<IClientResponse>) {
				const { data } = response;
				loader.hide();
				if (data.isSuccess) {
					
					callback();
					}
				else {

					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: data.errorDescription,
						statusType: StatusType.Warning
					});
				}
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response ? error.response.statusText : Constants.ErrorMessages.DocumentSignatureSetting,
					statusType: StatusType.Error
				});

				failureCallback && failureCallback();
			});
	},

	updateSignatureControlsData: (data: IDocument[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({
			type: actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE, data: data
		});
	},

	sign: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		let signatureData: ISignerControlDataModal = DocumentAdapter.create().convertToServerModel(documentData);
		signatureData.clientGuid = clientId;


		initializeAxios(clientId).postJson<boolean>(signatureData, 'api/Esign/v2/SignAsync/timeZone/' + clientId + '?userTimeZone=' + getUserTimeZone())
			.then(function (response: AxiosResponse<boolean>) {
				const { data } = response;
				if (data) {
						callback(data);
				}
				else {
					
					dispatch({
						type: actionTypes.NOTIFICATION, statusMessage: Constants.SiginingConstants.WarningMessage.ManualSignStatusWarning,
						statusType: StatusType.Warning
					});
				}
				loader.hide();
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response?.statusText ?? error.message,
					statusType: StatusType.Error
				});
				});
	},

	signOnBehalfSpouse: (clientId: string, documentData: IDocument[], taxpayerGuid: string, spouseGuid: string, callback: (status: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
		loader.show();
		let signatureData: ISignerControlDataModal[] = DocumentAdapter.create().convertToServerModelMultiSign(documentData, taxpayerGuid, spouseGuid);

		initializeAxios(clientId).postJson<boolean>(signatureData, 'api/Esign/v2/SignOnBehalfSpouseAsync/' + clientId + '?userTimeZone=' + getUserTimeZone())
			.then(function (response: AxiosResponse<boolean>) {
				const { data } = response;
				callback(data);
				loader.hide();
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION, statusMessage: error.response?.statusText ?? error.message,
					statusType: StatusType.Error
				});
			});
	},

	updateSignBehalfSpouseState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({
			type: actionTypes.UPDATE_CHANGED_SIGN_BEHALF_SPOUSE_STATE, data: !getState().esignData.changedSignBehalfSpouse
		});
	},
}

export const reducer: Reducer<EsignState> = (state: EsignState = initialEsignState, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	const currentState = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.ESIGN_REQUEST:
			currentState.data = initialTaxDocumentModel;
			return currentState;
		case actionTypes.ESIGN_RESPONSE:
			currentState.data = action.data;
			return currentState;
		case actionTypes.ESIGN_FAILURE:
			currentState.data = action.data
			return currentState;
		case actionTypes.SIGNATURE_CONTROLS_DATA_REQUEST:
			currentState.controlsData = [];
			return currentState;
		case actionTypes.SIGNATURE_CONTROLS_DATA_RESPONSE:
			currentState.controlsData = action.data;
			return currentState;
		case actionTypes.SIGNER_REQUEST:
			return initialEsignState;
		case actionTypes.SIGNER_RESPONSE:
			currentState.signerDetails = action.data;
			return currentState;
		case actionTypes.SIGNER_FAILURE:
			currentState.signerDetails = action.data
			return currentState;
		case actionTypes.UPDATE_CHANGED_SIGN_BEHALF_SPOUSE_STATE:
			currentState.changedSignBehalfSpouse = action.data;
			return currentState;
		default:
			return currentState || initialEsignState;
	}
};

export const kbaReducer: Reducer<IKBATransactionResponse> = (state: IKBATransactionResponse = initialKBAResponse, incomingAction: Action) => {
	const action = incomingAction as DispatchAction;
	const currentState = Object.assign({}, state);
	switch (action.type) {
		case actionTypes.CA_REQUEST:
			return initialKBAResponse;
		case actionTypes.CA_RESPONSE:
			return action.data;
		case actionTypes.CA_FAILURE:
			return currentState;
		default:
			return currentState || initialKBAResponse;
	}
};