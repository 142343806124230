import "react-phone-input-2/lib/style.css";
import styles from "./ConsentPopUp.module.scss";

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { isValidTenDigitNumber } from "../Common/Validations";
import { ButtonComponent } from "cp-common-ui-components";

interface IConsentPopUpProps {
  companyName: string;
  handleConsent: (
    consentGiven: boolean,
    countryCodeInput?: string,
    mobileNumberInput?: string
  ) => void;
  countryCode: string;
  mobileNumber: string;
}

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

const ConsentPopUp = (props: IConsentPopUpProps) => {
  const { companyName, countryCode, mobileNumber } = props;
  const [mobileNumberInput, setMobileNumberInput] = useState(mobileNumber);
  const [countryCodeInput, setCountryCodeInput] = useState(countryCode ?? "+1");
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [mobileNumberErrorMessage, setMobileNumberErrorMessage] = useState("");

  const onChangeCountryCode = (
    fullValue: string,
    countryObject: CountryData
  ) => {
    setCountryCodeInput(`+${countryObject.dialCode}`);
  };

  const onChangeMobileNumberInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isValidTenDigitNumber(event.target.value))
      setMobileNumberInput(event.target.value);
  };

  const onBlurMobileNumberInput = () => {
    if (mobileNumberInput.length === 0) {
      setMobileNumberError(true);
      setMobileNumberErrorMessage("This field is required");
    } else if (mobileNumberInput.length < 10) {
      setMobileNumberError(true);
      setMobileNumberErrorMessage("Please enter a 10 digit mobile number");
    } else {
      setMobileNumberError(false);
      setMobileNumberErrorMessage("");
    }
  };

  return (
    <>
      <Modal.Body className={`${styles.consentPopUpBody}`}>
        <div className={styles.consentPopUpBodyHeading}>
          Would you like to receive SMS Text Notification?
        </div>
        <p>
          By responding "Yes", I authorize <span>{companyName}</span> and its
          service providers (including SafeSend) to send me notifications and
          other messages at the number provided on this form via text (SMS)
          using automated dialing technology. Consent is not a condition for
          purchase. Message and data rates may apply. To opt-out at any time,
          reply "STOP".
        </p>
        <div className={styles.mobileNumberSection}>
          <label htmlFor="mobileNumberInput">Enter Mobile Number</label>
          <div
            className={`${styles.countryCodeMobileNumberWrapper} ${
              mobileNumberError ? styles.mobileNumberError : ""
            }`}
          >
            <PhoneInput
              containerClass={styles.phoneInput}
              country={"us"}
              autoFormat={false}
              placeholder=""
              value={countryCodeInput}
              onChange={onChangeCountryCode}
            />
            <input
              type="text"
              name="mobileNumberInput"
              id="mobileNumberInput"
              value={mobileNumberInput}
              onChange={onChangeMobileNumberInput}
              onBlur={(e) => {
                onBlurMobileNumberInput();
              }}
              className={styles.mobileNumberInput}
              maxLength={10}
            />
          </div>
          <div
            id="mobileNumberErrorMessage"
            className={styles.mobileNumberErrorMessage}
          >
            {mobileNumberErrorMessage}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.consentPopUpFooter}>
        <ButtonComponent
          data-test-auto="e015b681-c1af-4419-8033-e4cb0db2d180"
          onClick={() => {
            props.handleConsent(false);
          }}
          variant={"danger"}
          size={"medium"}
        >
          No
        </ButtonComponent>
        <ButtonComponent
          data-test-auto="9ff5fabf-fb69-4a7e-b3dc-f2365b860dc9"
          disabled={mobileNumberInput.length < 10}
          onClick={() => {
            props.handleConsent(true, countryCodeInput, mobileNumberInput);
          }}
          variant={"success"}
          size={"medium"}
        >
          Yes
        </ButtonComponent>
      </Modal.Footer>
    </>
  );
};

export default ConsentPopUp;
