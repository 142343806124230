import * as React from "react";
import { Modal, Row } from "react-bootstrap";
import TinyMCEComponent from "../Common/TinyMCE";
import {
    IDelegateeSignerDetails,
    initialState,
    IDelegateeInfo,
    DelegateeSignerDetails
} from "../../core/domain/models/IDelegateeSignerModel";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { ITaxReturn } from "../../core/domain/models/ITaxReturn";
import { isValidEmailAddress, validateMobileLength } from "../Common/Validations";
const htmlencode = require("htmlencode");
import { History } from "history";
import { DelegateeSignerConstants, PathConstants, ValidationContants, getRoutePathUrl } from "../Common/Constants";
import { SignProcessSteps } from "../Home/TaxpayerSignFlowManager";
import { DelegateeCancelPopup } from "./DelegateeCancelPopup";
import { logger } from "../../../src/routes";
import { CloseIcon } from "../Common/Icons/SvgIcons";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { ButtonComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../Helper/HelperFunction";

interface AssignToDelegateeProps {
    onReAssign?: (email: string, name: string, reason: string) => void;
    onCancel: () => void;
    show: boolean;
    param?: any;
    history?: History;
    taxReturn?: ITaxReturn;
    saveDelegateeSignerDetails?: (clientId: string, delegatee: IDelegateeSignerDetails, callback: () => void) => void;
    isDelegateeAssigned?: boolean;
    successMsg: string;
    goToStep?: (stepNumber: number) => void;
    nextStep?: number;
    cancelDelegation?: (clientId: string, remarks: string, taxYear: number, callback: () => void) => void;
    resetLastVisitedSteps?: (clientId: string, callback: () => void) => void;
    saveDelegateeState?: () => void;
    delegateeSigner?: IDelegateeInfo;
    isPreview: boolean;
}

interface AssignToDelegateeState {
    delegateeDetails: IDelegateeSignerDetails;
    showCancelDelegationModal: boolean;
    reasonForCancel: string;
    isStateUpdated: boolean;
}

export class AssignToDelegatee extends React.Component<AssignToDelegateeProps, AssignToDelegateeState> {
    constructor(props: AssignToDelegateeProps, states: AssignToDelegateeState) {
        super(props, states);
        this.state = {
            delegateeDetails: initialState,
            showCancelDelegationModal: false,
            reasonForCancel: "",
            isStateUpdated: false
        };
    }

    static getDerivedStateFromProps(nextProps: AssignToDelegateeProps, prevState: AssignToDelegateeState) {
        if (
            nextProps.delegateeSigner != null &&
            nextProps.delegateeSigner != undefined &&
            nextProps.isDelegateeAssigned &&
            prevState.delegateeDetails.email == "" &&
            !prevState.isStateUpdated
        ) {
            return {
                delegateeDetails: DelegateeSignerDetails.create(
                    nextProps.delegateeSigner.firstName,
                    nextProps.delegateeSigner.lastName,
                    nextProps.delegateeSigner.email,
                    nextProps.delegateeSigner.email,
                    nextProps.delegateeSigner.countryCode,
                    nextProps.delegateeSigner.mobileNumber,
                    nextProps.delegateeSigner.description
                )
            };
        }
    }

    onChangeEmail = (event: any) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.email = event.target.value;
        this.setState({ delegateeDetails: delegateeSigner, isStateUpdated: true });
    };

    onChangeConfirmEmail = (event: any) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.confirmEmail = event.target.value;
        this.setState({ delegateeDetails: delegateeSigner });
    };

    onChangeFirstName = (event: any) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.firstName = event.target.value;
        this.setState({ delegateeDetails: delegateeSigner });
    };

    onChangeLastName = (event: any) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.lastName = event.target.value;
        this.setState({ delegateeDetails: delegateeSigner });
    };

    onChangeReason = (value: any) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.description = value;
        this.setState({ delegateeDetails: delegateeSigner });
    };

    handleMobileChanges = (fullValue: string, countryObject: CountryData) => {
        let delegateeSigner = { ...this.state.delegateeDetails };
        delegateeSigner.mobileNumber = fullValue.slice(countryObject.dialCode.length);
        delegateeSigner.countryCode = `+${countryObject.dialCode}`;
        this.setState({
            delegateeDetails: delegateeSigner
        });
    };

    validateDetails = () => {
        let result = true;
        if (this.state.delegateeDetails.email?.trim() == "") {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.EmptyEmailAdrress, "");
            return (result = false);
        } else if (!isValidEmailAddress(this.state.delegateeDetails.email?.trim())) {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.InvalidEmailAddress, "");
            return (result = false);
        } else if (!isValidEmailAddress(this.state.delegateeDetails.confirmEmail?.trim())) {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.InvalidConfirmEmail, "");
            return (result = false);
        } else if (this.state.delegateeDetails.email?.trim() != this.state.delegateeDetails.confirmEmail?.trim()) {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.EmailDontMatch, "");
            return (result = false);
        } else if (this.state.delegateeDetails.firstName?.trim() == "") {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.EmptyFirstName, "");
            return (result = false);
        } else if (this.state.delegateeDetails.description?.trim() == "") {
            MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.EmptyDescription, "");
            return (result = false);
        } else if (
            this.state.delegateeDetails.mobileNumber.length > 0 ||
            this.state.delegateeDetails.countryCode?.trim() != ""
        ) {
            if (!validateMobileLength(this.state.delegateeDetails.mobileNumber)) {
                MarsNotifier.Warning(DelegateeSignerConstants.WarningMessage.InvalidMobileNumber, "");
                return (result = false);
            } else if (this.state.delegateeDetails.countryCode?.trim() == "") {
                MarsNotifier.Warning(ValidationContants.CountryCodeWarning, "");
                return (result = false);
            }
        }
        return result;
    };

    onSubmit = () => {
        var isValidate = this.validateDetails();
        if (isValidate) {
            this.props.saveDelegateeSignerDetails &&
                this.props.saveDelegateeSignerDetails(this.props.param.clientId, this.state.delegateeDetails, () => {
                    this.props.saveDelegateeState && this.props.saveDelegateeState();
                    this.props.goToStep &&
                        this.props.goToStep(this.props.nextStep ? this.props.nextStep : SignProcessSteps.Pay);
                    MarsNotifier.Success(this.props.successMsg, null);
                    logger.trackTrace("Document signing delegated", {
                        ClientId: this.props.param.clientId,
                        PreviewMode: this.props.isPreview
                    });
                    this.onCancel();
                });
        }
    };

    onCancelDelegation = () => {
        this.setState({ showCancelDelegationModal: true });
    };

    onCloseCancelDelegationPopup = () => {
        this.setState({
            showCancelDelegationModal: false,
            reasonForCancel: "",
            isStateUpdated: false
        });
    };

    onReasonForCancelDelagtionChange = (event: any) => {
        this.setState({ reasonForCancel: event.target.value });
    };

    onSubmitCancelDelegation = () => {
        this.props.cancelDelegation &&
            this.props.cancelDelegation(
                this.props.param.clientId,
                this.state.reasonForCancel,
                this.props.taxReturn!.taxYear,
                () => {
                    this.props.resetLastVisitedSteps &&
                        this.props.resetLastVisitedSteps(this.props.param.clientId, () => {
                            this.setState({ showCancelDelegationModal: false });
                            this.onCancel();
                            MarsNotifier.Success(
                                DelegateeSignerConstants.SuccessMessage.SavedCancelDelegationSuccess,
                                null
                            );
                            if (!this.props.param.controllerId) {
                                this.props.history?.push(`${PathConstants.SignFlow}${this.props.param.clientId}`);
                            } else {
                                this.props.history?.push(
                                    getRoutePathUrl(
                                        PathConstants.SignFlow,
                                        this.props.param.clientId,
                                        this.props.param.controllerId
                                    )
                                );
                            }
                        });
                }
            );
    };

    onCancel = () => {
        this.props.onCancel();
        this.setState({
            delegateeDetails: initialState,
            isStateUpdated: false
        });
    };

    public render() {
        let messageBody: string = this.state.delegateeDetails.description;
        messageBody = htmlencode.htmlDecode(messageBody);

        return (
            <div>
                <Modal className="assign-delegatee-modal" show={this.props.show} onHide={this.onCancel} id="delegatee">
                    <Modal.Header>
                        <Modal.Title>
                            <span>Send for Signature</span>
                        </Modal.Title>
                        <div
                            data-test-auto="827fcba4-d935-40b7-aeb4-eeaf18e6cd1c"
                            className="closeIcon"
                            onClick={this.onCancel}
                            tabIndex={0}
                            onKeyDown={(e: any) => handleKeyDown(e, this.onCancel)}
                        >
                            <CloseIcon />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="delegatee">
                            <div className="content-paragraph">
                                Please provide the information of the person you would like to delegate the signing
                                process to:
                            </div>
                            <Row style={{ marginBottom: "16px" }}>
                                <div style={{ display: "contents" }}>
                                    <div className="col-sm-6 col-md-6 col-xl-6">
                                        <label className="delegatee-label" htmlFor="delegateeFirstName">
                                            First Name<span className="asterisk">*</span>
                                        </label>
                                        <input
                                            id="delegateeFirstName"
                                            className="form-control"
                                            type="text"
                                            value={this.state.delegateeDetails.firstName}
                                            onChange={(e) => {
                                                this.onChangeFirstName(e);
                                            }}
                                            data-lpignore="true"
                                            disabled={this.props.isPreview}
                                        />
                                    </div>

                                    <div className="col-sm-6 col-md-6 col-xl-6">
                                        <label className="delegatee-label" htmlFor="delegateeLastName">Last Name</label>
                                        <input
                                            id="delegateeLastName"
                                            className="form-control"
                                            type="text"
                                            value={this.state.delegateeDetails.lastName}
                                            onChange={(e) => {
                                                this.onChangeLastName(e);
                                            }}
                                            data-lpignore="true"
                                            disabled={this.props.isPreview}
                                        />
                                    </div>
                                </div>
                            </Row>

                            <Row style={{ marginBottom: "16px" }}>
                                <div style={{ display: "contents" }}>
                                    <div className="col-sm-6 col-md-6 col-xl-6">
                                        <label className="delegatee-label" htmlFor="delegateeEmail">
                                            Email<span className="asterisk">*</span>
                                        </label>
                                        <input
                                            id="delegateeEmail"
                                            className="form-control"
                                            type="text"
                                            value={this.state.delegateeDetails.email}
                                            onChange={(e) => {
                                                this.onChangeEmail(e);
                                            }}
                                            data-lpignore="true"
                                            disabled={this.props.isPreview}
                                        />
                                    </div>

                                    <div className="col-sm-6 col-md-6 col-xl-6">
                                        <label className="delegatee-label" htmlFor="delegateeConfirmEmail">
                                            Confirm Email<span className="asterisk">*</span>
                                        </label>
                                        <input
                                            id="delegateeConfirmEmail"
                                            className="form-control"
                                            type="text"
                                            value={this.state.delegateeDetails.confirmEmail}
                                            onChange={(e) => {
                                                this.onChangeConfirmEmail(e);
                                            }}
                                            data-lpignore="true"
                                            disabled={this.props.isPreview}
                                        />
                                    </div>
                                </div>
                            </Row>

                            <Row>
                                <div style={{ display: "contents" }}>
                                    <div className="col-sm-6 col-md-6 col-xl-6">
                                        <label className="delegatee-label" htmlFor="delegateeMobileNumber">SMS/Text enabled Mobile Number</label>
                                    </div>
                                </div>
                            </Row>

                            <Row style={{ marginBottom: "16px" }}>
                                <div style={{ display: "contents" }}>
                                    <div className="col-sm-6 col-md-6 col-xl-6">
                                        <div className="mobileSection">
                                            <PhoneInput
                                                inputProps={{ id: "delegateeMobileNumber" }}
                                                containerClass={`inputCCMobile`}
                                                country={"us"}
                                                autoFormat={true}
                                                placeholder="() -"
                                                value={`${this.state.delegateeDetails.countryCode}${this.state.delegateeDetails.mobileNumber}`}
                                                onChange={this.handleMobileChanges}
                                                disabled={this.props.isPreview}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Row>

                            <Row>
                                <div className="col-sm-12 col-md-12 col-xl-12">
                                    <label className="delegatee-label" htmlFor="delegationMessage">
                                        Message To Signer<span className="asterisk">*</span>
                                    </label>
                                    <TinyMCEComponent
                                        id="delegationMessage"
                                        messageBody={messageBody}
                                        changeStateTinymceBody={this.onChangeReason}
                                        readOnly={!this.props.isPreview ? 0 : 1}
                                    />
                                </div>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.isDelegateeAssigned && (
                            <ButtonComponent
                                onClick={this.onCancelDelegation}
                                disabled={this.props.isPreview}
								style={{ marginLeft: "0", marginRight: "auto" }}
                                variant={"primary"}
                                size={"medium"}
                            >
                                Cancel Delegation
                            </ButtonComponent>
                        )}

                        <ButtonComponent
                            onClick={this.onCancel}
                            variant={"outline-tertiary"}
                            buttonClassName={"button-margin-right"}
                            size={"medium"}
                        >
                            Cancel
                        </ButtonComponent>

                        <ButtonComponent
                            onClick={this.onSubmit}
                            variant={"primary"}
                            disabled={
                                this.props.isPreview ||
                                !this.state.isStateUpdated ||
                                this.state.delegateeDetails.email === this.props.delegateeSigner?.email
                            }
                            size={"medium"}
                        >
                            Send
                        </ButtonComponent>
                    </Modal.Footer>
                </Modal>

                <DelegateeCancelPopup
                    onCancel={this.onCloseCancelDelegationPopup}
                    show={this.state.showCancelDelegationModal}
                    onSaveRemarks={this.onSubmitCancelDelegation}
                    reason={this.state.reasonForCancel}
                    onRemarksChange={this.onReasonForCancelDelagtionChange}
                    title={"Reason for Cancellation"}
                    warningMessage={DelegateeSignerConstants.WarningMessage.EmptyCancelDelegationRemarks}
                />
            </div>
        );
    }
}
