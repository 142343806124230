import { History } from "history";
import * as React from "react";
import {
  Button,
  ButtonToolbar,
  Dropdown,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import {
  BootstrapTable,
  TableHeaderColumn,
  Options,
  Filter,
  TextFilter,
  CustomFilter,
  SelectFilter,
  FilterData,
  SortOrder,
} from "react-bootstrap-table";
import { match, RouteComponentProps } from "react-router";
import { BroadcastChannel } from "broadcast-channel";
import * as DashboardStore from "../../store/GroupedReturns/DashboardStore";
import * as AccountStore from "../../store/Common/AccountStore";
import { ControllerDashboardState } from "../../store/GroupedReturns/DashboardStore";
import { SignatureStatus, DocumentStatus } from "../../core/common/Enums";
import { storeControllerIdInMemory } from "../../core/services/dataAccess/DataService.Axios";
import {
  DocumentGroups,
  IGroup,
  IVoucher,
} from "../../core/domain/models/IGroup";
import { AddCCRecipientModal } from "../CCRecipient/AddCCRecipientModal";
import {
  ICCRecipientDownloadableDocuments,
  ICCRecipientModel,
} from "../../core/domain/models/ICCRecipientModel";
import { IGroupedReturnTableModel } from "../../core/domain/models/groupedReturns/IGroupedReturnTableModel";
import { OverlayLoader } from "../Common/Loader/OverlayLoader";
import { ILoader } from "../../core/utilities/ui/Loader";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import { CustomMultiSelect } from "../Common/MultipleSelectComponent";
import { PrepareTaxYear, handleKeyDown } from "../Helper/HelperFunction";
import { CustomDateFilter } from "../Common/CustomDateFilter";
import { ILogoutMessage } from "../../core/domain/models/ILogoutMessage";
import {
  DelegateeSignerConstants,
  PathConstants,
  dashboardConstants,
} from "../Common/Constants";
import { getMFJTableData } from "../Common/ControllerDashboardHelper";
import { DocumentEvent } from "../../core/domain/models/IDocumentTransaction";
import { FilterIcon, RefreshIcon, SearchIcon } from "../Common/Icons/SvgIcons";
import {
  SIGNATURE_STATUS_OPTIONS_LIST,
  SIGNATURE_STATUS_OPTIONS_STYLE,
} from "src/components/Common/Constants";
import SuiteModal from "../Common/SuiteModal";
import { ButtonComponent } from "cp-common-ui-components";
import IconMore from "../Common/Icons/IconMore";
import { Guid } from "src/core/utilities/Guid";
const loader = container.get<ILoader>(TYPES.ILoader);
const channel = new BroadcastChannel("ssr-taxpayer");

interface IDocumentSignerDetails {
  signer: string;
  isSigned: string;
  signedOn: string;
}

export type DashboardPreviewProps = {
  dashboardData: ControllerDashboardState;
  match: match;
  history: History;
  ccRecipientDownloadableDocuments: ICCRecipientDownloadableDocuments[];
} & typeof DashboardStore.actionCreators &
  typeof AccountStore.actionCreators &
  RouteComponentProps<{}>;

export interface option {
  value: number | string;
  label: string;
}

export interface DashboardPreviewState {
  paymentDueOptions?: { [id: number]: option[] };
  pageNo: number;
  sortName: string;
  sortOrder: "asc" | "desc";
  searchText: string;
  emailTaxDocuments: {
    showModal: boolean;
    documentId: number;
  };
  selectedRow: number;
  refreshDelay: boolean;

  filterTaxPayerName: string;
  filterSignatureStatus: any;
  filterK1Status: string;
  filterNextPaymentDue: string;
  filterTaxYear: any;
  filterInvoiceStatus: string;

  showSignerModal: boolean;
  signModalData: any[];
}

interface IColumn {
  header: string;
  ref?: React.RefObject<TableHeaderColumn> | string;
  key: string;
  isKey: boolean;
  dataFormat: (
    cell: any,
    row: any,
    formatExtraData: any,
    rowIndex: number
  ) => any;
  columnClassName: string;
  dataSort: boolean;
  toolTip: boolean;
  isHidden: boolean;
  width: string;
  dataAlign?: "left" | "center" | "right" | "start" | "end";
  filter?: Filter;
}

const pageSize = 10;

export class DashboardPreview extends React.Component<
  DashboardPreviewProps,
  DashboardPreviewState
> {
  constructor(props: DashboardPreviewProps) {
    super(props);
    this.state = {
      sortName: "name",
      sortOrder: "asc",
      searchText: "",
      pageNo: 1,
      emailTaxDocuments: {
        showModal: false,
        documentId: 0,
      },
      selectedRow: 0,
      refreshDelay: false,

      filterInvoiceStatus: "",
      filterK1Status: "",
      filterNextPaymentDue: "",
      filterSignatureStatus: "",
      filterTaxPayerName: "",
      filterTaxYear: "",
      showSignerModal: false,
      signModalData: [],
    };
  }

  private refInvoiceStatus = React.createRef<TableHeaderColumn>();
  private refK1Status = React.createRef<TableHeaderColumn>();
  private refNextPaymentDue = React.createRef<TableHeaderColumn>();
  private refSignatureStatus = React.createRef<CustomMultiSelect>();
  private refTaxPayerName = React.createRef<TableHeaderColumn>();
  private refTaxYear = React.createRef<CustomMultiSelect>();

  componentDidMount() {
    let param: any = this.props.match.params;
    storeControllerIdInMemory(param.clientId);
    this.props.requestGroupedDocuments(
      param.clientId,
      this.state.pageNo,
      pageSize,
      this.state.sortName,
      this.state.sortOrder,
      this.state.filterTaxPayerName,
      this.state.filterSignatureStatus,
      this.state.filterK1Status,
      this.state.filterNextPaymentDue,
      this.state.filterTaxYear,
      this.state.filterInvoiceStatus,
      this.state.searchText
    );
    this.applyLabelToInputs();
  }

  componentDidUpdate(
    prevProps: DashboardPreviewProps,
    prevState: DashboardPreviewState
  ) {
    if (
      this.state.pageNo != prevState.pageNo ||
      JSON.stringify(
        this.props.dashboardData.groupedReturnsState.groupedReturns
      ) !=
        JSON.stringify(
          prevProps.dashboardData.groupedReturnsState.groupedReturns ||
            this.props.dashboardData.groupedReturnsState.isLoading !=
              prevProps.dashboardData.groupedReturnsState.isLoading
        )
    ) {
      let paymentDueOptions: { [id: number]: option[] } = {};
      for (
        var i = 0;
        i < this.props.dashboardData.groupedReturnsState.groupedReturns.length;
        i++
      ) {
        paymentDueOptions[i] = this.getPaymentDueOptions(
          this.getVouchersDueDates(
            this.props.dashboardData.groupedReturnsState.groupedReturns[i]
              .formData
          )
        );
      }
      this.setState({
        paymentDueOptions: paymentDueOptions,
        refreshDelay: this.props.dashboardData.groupedReturnsState.isLoading,
      });

      if (this.props.dashboardData.refreshToken) {
        let param: any = this.props.match.params;
        this.props.refreshToken(param.clientId, () => {
          const message: ILogoutMessage = {
            isLoggedOut: true,
            clientGuid: param.clientId,
          };

          channel
            .postMessage(message)
            .then((value: any) => {
              this.props.logout(param.clientId, this.onLogOutCompletion);
            })
            .catch((error: any) => {
              console.log(error);
            });
        });
      }
    }
  }
  onLogOutCompletion = (id: string) => {
    let param: any = this.props.match.params;
    const url = PathConstants.ControllerLogin + id;
    this.props.history.push(url);
  };
  private getHeader = () => {
    return (
      <div className="displayFlex dashboatd-title tpGRHeaderTitleSearch">
        <h3
          id="header-dashboard"
          className="step-layout-body-header displayFlex3 no-padding col-xs-9 col-sm-9 col-md-9 col-lg-9"
        >
          Tax Return Dashboard
        </h3>
        <div className="tpGRHeaderSearch">
          <input
            id="controller-table-search"
            type="text"
            className="form-control empty"
            placeholder="Search"
            onChange={(e) => {
              this.onSearchChange(e.target.value);
            }}
            aria-label="Enter search text"
          />
          <div className="tpGRHeaderSearchIcon">
            <SearchIcon />
          </div>
        </div>
      </div>
    );
  };

  private applyLabelToInputs = () => {
    const filterInputs = document.querySelectorAll("input.filter, select.filter");
    if (filterInputs?.length > 0) {
      filterInputs.forEach((input) => {
        input.setAttribute("aria-label", "Select filter");
      });
    }
  }

  private defaultType = (cell: any, row: any) => {
    return (
      <span title={cell} className="ellipsis">
        {cell}
      </span>
    );
  };

  private k1DistributionStatusFormat = (cell: any, row: any) => {
    return (
      <span title={cell} className="ellipsis">
        {cell.toString().length === 0 ? "----" : cell}
      </span>
    );
  };

  private nameColumnFormat = (cell: any, row: any) => {
    return (
      <>
        <div style={{ position: "relative" }}>
          <div title={cell} className="DashboardNameStyle">
            {cell}
          </div>
          {row.isDocumentUpdated && row.isDocumentUpdated === 1 ? (
            <i className="fas fa-info-circle updatedDocumentInfo">
              <span className="UpdatedDocumentText">
                {dashboardConstants.UpdatedEstimatedDocument}
              </span>
            </i>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  private handleDueDateChange = (rowIndex: number, selectedValue: option) => {
    let model: IGroupedReturnTableModel =
      this.props.dashboardData.groupedReturnsState.groupedReturns[rowIndex];

    let formGroups: IGroup[] = JSON.parse(model.formData) as IGroup[];
    let vouchers: IVoucher[] = [];
    formGroups
      .filter((group) => group.group === DocumentGroups.Vouchers)
      .forEach((group) => {
        vouchers = vouchers.concat(group.forms as IVoucher[]);
      });

    vouchers.forEach((voucher: IVoucher, index: number) => {
      if (
        voucher.dueDate &&
        new Date(voucher.dueDate).getTime() >= new Date().getTime() &&
        new Date(voucher.dueDate).getTime() <
          new Date(selectedValue.value).getTime()
      ) {
        voucher.isPaymentCompleted = true;
      }
    });

    this.props.updateNextPaymentDue(
      (this.props.match.params as any).clientId,
      model.documentId,
      JSON.stringify(formGroups)
    );
  };

  private handleInvoiceStatusChange = (
    rowIndex: number,
    selectedValue: option
  ) => {
    //TODO: update invoice status
  };

  private nextPaymentDueFormat = (cell: any, row: any) => {
    const options = cell as option[];
    return (
      <div>
        {this.state.paymentDueOptions &&
          this.state.paymentDueOptions[row.rowIndex] &&
          this.state.paymentDueOptions[row.rowIndex].length > 0 && (
            <Dropdown>
              <Dropdown.Toggle as="text" id="dd-payment-due">
                {this.state.paymentDueOptions[row.rowIndex][0].label}
              </Dropdown.Toggle>

              <Dropdown.Menu alignRight={false}>
                {options.map((option, index) => {
                  return (
                    <Dropdown.Item
                      as="button"
                      key={`payment_due_${option.value}`}
                      title={option.label}
                      onClick={(e: any) =>
                        this.handleDueDateChange(row.rowIndex, option)
                      }
                      active={
                        this.state.paymentDueOptions &&
                        this.state.paymentDueOptions[row.rowIndex] !==
                          undefined &&
                        index == 0
                      }
                    >
                      {option.label}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
      </div>
    );
  };

  private invoiceStatusFormat = (cell: any, row: any) => {
    const options = [
      { label: "Unpaid", value: 0 },
      { label: "Paid", value: 1 },
    ];
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle as="text" id="dd-invoice-status">
            {options[cell ? 1 : 0].label}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {options.map((option, index) => {
              return (
                <Dropdown.Item
                  as="button"
                  key={`invoice_status_${option.value}`}
                  title={option.label}
                  onClick={(e: any) =>
                    this.handleInvoiceStatusChange(row.rowIndex, option)
                  }
                  active={cell === option.value}
                >
                  {option.label}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  handleEmailTaxDocuments(
    documentDetail: IGroupedReturnTableModel,
    rowIndex: number
  ): void {
    this.setState({
      selectedRow: rowIndex,
      emailTaxDocuments: {
        showModal: true,
        documentId: documentDetail.documentId,
      },
    });
    let param: any = this.props.match.params;
    this.props.requestCCRecipientDownloadableDocuments(
      param.clientId,
      documentDetail.documentId,
      documentDetail.documentStatus
    );
  }

  handleDownloadTaxDocuments(clientGuid: string): void {
    if (Guid.isValid(clientGuid)) {
      const clientId = (this.props.match.params as any).clientId;
      const url = `/download/${clientId}/${clientGuid?.toLowerCase()}`;
      const win = window.open(url, "_blank");
      if (win) {
        win.focus();
      }
    }
  }

  handleOpenTaxReturnClick(clientGuid: string): void {
    if (Guid.isValid(clientGuid)) {
      const clientId = (this.props.match.params as any).clientId;
      const url = `/taxpayerpreview/${clientId}/${clientGuid?.toLowerCase()}`;
      const win = window.open(url, "_blank");
      if (win) {
        win.focus();
      }
    }
  }

  createMoreActions(groupedReturn, row) {
    const dropdownBtn = document.getElementById("tpControllerMoreActions");
    if (dropdownBtn) {
      dropdownBtn.setAttribute("aria-label", "More Actions");
    }
    
    return (
      <ButtonToolbar className="btn-group-vertical">
        <DropdownButton
          size="sm"
          title={<IconMore />}
          id="tpControllerMoreActions"
          data-test-auto="271576c6-b729-4e50-b51a-480831e76dc4"
          className="tpControllerMoreActions"
        >
          <li
            data-test-auto="a08f98d1-e6eb-4522-a97f-dcae59bf187a"
            key="RevokeOffice365"
            onClick={() =>
              this.handleEmailTaxDocuments(groupedReturn, row.rowIndex)
            }
            tabIndex={0}
            onKeyDown={(e: any) => handleKeyDown(e, () => this.handleEmailTaxDocuments(groupedReturn, row.rowIndex))}
          >
            Email&nbsp;Documents
          </li>
        </DropdownButton>
      </ButtonToolbar>
    );
  }

  private getTaxReturnAction = (cell: any, row: any) => {
    const groupedReturns: IGroupedReturnTableModel[] =
      this.props.dashboardData.groupedReturnsState.groupedReturns;
    return (
      <>
        <ButtonComponent
          onClick={() => this.handleOpenTaxReturnClick(row.clientGuid)}
          variant={"link"}
          size={"medium"}
          buttonClassName={"tpGRActionsItem tpGROpenButton"}
        >
          Open
        </ButtonComponent>
        <span className="tpGRActionsSeparator">|</span>
        <ButtonComponent
          onClick={() => this.handleDownloadTaxDocuments(row.clientGuid)}
          disabled={
            parseInt(groupedReturns[row.rowIndex].signatureStatus) !==
              SignatureStatus.ESigned &&
            parseInt(groupedReturns[row.rowIndex].signatureStatus) !==
              SignatureStatus.ESignedInOffice &&
            parseInt(groupedReturns[row.rowIndex].signatureStatus) !==
              SignatureStatus.Uploaded &&
            groupedReturns[row.rowIndex].documentStatus !==
              DocumentStatus.USERSIGNED
          }
          variant={"link"}
          size={"medium"}
          buttonClassName={"tpGRActionsItem tpGRDownloadButton"}
        >
          Download
        </ButtonComponent>
        <span className="tpGRActionsSeparator">|</span>
        {this.createMoreActions(groupedReturns[row.rowIndex], row)}
      </>
    );
  };

  private getPaymentDueOptions = (
    dueDates: string[]
  ): { value: string; label: string }[] => {
    return dueDates.map((date, index) => {
      return { value: date, label: date };
    });
  };

  private getSignatureStatus = (signatureStatus: SignatureStatus): string => {
    let signStatus: string = "";
    switch (signatureStatus) {
      case SignatureStatus.ESigned:
        signStatus = "E Signed";
        break;
      case SignatureStatus.ESignedInOffice:
        signStatus = "E-Signed – In Office";
        break;
      case SignatureStatus.ManuallySigned:
        signStatus = "Manually Signed";
        break;
      case SignatureStatus.Uploaded:
        signStatus = "Uploaded";
        break;
      case SignatureStatus.AwaitingESign:
        signStatus = "Awaiting E-Sign";
        break;
      case SignatureStatus.AwaitingUpload:
        signStatus = "Awaiting Upload";
        break;
      case SignatureStatus.SignedAndESigned:
        signStatus = "Signed And E-Signed";
        break;
      case SignatureStatus.PartiallySigned:
        signStatus = "Partially Signed";
        break;
      case SignatureStatus.SignatureStampingFailed:
        signStatus = "Signature Stamping Failed";
    }
    return signStatus;
  };

  private getNextPaymentDueDateFilter = (
    filterHandler: any,
    customFilterParameters: any
  ) => {
    return (
      <CustomDateFilter
        filterHandler={filterHandler}
        onChange={() => {}}
        placeholderText={customFilterParameters.placeholder}
        calendarClassName="tpGRCalendar"
      />
    );
  };

  private getStatusMultiSelectDropDown = (
    filterHandler: any,
    customFilterParameters: any
  ) => {
    const options = customFilterParameters.options;
    const enableAllFilter = customFilterParameters.enableAllFilter;
    const placeholder = customFilterParameters.placeholder;
    return (
      <CustomMultiSelect
        ref={this.refSignatureStatus}
        filterHandler={filterHandler}
        options={options}
        enableAllFilter={enableAllFilter}
        placeholder={placeholder}
        classNamePrefix="signatureStatusTH"
        maxMenuHeight={100}
      />
    );
  };

  private getYearMultiSelectDropDown = (
    filterHandler: any,
    customFilterParameters: any
  ) => {
    const options = customFilterParameters.options;
    const enableAllFilter = customFilterParameters.enableAllFilter;
    const placeholder = customFilterParameters.placeholder;
    return (
      <CustomMultiSelect
        ref={this.refTaxYear}
        filterHandler={filterHandler}
        options={options}
        enableAllFilter={enableAllFilter}
        placeholder={placeholder}
        classNamePrefix="taxYearTH"
        maxMenuHeight={100}
      />
    );
  };

  private customSignatureStatusFormat = (cell: any, row: any) => {
    if (
      (row.delegateeGuid && row.signatureStatus == "Awaiting E-Sign") ||
      (row.delegateeGuid && row.signatureStatus == "Awaiting Upload")
    ) {
      return (
        <span
          style={
            SIGNATURE_STATUS_OPTIONS_STYLE[
              DelegateeSignerConstants.Info.DelegatedPreview
            ]
          }
        >
          {DelegateeSignerConstants.Info.DelegatedStatus}
        </span>
      );
    } else if (row.signatureStatus !== "Uploaded") {
      return (
        <span
          style={SIGNATURE_STATUS_OPTIONS_STYLE[row.signatureStatus]}
          onClick={() => {
            this.handleSignDetailPopup(row.clientGuid, row.rowIndex);
          }}
        >
          {row.signatureStatus}
        </span>
      );
    } else {
      return (
        <span style={SIGNATURE_STATUS_OPTIONS_STYLE[row.signatureStatus]}>
          {row.signatureStatus}
        </span>
      );
    }
  };

  handleSignDetailPopup = (clientId: string, rowIndex: number) => {
    this.setState({ selectedRow: rowIndex });
    this.props.getSignerData(clientId, this.updateSignerPopup);
  };

  updateSignerPopup = (data: any, clientTracking: any) => {
    var SignDetails: IDocumentSignerDetails[] = [];
    let signStatus: any =
      this.props.dashboardData.groupedReturnsState.groupedReturns[
        this.state.selectedRow
      ]?.signatureStatus?.split(",");
    if (data.length !== 0) {
      SignDetails = data.map((val: any) => {
        return {
          signer: val.signer !== null ? val.signer : "",
          signedStatus:
            val.isSigned == true
              ? signStatus[0] == [SignatureStatus.SignatureStampingFailed] &&
                (clientTracking?.find(
                  (x) => x.eventId == DocumentEvent.SigningFailed
                )?.eventData.clientType == val.signerType ||
                  clientTracking
                    ?.find((x) => x.eventId == DocumentEvent.SigningFailed)
                    ?.eventData.customData?.find(
                      (x) => x.key == "TaxClientGuid"
                    )?.value === val.clientGuid)
                ? "Stamping Service Failed"
                :  (clientTracking?.find(x => x.eventId == DocumentEvent.TaxpayerESignedInOffice)?.eventData.clientType == val.signerType ||
                clientTracking?.find(x => x.eventId == DocumentEvent.SpouseESignedInOffice)?.eventData.clientType == val.signerType ||
                clientTracking?.find(x => x.eventId == DocumentEvent.PartnershipESignedInOffice)?.eventData.clientType == val.signerType ||
                clientTracking?.find(x => x.eventId == DocumentEvent.ControllerESignedInOffice)?.eventData.customData?.find(
                  (x) => x.key == "TaxClientGuid"
                )?.value === val.clientGuid
                ? "E-Signed – In Office" :"Signed")
              : signStatus == SignatureStatus[SignatureStatus.AwaitingUpload] &&
                val.isKBAVerified == null &&
                val.kbaFailedCount > 0
              ? "Failed KBA - Awaiting document upload"
              : signStatus == SignatureStatus[SignatureStatus.AwaitingUpload] &&
                val.isKBAVerified == null &&
                val.kbaFailedCount == 0
              ? "opted for manual sign"
              : signStatus[0] == [SignatureStatus.SignatureStampingFailed] &&
                clientTracking?.find(
                  (x) => x.eventId == DocumentEvent.SigningFailed
                )?.eventData.clientType == val.signerType
              ? "Stamping Service Failed"
              : signStatus[0] == [SignatureStatus.SignatureStampingFailed] &&
                (clientTracking?.find(
                  (x) => x.eventId == DocumentEvent.SigningFailed
                )?.eventData.clientType == val.signerType ||
                  clientTracking
                    ?.find((x) => x.eventId == DocumentEvent.SigningFailed)
                    ?.eventData.customData?.find(
                      (x) => x.key == "TaxClientGuid"
                    )?.value === val.clientGuid)
              ? "Stamping Service Failed"
              : "Not Signed",
          signedOn: val.isSigned == true ? val.signedOn.split("T")[0] : "N/A",
        };
      });
    }
    this.setState({ showSignerModal: true, signModalData: SignDetails });
  };

  handleSignerClose = () => {
    this.setState({ showSignerModal: false, signModalData: [] });
  };

  getSignerStatusModal = () => {
    return (
      <>
        {this.state.showSignerModal && (
          <SuiteModal
            width="606"
            theme="light"
            title="Signing Details"
            onClickOutside={() => {}}
            onClickClose={this.handleSignerClose}
            className="tpGRSigningModal"
          >
            <>
              <Modal.Body>
                <div className="signModalDiv">
                  <div className="headerDiv">
                    <div className="emailHeaderDiv">Signer Email</div>
                    <div className="stateHeaderDiv">Signing State</div>
                    <div className="dateHeaderDiv">Signed Date</div>
                  </div>
                  {this.state.signModalData.map((val: any) => {
                    return (
                      <div className="valueDiv">
                        <div className="emailValueDiv" title={val.signer}>
                          {val.signer}
                        </div>
                        <div className="stateValueDiv">{val.signedStatus}</div>
                        <div className="dateValueDiv">{val.signedOn}</div>
                      </div>
                    );
                  })}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <ButtonComponent
                  onClick={this.handleSignerClose}
                  variant={"primary"}
                  size={"medium"}
                >
                  OK
                </ButtonComponent>
              </Modal.Footer>
            </>
          </SuiteModal>
        )}
      </>
    );
  };

  private K1DistributionList = {
    Pending: "Pending",
    "Partially distributed": "Partially distributed",
    Distributed: "Distributed",
  };

  private InvoiceStatusList = {
    1: "Unpaid",
    2: "Paid",
  };

  private columns: IColumn[] = [
    {
      header: "",
      key: "rowIndex",
      isKey: true,
      dataFormat: this.defaultType,
      columnClassName: "",
      dataSort: true,
      toolTip: false,
      isHidden: true,
      width: "auto",
    },
    {
      header: "Name",
      key: "name",
      ref: this.refTaxPayerName,
      isKey: false,
      dataFormat: this.nameColumnFormat,
      columnClassName: "nameClass",
      dataSort: true,
      toolTip: false,
      isHidden: false,
      width: "auto",
      filter: {
        type: "TextFilter",
        placeholder: " ",
      } as TextFilter,
    },
    {
      header: "Tax Year",
      key: "taxYear",
      ref: this.refTaxYear,
      isKey: false,
      dataFormat: this.defaultType,
      columnClassName: "taxYearClass",
      dataSort: true,
      toolTip: false,
      isHidden: false,
      width: "110px",
      filter: {
        type: "CustomFilter",
        getElement: this.getYearMultiSelectDropDown,
        customFilterParameters: {
          options: PrepareTaxYear(),
          enableAllFilter: true,
          placeholder: " ",
        } as any,
      } as CustomFilter,
    },
    {
      header: "Signature Status",
      key: "signatureStatus",
      ref: this.refSignatureStatus,
      isKey: false,
      dataFormat: this.customSignatureStatusFormat,
      columnClassName: "signatureStatusClass",
      dataSort: true,
      toolTip: false,
      isHidden: false,
      width: "auto",
      filter: {
        type: "CustomFilter",
        getElement: this.getStatusMultiSelectDropDown,
        customFilterParameters: {
          options: SIGNATURE_STATUS_OPTIONS_LIST,
          enableAllFilter: false,
          placeholder: " ",
        } as any,
      } as CustomFilter,
    },
    {
      header: "Next Payment Due",
      key: "nextPaymentDue",
      ref: this.refNextPaymentDue,
      isKey: false,
      dataFormat: this.nextPaymentDueFormat,
      columnClassName: "nextPaymentDueClass",
      dataSort: false,
      toolTip: false,
      isHidden: false,
      width: "auto",
      filter: {
        type: "CustomFilter",
        getElement: this.getNextPaymentDueDateFilter,
        customFilterParameters: {
          placeholder: " ",
        } as any,
      } as CustomFilter,
    },
    {
      header: "K-1 Distribution",
      key: "k1DistributionStatus",
      ref: this.refK1Status,
      isKey: false,
      dataFormat: this.k1DistributionStatusFormat,
      columnClassName: "k1DistributionStatusClass",
      dataSort: true,
      toolTip: false,
      isHidden: false,
      width: "auto",
      filter: {
        type: "SelectFilter",
        options: this.K1DistributionList,
        placeholder: " ",
      } as SelectFilter,
    },
    {
      header: "Invoice Status",
      key: "invoiceStatus",
      ref: this.refInvoiceStatus,
      isKey: false,
      dataFormat: this.invoiceStatusFormat,
      columnClassName: "invoiceStatusClass",
      dataSort: true,
      toolTip: false,
      isHidden: true, //TODO: set as true for further implementation
      width: "auto",
      filter: {
        type: "SelectFilter",
        options: this.InvoiceStatusList,
      } as SelectFilter,
    },
    {
      header: "Actions",
      key: "button",
      isKey: false,
      dataFormat: this.getTaxReturnAction,
      columnClassName: "taxReturnActionClass",
      dataSort: false,
      toolTip: false,
      isHidden: false,
      width: "auto",
    },
  ];

  private getVouchersDueDates = (formData: string): string[] => {
    let dueDates: string[] = [];
    let vouchers: IVoucher[] = [];
    (JSON.parse(formData) as IGroup[])
      .filter((group) => group.group === DocumentGroups.Vouchers)
      .forEach((group) => {
        vouchers = vouchers.concat(group.forms as IVoucher[]);
      });

    //show future due dates
    dueDates = vouchers
      .filter(
        (voucher) =>
          !voucher.isPaymentCompleted &&
          voucher.dueDate != undefined &&
          new Date(voucher.dueDate).getTime() >= new Date().getTime()
      )
      .map((voucher) =>
        new Date(voucher.dueDate ? voucher.dueDate : "").toLocaleDateString()
      );

    dueDates = dueDates.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    dueDates = dueDates.sort((a: string, b: string) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    return dueDates;
  };

  loadGroupedDocuments = () => {
    let param: any = this.props.match.params;
    this.props.requestGroupedDocuments(
      param.clientId,
      this.state.pageNo,
      pageSize,
      this.state.sortName,
      this.state.sortOrder,
      this.state.filterTaxPayerName,
      this.state.filterSignatureStatus,
      this.state.filterK1Status,
      this.state.filterNextPaymentDue,
      this.state.filterTaxYear,
      this.state.filterInvoiceStatus,
      this.state.searchText
    );
  };

  private onPageChange = (page: number, sizePerPage: number) => {
    this.setState(
      {
        pageNo: page,
      },
      () => this.loadGroupedDocuments()
    );
  };

  private onSortChange: any = (sortName: string, sortOrder: SortOrder) => {
    this.setState(
      {
        sortName: sortName,
        sortOrder: sortOrder,
      },
      () => this.loadGroupedDocuments()
    );
  };

  private onSearchChange = (searchString: string) => {
    let temp = searchString !== "" ? searchString : " ";
    this.setState(
      {
        searchText: temp,
        pageNo: 1,
      },
      () => this.loadGroupedDocuments()
    );
  };

  private renderShowsTotal = (start: number, to: number, total: number) => {
    return (
      <p>
        Showing {start} to {to} of {total} entries
      </p>
    );
  };

  closeEmailTaxDocumentModal = () => {
    this.setState({ emailTaxDocuments: { documentId: 0, showModal: false } });
  };

  private setNoContent() {
    if (this.props.dashboardData.groupedReturnsState.isLoading) {
      if (document.getElementById("overlay-loader")) {
        loader.show();
        return;
      }
      return <OverlayLoader />;
    } else {
      loader.hide();
      return "No returns found";
    }
  }

  private forwardDocuments = (
    clientId: string,
    recipients: ICCRecipientModel,
    callback: () => void
  ) => {
    const taxClientId =
      this.props.dashboardData.groupedReturnsState.groupedReturns[
        this.state.selectedRow
      ]?.clientGuid.split(",")[0];
    if (taxClientId)
      this.props.forwardDocumentsToRecipients(taxClientId, recipients, () => {
        callback();
      });
  };

  private onPageReload = () => {
    this.loadGroupedDocuments();
  };

  private onFilterChange = (dataField: FilterData<any>) => {
    let newState: DashboardPreviewState = {
      ...this.state,
      filterInvoiceStatus: "",
      filterK1Status: "",
      filterNextPaymentDue: "",
      filterSignatureStatus: "",
      filterTaxPayerName: "",
      filterTaxYear: "",
    };

    for (let field of Object.keys(dataField)) {
      let data = dataField[field].value
        ? dataField[field].value
        : dataField[field];
      switch (field) {
        case "name":
          newState.filterTaxPayerName = data;
          break;
        case "taxYear":
          newState.filterTaxYear = data == "-1" ? "" : data;
          break;
        case "signatureStatus":
          newState.filterSignatureStatus = data == "-1" ? "" : data;
          break;
        case "nextPaymentDue":
          newState.filterNextPaymentDue = data;
          break;
        case "k1DistributionStatus":
          newState.filterK1Status = data;
          break;
        case "invoiceStatus":
          newState.filterInvoiceStatus = data;
          break;
      }
    }
    this.setState({ ...newState, pageNo: 1 }, () => {
      this.loadGroupedDocuments();
    });
  };

  public clearFilter = () => {
    this.refTaxPayerName.current?.cleanFiltered();
    this.refTaxYear.current?.cleanFiltered();
    this.refInvoiceStatus.current?.cleanFiltered();
    this.refK1Status.current?.cleanFiltered();
    this.refNextPaymentDue.current?.cleanFiltered();
    this.refSignatureStatus.current?.cleanFiltered();

    this.setState({
      filterInvoiceStatus: "",
      filterK1Status: "",
      filterNextPaymentDue: "",
      filterSignatureStatus: "",
      filterTaxPayerName: "",
      filterTaxYear: "",
    });
  };

  sortRenderer = (direction: SortOrder | null, fieldName: string) => {
    if (fieldName === "button" || fieldName === "nextPaymentDue") return <></>;
    return (
      <i
        className={`fa fa-fw fa-sort${
          !direction ? "" : direction === "asc" ? "-asc" : "-desc"
        } sortIcon`}
      ></i>
    );
  };

  public render() {
    const data =
      this.props.dashboardData.groupedReturnsState.groupedReturns.length > 0
        ? this.props.dashboardData.groupedReturnsState.groupedReturns.map(
            (model, index) => {
              if (model.clientType === "1,2" || model.clientType === "2,1") {
                return getMFJTableData(model, index, this);
              } else {
                return {
                  name: model.name,
                  taxYear: model.taxYear,
                  signatureStatus: this.getSignatureStatus(
                    parseInt(model.signatureStatus)
                  ),
                  nextPaymentDue: this.state.paymentDueOptions
                    ? this.state.paymentDueOptions[index]
                    : [],
                  k1DistributionStatus: model.k1DisttributionStatus,
                  invoiceStatus: index % 2 === 0,
                  button: "Actions",
                  rowIndex: index,
                  disableActions: model.disableActions === "1" ? true : false,
                  delegateeGuid: model.delegateeGuid,
                  delegateeEmail: model.delegateeEmail,
                  clientGuid: model.clientGuid,
                  clientType: model.clientType,
                  isDocumentUpdated: model.isDocumentUpdated,
                };
              }
            }
          )
        : [];

    const options: Options<any> = {
      onSortChange: this.onSortChange,
      onPageChange: this.onPageChange,
      sizePerPage: pageSize,
      page: this.state.pageNo,
      paginationShowsTotal: this.renderShowsTotal,
      hideSizePerPage: true,
      onRowDoubleClick: (row, ev) => {
        if (
          !parseInt(
            this.props.dashboardData.groupedReturnsState.groupedReturns[
              row.rowIndex
            ].disableActions
          )
        ) {
          var clientGuid =
            this.props.dashboardData.groupedReturnsState.groupedReturns[
              row.rowIndex
            ].clientGuid.split(",")[0];
          this.handleOpenTaxReturnClick(clientGuid);
        }
      },
      noDataText: this.setNoContent(),
      onFilterChange: this.onFilterChange,
      defaultSortName: this.state.sortName,
      defaultSortOrder: this.state.sortOrder,
    };

    let totalCount =
      this.props &&
      this.props.dashboardData.groupedReturnsState.groupedReturns.length > 0
        ? this.props.dashboardData.groupedReturnsState.groupedReturns[0]
            .totalCount
        : 0;
    if (this.props.dashboardData.groupedReturnsState.isLoading) {
      loader.show();
    } else {
      loader.hide();
    }
    return (
      <div
        className="col-xs-12 col-sm-12 col-md-12 col-lg-12 content-wrapper-2 tpGRPagePadding steps-wrapper"
        style={{ overflow: "auto" }}
      >
        {this.getHeader()}
        {this.getSignerStatusModal()}
        <div className="row dashboatd-title tpGRHeaderDescButtons">
          <div className="col-md-6 tpGRHeaderDesc">
            Double click a tax return to open the taxpayer view
          </div>
          <div
            className="tpGRHeaderButtons"
            style={{ paddingRight: 30, textAlign: "right" }}
          >
            <ButtonComponent
              onClick={this.onPageReload}
              title="Refresh"
              disabled={this.state.refreshDelay}
              variant={"outline-secondary"}
              size={"medium"}
              buttonClassName={"tpGRHeaderButtonRefresh"}
            >
              <RefreshIcon />
            </ButtonComponent>
            <ButtonComponent
              onClick={this.clearFilter}
              variant={"outline-secondary"}
              size={"medium"}
              buttonClassName={"tpGRHeaderButtonClearFilters"}
            >
              <FilterIcon />
              <span className="pl-2">Clear Filters</span>
            </ButtonComponent>
          </div>
        </div>

        <div className="col-lg-12 tbl-controller-dashboard tpGRTable">
          <BootstrapTable
            ref="table"
            data={data}
            bordered={false}
            options={options}
            remote={true}
            pagination={totalCount > pageSize}
            hover={true}
            withoutTabIndex={true}
            fetchInfo={{
              dataTotalSize:
                this.props &&
                this.props.dashboardData.groupedReturnsState.groupedReturns
                  ?.length > 0
                  ? this.props.dashboardData.groupedReturnsState
                      .groupedReturns[0].totalCount
                  : 0,
            }}
            tableBodyClass="tbl-controller-dashboard-body tpGRTableBody"
            trClassName="tbl-controller-dashboard-row tpGRTableRow"
          >
            {this.columns.map((value, index) => {
              const header = value.header === "" && value.isHidden ? `Column ${index + 1}` : value.header;
              return (
                <TableHeaderColumn
                  key={index}
                  ref={value.ref}
                  isKey={value.isKey}
                  dataField={value.key}
                  hidden={value.isHidden}
                  width={value.width}
                  dataFormat={value.dataFormat}
                  columnClassName={value.columnClassName}
                  columnTitle={value.toolTip}
                  dataSort={value.dataSort}
                  caretRender={this.sortRenderer}
                  dataAlign={value.dataAlign}
                  filter={value.filter}
                  tdStyle={(
                    cell: any,
                    row: any,
                    rowIndex: number,
                    columnIndex: number
                  ) => {
                    return columnIndex === 1 ? { overflow: "initial" } : {};
                  }}
                >
                  {value.dataSort ? (
                    <span title={value.header} className="table-text-sort">
                      {header}
                    </span>
                  ) : (
                    <span>{header}</span>
                  )}
                </TableHeaderColumn>
              );
            })}
          </BootstrapTable>
        </div>
        <AddCCRecipientModal
          show={this.state.emailTaxDocuments.showModal}
          closeCCRecipientModal={this.closeEmailTaxDocumentModal}
          downloadableDocuments={
            this.props.dashboardData.ccRecipientDownloadableDocuments
          }
          taxdocumentId={this.state.emailTaxDocuments.documentId}
          forwardDocuments={this.forwardDocuments}
          match={this.props.match}
        />
      </div>
    );
  }
}
