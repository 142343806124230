import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CustomDatepicker.style.scss';

interface ICustomDatepicker {
	selectedDate: Date | null | undefined;
	setSelectedDate: (selectedDate: Date | null | undefined) => void;
	handleOnclick?: () => void;
	dateFormat?: string;
	customInput?: React.ReactNode;
	placeholderText?: string;
	disabled?: boolean;
	className?: string;
	id?: string;
}

const CustomDatepicker: React.FC<ICustomDatepicker> = React.forwardRef<DatePicker, ICustomDatepicker>((props, ref) => {
	
		const { selectedDate, setSelectedDate, handleOnclick, dateFormat, customInput, placeholderText, disabled, className, id } = props;

		return (
			<div onClick={handleOnclick}>
				<DatePicker
					{...props}
					ref={ref}
					id={id}
					selected={selectedDate}
					onChange={(date) => setSelectedDate(date)}
					placeholderText={placeholderText ?? 'Select...'}
					customInput={customInput}
					dateFormat={dateFormat ?? 'MM/dd/yyyy'}
					showMonthDropdown={true}
					showYearDropdown={true}
					dropdownMode='select'
					maxDate={new Date()}
					disabled={disabled}
					className={`${className} ${disabled ? 'disabled_button' : ''}`}
				/>
			</div>
		);
	},
);

export default CustomDatepicker;
