import { Role } from '../../common/Enums'
import { SignProcessSteps } from '../../../components/Home/TaxpayerSignFlowManager';
import { ISignProcessInfoViewModel, initialSignProcessInfoViewModel } from '../viewModels/ISignProcessInfoViewModel';

export interface IClientProcessViewModel {
    role: Role;
    currentstep: ISignProcessInfoViewModel;
    permission: string,
    isSigned: boolean;
    isDelegated: boolean;
    isInOfficeESign: boolean;
}

export const initialClientProcessModel: IClientProcessViewModel = {
    role: Role.None,
    currentstep: initialSignProcessInfoViewModel,
    permission: "",
    isSigned: false,
    isDelegated: false,
    isInOfficeESign: false
}