
import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { SignProcessSteps } from '../../components/Home/TaxpayerSignFlowManager';
import { IClientProcessViewModel, initialClientProcessModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { AuthToasterError } from '../../components/Common/Constants';
import { OTPPageConstants } from '../../components/Common/Constants';
import { OTPMobilePageConstants,DisplayError } from '../../components/Common/Constants';


export interface IOTPPage {
	loading: boolean,
	error: any,
	mobileNo: string
	countryCode: string
}

const OTPPage: IOTPPage = {
	loading: false,
	error: null,
	mobileNo: "",
	countryCode: ""


}




interface RequestSignProcessAction {
	type: actionTypes.SIGN_PROCESS_REQUEST;
}
interface RequestCurrentStepAction {
	type: actionTypes.SIGN_PROCESS_STEP;
	data: IClientProcessViewModel;
}


interface ResponseSignProcessAction {
	type: actionTypes.SIGN_PROCESS_RESPONSE;
	data: number;
}

interface FailureSignProcessAction {
	type: actionTypes.SIGN_PROCESS_FAILURE;
	data: number;
}

interface UpdateLastVisitedStep {
	type: actionTypes.UPDATE_LAST_VISITED_PROCESS_STEP;
	data: SignProcessSteps;
}

export interface SignProcessState {
	data: number;
	clientprocessmodel: IClientProcessViewModel;
}

export const initialSignProcessState: SignProcessState = {
	data: 0,
	clientprocessmodel: initialClientProcessModel
}



type KnownAction =
	DispatchAction |
	NotificationAction;

type DispatchAction =
	ResponseSignProcessAction
	| RequestSignProcessAction
	| FailureSignProcessAction
	| RequestCurrentStepAction
	| UpdateLastVisitedStep



interface IClientResponse {
	IsSuccess: boolean,
	ErrorCode: string,
	ErrorDescription: string,
	Data: any
}






export const actionCreators = {
	pageValidityGroup: (clientId: string, handleError: (result?: any)=>void): AppThunkAction<any> => (dispatch, getState) => {
		dispatch({ type: actionTypes.SET_GROUP_OTP_LOADING });
		return initializeAxios().get<any>("api/GroupedReturns/OTP/" + clientId)
			.then(function (response: AxiosResponse<any>) {

				let result = response.data;
				if (result.isSuccess) {
					dispatch({ type: actionTypes.SET_VALID_GROUP_OTP_PAGE });

				}
				else {
					let errorDesc = result.errorDescription;
					let errorCode = result.errorCode;

					if (errorCode && DisplayError.includes(errorCode)) {

						dispatch({
							type: actionTypes.SET_ERROR_MSG,
							data: errorDesc
						});
					}
					else {					
						dispatch({ type: actionTypes.SET_INVALID_GROUP_OTP_PAGE });
                    }
					if(errorCode && AuthToasterError.includes(errorCode)){
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: errorDesc,
							statusType: StatusType.Error
						});
					}
					else {
						handleError(result);
					}
				}
			})
			.catch((e: any) => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: OTPPageConstants.ErrorMessage.serverError,
					statusType: StatusType.Error
				})
			
			}
			);

	},
	generateMobileOTPGroup: (clientId: string): AppThunkAction<any> => (dispatch, getState) => {
		return initializeAxios().postJson<any>(null, 'api/GroupedReturns/OTP/Mobile/Generate/' + clientId)
			.then(function (response: any) {
				let result = response.data;
				if (result.isSuccess) {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPMobilePageConstants.SuccessMessage.OTPGenerateSuccess,
						statusType: StatusType.Success
					})
				}
				else {

					let errorDesc = result.errorDescription;
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: errorDesc,
						statusType: StatusType.Success
					})
				}
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: OTPMobilePageConstants.ErrorMessage.serverError,
					statusType: StatusType.Error
				})


			});


	}
	, generateOTPGroup: (clientId: string): AppThunkAction<any> => (dispatch, getState) => {
		return initializeAxios().postJson<any>(null, 'api/GroupedReturns/OTP/Generate/' + clientId)
			.then(function (response: any) {
				if (response.data === false) {
					
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPPageConstants.ErrorMessage.OTPGenerateFailed,
						statusType: StatusType.Error
					})
				}
				else {
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: OTPPageConstants.SuccessMessage.OTPGenerateSuccess,
						statusType: StatusType.Success
					})
				}

			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: OTPPageConstants.ErrorMessage.serverError,
					statusType: StatusType.Error
				})

			});


	},
	verifyOTPGroup: (OTP: string, clientId: string, handleVerify?: (guid: string) => void, handleError?: (result?: any) => void): AppThunkAction<any> => (dispatch, getState) => {
		return initializeAxios().postJson<any>('"' + OTP + '"', `api/GroupedReturns/OTP/Validate/${clientId}`)
			.then(function (response: any) {
				let result = response.data;

				if (result.isSuccess) {

					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: "verify set",
						statusType: StatusType.Success
					})

					if (handleVerify !== undefined) {
						handleVerify(result.data);
                    }

				}
				else {
					let errorDesc = result.errorDescription;
					let errorCode = result.errorCode;

					if (errorCode && DisplayError.includes(errorCode)) {
						dispatch({
							type: actionTypes.SET_ERROR_MSG,
							data: errorDesc
						});

					}
					if(errorCode && AuthToasterError.includes(errorCode)){
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: errorDesc,
							statusType: StatusType.Error
						});
					}
					else {
						handleError && handleError(result);					
					}
				}
			})
			.catch(function (error: any) {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: OTPPageConstants.ErrorMessage.serverError,
					statusType: StatusType.Error
				})
			
			});

	},
	setMobileNoGroup: (mobileNo: string, countryCode: string): AppThunkAction<any> => (dispatch, getState) => {
		return dispatch({ type: actionTypes.SET_MOBILE_GROUP_VAL, data: { mobileNo, countryCode } })
	}
}

export const reducer: Reducer<IOTPPage> = (state: IOTPPage = OTPPage, incomingAction: Action) => {
	const action = incomingAction as any;
	const currentState = Object.assign({}, state);
	console.log("in reducers", action);
	switch (action.type) {
		case actionTypes.SET_GROUP_OTP_LOADING:
			return { ...currentState, loading: true };
		case actionTypes.SET_INVALID_GROUP_OTP_PAGE:
			//set a error message stating this is a invalid page
			return { ...currentState, loading: true };
		case actionTypes.SET_VALID_GROUP_OTP_PAGE:
			return { ...currentState, loading: false };
		case actionTypes.SET_GROUP_OTP_ERROR:
			//set the exception error for not getting data.
			let errorMsg = action.error;
			return { ...currentState, loading: false, error: errorMsg }
		case actionTypes.SET_MOBILE_GROUP_VAL:
			return { ...currentState, mobileNo: action.data.mobileNo, countryCode: action.data.countryCode };

     	default:
			return currentState || initialSignProcessState;
	}
}; 