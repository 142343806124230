import * as React from 'react';
import { BootstrapTable, TableHeaderColumn, SortOrder } from 'react-bootstrap-table';
import { IK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { ConsentStatus, EngagementTypeS } from '../../core/common/Enums';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { GetK1EngagementType } from '../../core/domain/models/IK1Distribute';
import { match } from 'react-router';
import { GetFormatedDateTime, handleKeyDown } from '../Helper/HelperFunction';
import { phoneNumberDisplay, countryCodeDisplay } from '../Common/Validations';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { ILoader } from '../../core/utilities/ui/Loader';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { OverlayLoader } from '../Common/Loader/OverlayLoader';
import { SvgDownloadIcon, SvgEditIcon, SvgResendIcon, SvgDividerIcon, SortInitial, SortAscending, SortDescending } from '../Common/Icons/SvgIcons';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const loader = container.get<ILoader>(TYPES.ILoader);

interface DistributeElectronicTableProps {
    k1Data: IK1ShareHolderDetails[];
    onSortChange: any;
    onPageChange: any;
    pageNo: number;
    pageSize: number;
    onSearchChange: (searchString: string) => void;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedK1Partners: IK1ShareHolderDetails[]
    taxReturn: ITaxReturn;
    match: match;
    editPartnerDetails: (rowIndex: number) => void;
    downloadIndividualK1DocumentAsync: (
        clientId: string,
        shareHolder: IK1ShareHolderDetails,
        engagementTpe: EngagementTypeS
    ) => void;
    onResendMail: (shareHolderId: number) => void;
    isK1Loading: boolean;
    onBulkSelectionChange: (isBulkSelection: boolean) => void;
    showBulkSelectionMessage: boolean;
}

export class DistributeElectronicTable extends React.Component<DistributeElectronicTableProps, {}>{
    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
        const thElements = Array.from(document.querySelectorAll('th'));
        const thElementsWithInput = thElements.filter((th: any) => {
            return th.querySelector('input.react-bs-select-all') !== null;
        });
        if (thElementsWithInput?.length > 0) {
            thElementsWithInput.forEach((th: any) => {
                th.setAttribute('aria-label', 'Select All');
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<DistributeElectronicTableProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.k1Data?.length !== this.props.k1Data?.length) {
            const checkboxElements = document.querySelectorAll('.distributeK1Tbl input[type="checkbox"]');
            if (checkboxElements?.length > 0) {
                checkboxElements.forEach((element: any) => {
                    element.setAttribute('aria-label', 'Select Row');
                });
            }
        }
    }

    handleK1Download = (shareHolderId: number) => {
        let shareHolder = this.props.k1Data.filter(x => x.shareHolderId === shareHolderId);
        if (shareHolder) {
            let param: any = this.props.match.params;
            this.props.downloadIndividualK1DocumentAsync(param.clientId, shareHolder[0], this.props.taxReturn.engagementType);
        }
    }

    private showBulkAlertMessage() {
        let totalCount = this.props && this.props.k1Data.length > 0 ? this.props.k1Data[0].count : 0
		if (totalCount > this.props.pageSize && this.props.showBulkSelectionMessage)
			return (<div className="col-sm-6 col-lg-8" style={{ display: "inline-block", zIndex: 10, padding: '0px 0px' }} >
				<Alert variant="warning" style={{ padding: "6px", width: "fit-content", marginBottom: '0px' }}>
					<span
						className="fa fa-exclamation-triangle"
						style={{ marginRight: '5px', paddingLeft: "5px" }}>
					</span> All {this.props.pageSize} records on this page are selected. To select the remaining {totalCount - this.props.pageSize} filtered records,
					<Link to={"#"} className='ml-1' style={{textDecoration: 'underline'}} onClick={() => this.props.onBulkSelectionChange(true)}>Click Here</Link>
				</Alert>
			</div>);
	}

    private k1StatusAction = (cell: any, row: any) => {
        return (
            <div>
                <span
                    className="k1-partner-button"
                    data-test-auto="A853852E-ED23-4372-A5F9-D0A4CDB0812B"
                    title={"Edit Details"}
                    onClick={() => { this.props.editPartnerDetails(row.rowIndex) }}
                    tabIndex={0}
                    onKeyDown={(e: any) => handleKeyDown(e, () => { this.props.editPartnerDetails(row.rowIndex) })}
                >
                    <SvgEditIcon />
                    Edit
                </span>
                <span className="margin-right-8">
                    <SvgDividerIcon />
                </span>
                <span
                    className="k1-partner-button"
                    data-test-auto="C73C9574-8EED-47B1-A492-AEA62F71B6E4"
                    title={"Download"}
                    onClick={() => { this.handleK1Download(row.shareHolderId) }}
                    tabIndex={0}
                    onKeyDown={(e: any) => handleKeyDown(e, () => this.handleK1Download(row.shareHolderId))}
                >
                    <SvgDownloadIcon width={16} height={16} />
                    Download
                </span>
               {this.props.k1Data && this.props.k1Data[row.rowIndex].k1SendDate != null ?
                    <span>
                        <span className="margin-right-8">
                                <SvgDividerIcon />
                        </span>
                    <span 
                        className="k1-partner-button"
                        data-test-auto="D67456A1-CC27-4D75-9CB5-EDF39F302B93"
                        title={"Resend"}
                        onClick={() => { this.props.onResendMail(row.shareHolderId) }}
                        tabIndex={0}
                        onKeyDown={(e: any) => handleKeyDown(e, () => this.props.onResendMail(row.shareHolderId))}
                    >
                        <SvgResendIcon />
                        Resend
                        </span>
                    </span>
                        : ""                   
                }
            </div>
        );
    }

    private defaultType = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis" data-test-auto="BB075771-FC0D-479D-8F19-CF5AFD3F57CA">{cell}</span>;
    }

    private customStatusFormat = (cell: any, row: any) => {
        let className: string = 'k1-status ellipsis';
        if ((cell as string).startsWith('Not Sent')) {
            className = 'k1-decline-status ellipsis';
        }
        else if ((cell as string).startsWith('Declined')) {
            let content = cell.split('~');
            return <div title={content[0] + '\n' + content[1]}>
                <span className="ellipsis k1-decline-status" data-test-auto="FEF2FAAB-A29C-45C4-8CAA-81E0A0CD9A36"> {content[0]}</span>
                <br />
                <span className="ellipsis k1-status" data-test-auto="D88C9C9B-60CF-4FFB-9C27-5B8D880ACB8F">{content[1]}</span>
            </div>
        }
        return <span title={cell} className={className} data-test-auto="5C9FD01D-E0A1-4E42-B104-8CBD5B8BEEDB">{cell}</span>;
    }

    private customHeaderName = () => {
        return this.props.taxReturn && GetK1EngagementType(this.props.taxReturn.engagementType);
    }

    private renderShowsTotal = (start: number, to: number, total: number) => {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    private setNoContent() {
        if (this.props.isK1Loading) {
            if (document.getElementById("overlay-loader")) {
                loader.show();
                return;
            }
            return (<OverlayLoader />);
        } else {
            loader.hide();
            return "No records found";
        }
    }

    sortRenderer = (direction: SortOrder | null, fieldName: string) => {
        if (fieldName === 'button') {
            return <></>
        }
        if (fieldName === 'name' || fieldName === 'email') {
            if (!direction) {
                return <SortInitial />
            }
            if (direction === 'asc') {
                return <SortAscending />
            }
            else {
                return <SortDescending />
            }
        }
    }

    private columns = [
        {
            header: '',
            key: 'rowIndex',
            isKey: true,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: true,
            width: 'auto',

        },
        {
            header: this.customHeaderName(),
            key: 'name',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: 'Email Address',
            key: 'email',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: 'Mobile',
            key: 'mobileNumber',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: 'Status',
            key: 'status',
            isKey: false,
            dataFormat: this.customStatusFormat,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: 'Actions',
            key: 'button',
            isKey: false,
            dataFormat: this.k1StatusAction,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: '300px',
        },
    ];

    public render() {

        const options = {
            onSortChange: this.props.onSortChange,
            onSearchChange: this.props.onSearchChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            clearSearch: false,
            hideSizePerPage: true,
            noDataText: this.setNoContent(),
        };

        const data = this.props.k1Data && this.props.k1Data.map((model, index) => {
            const k1Status = model.k1DownloadDate ? 'Downloaded on ' + GetFormatedDateTime(model.k1DownloadDate) :
                model.consentStatus === ConsentStatus.Declined ? 'Declined Consent ~' + 'Sent on ' + GetFormatedDateTime(model.k1SendDate) :
                    model.k1SendDate ? 'Sent on ' + GetFormatedDateTime(model.k1SendDate) : 'Not Sent';
            return {
                name: model.name,
                email: model.email,
                status: k1Status,
                mobileNumber: model.mobileNumber && model.countryCode ? model.countryCode + ' ' + phoneNumberDisplay(model.mobileNumber) : model.mobileNumber,
                shareHolderId: model.shareHolderId,
                rowIndex: index,
                button: 'Actions',
            }
        });

        let selected: number[] = [];
        if (this.props.selectedK1Partners.length > 0) {
            this.props.selectedK1Partners.forEach(k1Partner => {
                let rowIndex = data.findIndex(row => row.shareHolderId == k1Partner.shareHolderId);
                if (rowIndex != -1)
                    selected.push(rowIndex);
            });
        }

        let selectRowProp: any =
        {
            mode: 'checkbox',
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            className: 'row-selected',
            columnWidth: '5%'
        };
        let totalCount = this.props && this.props.k1Data.length > 0 ? this.props.k1Data[0].count : 0 
        return (
            <>
            {this.showBulkAlertMessage()}
            <div className="distributeK1Tbl">
                <BootstrapTable
                    ref='table'
                    data={data}
                    remote={true}
                    pagination={totalCount > this.props.pageSize}
                    withoutTabIndex={true}
                    options={options}
                    selectRow={selectRowProp} 
                    bordered={false}
                    fetchInfo={{ dataTotalSize: this.props && this.props.k1Data.length > 0 ? this.props.k1Data[0].count : 0 }}
                >
                    {this.columns.map((value, index) => {
                        const header = value.header === "" && value.isHidden ? `Column ${index + 1}` : value.header;
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            dataSort={value.dataSort}
                            caretRender={this.sortRenderer}
                        >
                            {
                                (value.dataSort) ?
                                <span title={value.header} className="table-text-sort">{header}</span> :
                                <span>{header}</span>
                            }
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div >
            </>
        )
    }
}