import * as React from 'react';
import { IDocumentReviewModel } from '../../../core/domain/viewModels/IDocumentReviewModel';
import { IAttachmentDownloadViewModel, initialAttachmentDownloadModel } from '../../../core/domain/viewModels/IAttachmentDownloadViewModel';
import { Popover } from 'react-bootstrap';
import { truncateString } from '../../../../src/components/Helper/HelperFunction';
import { match } from "react-router";
var htmlencode = require('htmlencode')
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { TYPES } from '../../../startup/types';
import { IFileUtilities } from '../../../core/utilities/File/FileUtilities';
import { MarsNotifier } from '../../../../src/components/Common/Notification/MarsNotifier';
import { DownloadDocumentsConstants } from '../../Common/Constants';
import { container } from '../../../startup/inversify.config';
import { AttachmentsInstruction } from './AttachmentsInstruction';
import MessageNote from '../../Common/Notification/MessageNote';
import { SvgDownloadButtonIcon, SvgAttachmentIcon, SvgNotesIcon } from '../../Common/Icons/SvgIcons';
import { AttachmentConstants } from '../../Common/Constants';
import { NotificationType } from "../../../core/common/Enums";
import { ButtonComponent } from 'cp-common-ui-components';

interface IAttachmentViewProps {
    attachmentReviewModel: IDocumentReviewModel[];
    downloadAttachment: (clientId: string, attachment: IAttachmentDownloadViewModel) => void;
    downloadAllAttachments: (clientId: string, attachments: IAttachmentDownloadViewModel[]) => void;
    match: match;
    isPreviewMode: boolean;
}

interface AttachmentsInstructionState {
    showAttachmentInstructionModal: boolean;
    attachmentInstruction: string;
    attachmentFileName: string;
}

const fileUtilities = container.get<IFileUtilities>(TYPES.IFileUtilities);
export class AttachmentsView extends React.Component<IAttachmentViewProps, AttachmentsInstructionState> {
    private param: any = this.props.match.params;
    constructor(props: IAttachmentViewProps) {
        super(props);
        this.state = {
            showAttachmentInstructionModal: false,
            attachmentInstruction: "",
            attachmentFileName: ""
        }
        this.downloadAttachment = this.downloadAttachment.bind(this);
        this.downloadAttachmentsAsZip = this.downloadAttachmentsAsZip.bind(this);
    }

    removeHtmlTags = (htmlStr: string) => {

        if ((htmlStr === null) || (htmlStr === '')) {
            return "";
        }
        else {
            htmlStr = htmlencode.htmlDecode(htmlStr);
            htmlStr = htmlStr.replace(/(<([^>]+)>)/ig, '').replace(new RegExp('&nbsp;', 'g'), '');
            return htmlStr;
        }
    }

    downloadAttachment(attachment: IDocumentReviewModel) {
        if (!this.props.isPreviewMode) {
            let downloadAttachment: IAttachmentDownloadViewModel = initialAttachmentDownloadModel;
            downloadAttachment.attachmentName = attachment.fileName;
            downloadAttachment.documentGuid = attachment.documentGuid;
            downloadAttachment.taxYear = attachment.taxYear;
            this.props.downloadAttachment(this.param.clientId, downloadAttachment);
        }
        else {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        }
    }

    downloadAttachmentsAsZip() {
        if (!this.props.isPreviewMode) {
            let attachmentsToDownload: IAttachmentDownloadViewModel[] = [];

            this.props.attachmentReviewModel.forEach((attachment, i) => {
                let attachmentDownloadData: IAttachmentDownloadViewModel = { ...initialAttachmentDownloadModel };

                attachmentDownloadData.attachmentName = attachment.fileName;
                attachmentDownloadData.documentGuid = attachment.documentGuid;
                attachmentDownloadData.taxYear = attachment.taxYear;
                attachmentsToDownload.push(attachmentDownloadData)
            });
            this.props.downloadAllAttachments(this.param.clientId, attachmentsToDownload);
        }
        else {
            MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
        }
    }

    closeAttachmentsInstructionModal = () => {
        this.setState({ showAttachmentInstructionModal: false, attachmentInstruction: "", attachmentFileName: "" });
    }

    showAttachmentsInstructionModal = (instruction: string, fileName: string) => {
        this.setState({ showAttachmentInstructionModal: true, attachmentInstruction: instruction, attachmentFileName: fileName });
    }

    attachmentInstruction = (instruction: string) => {
        return (
            <Popover id="contactInfoPopover" placement="right"
                data-test-auto="78524138-E070-46C3-AEA0-8E54F038401B">
                <h3 className="popover-header">Instructions</h3>
                <div className="popover-body">
                    <div dangerouslySetInnerHTML={{ __html: htmlencode.htmlDecode(instruction) }}></div>
                </div>
            </Popover>
        );
    }
    private fileNameCell = (cell: any, row: IDocumentReviewModel) => {
        return (<span 
            title={cell}
            className="attachment-ellipses" 
            data-test-auto="3765DE41-3624-4D54-8F38-A69C6F1DB1D2">
            <SvgAttachmentIcon />
            {fileUtilities.getSafeFilename(truncateString(cell, 55, 55))}
        </span>)
    } 

    private instructionCell = (cell: any, row: any) => {
        return (
            <ButtonComponent
                data-test-auto="2ef9ca8e-4888-4ffc-87a7-107d303c67a5"
                onClick={() => this.showAttachmentsInstructionModal(cell, fileUtilities.getSafeFilename(truncateString(row.fileName, 55, 55)))}
                title="View Instructions"
                variant={"link"}
                size={"medium"}
                buttonClassName={"attachment-file-viewer"}
            >
                <SvgNotesIcon />
                <span>Notes</span> 
            </ButtonComponent>
        )
    }

    private downloadAttachmentCell = (cell: any, row: IDocumentReviewModel) => {
        return (
            <ButtonComponent
                data-test-auto={"f1376f1c-f9b7-40c9-9153-94c84f4dc52c"}
                onClick={() => this.downloadAttachment(row)}
                title='Download Attachment'
                variant={"link"}
                size={"medium"}
                buttonClassName={"attachment-file-viewer"}
            >
                <SvgDownloadButtonIcon />
                <span>Download</span>
            </ButtonComponent>
        ) 
    }

    private columns = [
        {
            header: '',
            key: 'rowIndex',
            isKey: true,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: true,
            width: 'auto',

        },
        {
            header: "File Name",
            key: 'fileName',
            isKey: false,
            dataFormat: this.fileNameCell,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: '65%',
        },
        {
            header: "Instructions",
            key: 'attachmentInstruction',
            isKey: false,
            dataFormat: this.instructionCell,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: "Actions",
            key: 'fileName',
            isKey: false,
            dataFormat: this.downloadAttachmentCell,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
    ];

    public render() {

        const data = this.props.attachmentReviewModel && this.props.attachmentReviewModel.map((model, index) => {
            return {
                attachmentInstruction: model.attachmentInstruction,
                fileName: model.fileName,
                documentGroup: model.documentGroup,
                documentGuid: model.documentGuid,
                documentId: model.documentId,
                fileLink: model.fileLink,
                taxYear: model.taxYear,
                rowIndex: index,

            }
        });

        return (<div data-test-auto="4D84A694-E49A-45D7-9C41-95B6AB75EC24" >

            <div className="attachment-tab-container">
                <MessageNote note={AttachmentConstants.Note}
                    noteType={NotificationType.Info}
                />
                <div style={{ width : '100%' }} data-test-auto="77357D6E-0A73-4063-8FCC-A28DA25A0F5E" className="margin-top-10">
                    <BootstrapTable
                        ref='table'
                        data={data}
                        remote={false}
                        bordered={false}
                        withoutTabIndex={true}
                    >
                        {this.columns.map((value, index) => {
                            const header = value.header === "" && value.isHidden ? `Column ${index + 1}` : value.header;
                            return <TableHeaderColumn
                                key={index}
                                ref={value.key}
                                isKey={value.isKey}
                                dataField={value.key}
                                hidden={value.isHidden}
                                width={value.width}
                                dataFormat={value.dataFormat}
                                columnClassName={value.columnClassName}
                                columnTitle={value.toolTip}
                                dataSort={value.dataSort}
                            >
                                {header}
                            </TableHeaderColumn>;
                        })}
                    </BootstrapTable>
                </div>
                <div style={{ marginTop:'30px' }}>
                    <ButtonComponent
                        data-test-auto={"77357d6e-0a73-4063-8fcc-a28da25a0f5e"}
                        onClick={this.downloadAttachmentsAsZip}
                        buttonClassName={"download-all-button margin-left-18"}
                        title='Download all Attachments'
                        variant={"outline-primary"}
                        size={"medium"}
                    >
                        <SvgDownloadButtonIcon />
                        <span className='pl-2'>Download All </span>
                    </ButtonComponent>
                </div> 
            </div>
            <AttachmentsInstruction
                show={this.state.showAttachmentInstructionModal}
                fileName={this.state.attachmentFileName}
                attachmentInstruction={this.state.attachmentInstruction}
                closeAttachmentsInstructionModal={this.closeAttachmentsInstructionModal}>
            </AttachmentsInstruction>
        </div>);
    }
}