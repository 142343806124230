import * as React from 'react';
import { match } from 'react-router';
import { DocumentSignatureDataViewModel } from '../../../core/domain/viewModels/IDocumentSignatureDataViewModel';
import { PdfView } from './PdfView';
import { IDocument } from '../../../core/domain/models/esign/Document';
import { EsignManager, IEsignValidationModel } from './EsignBase';
import { MarsNotifier } from '../../Common/Notification/MarsNotifier';
import { Main } from '../Main/Main';
import { History } from 'history';
//import LoadingOverlay from 'react-loading-overlay'
import TaxpayerHelper from '../../Helper/TaxpayerHelper';
import { ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import { EngagementTypeS } from '../../../core/common/Enums';
import { isIndividualEngagementType } from '../../Helper/HelperFunction';
import { IMarriedJointTaxReturn } from '../../../core/domain/models/ITaxReturn';
import { ISignerModel } from '../../../core/domain/models/ISignerModel';
import { logger } from '../../../routes';
import { resizeEventHandler } from "../../Helper/HelperFunction";

export const NO_INDEX = -1;

interface EsignState {
	documentControlsAndData: DocumentSignatureDataViewModel[],
	showTimeout: boolean,
	finishEnabled: boolean,
	showSubmitLoader: boolean,
}

export interface ESignProps {
	requestSignatureControls: (clientGuid: string) => void;
	requestSignBehalfSpouseSignatureControls: (clientGuid: string) => void;
	match: match;
	history: History;
	signatureControlsData: IDocument[];
	onSigningComplete: (signData: IDocument[]) => any;
	disableNextButton: (value: boolean) => void;
	completeSigningStep: () => void;
	isSpouseSignRequired: boolean;
	sign?: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void) => void;
	signOnBehalfSpouse?: (clientId: string, documentData: IDocument[], taxpayerGuid: string, spouseGuid: string, callback: (status: boolean) => void) => void;
	onHeaderAndFooterVisibility: (visibility: boolean) => void;
	isPreviewMode: boolean;
	headerInfo: IHeaderInfoViewModel;
	engagementType: EngagementTypeS;
	signBehalfSpouse: boolean;
	mutual: IMarriedJointTaxReturn;
	signerData: ISignerModel[];
	updateChangedSignBehalfSpouseState: () => void;
	changedSignBehalfSpouse: boolean;
}


export class Esign extends React.Component<ESignProps, EsignState> {

	private _pdfView: any;

	constructor(props: any, states: EsignState) {
		super(props, states);
		this.state = {
			documentControlsAndData: [],
			showTimeout: false,
			finishEnabled: false,
			showSubmitLoader: false
		}
		this.props.disableNextButton(!this.props.isPreviewMode);
	}




	componentDidMount() {
		window.addEventListener('resize', resizeEventHandler);

		const param: any = this.props.match.params;
		if (this.props.signatureControlsData.length == 0 || this.props.changedSignBehalfSpouse) {
			this.props.signBehalfSpouse
				? this.props.requestSignBehalfSpouseSignatureControls(param.clientId)
				: this.props.requestSignatureControls(param.clientId);
			this.props.changedSignBehalfSpouse && this.props.updateChangedSignBehalfSpouseState();
			logger.trackTrace('Esign Page View', { "ClientId": param.clientId, "DocumentId": this.props.signatureControlsData[0]?.id, "PreviewMode": this.props.isPreviewMode });
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', resizeEventHandler);
    }

	static getDerivedStateFromProps(nextProps: ESignProps, nextState: EsignState) {
		if (nextProps.signatureControlsData &&
			nextProps.signatureControlsData.length !== nextState.documentControlsAndData.length) {
			return { documentControlsAndData: nextProps.signatureControlsData }
		}
		else {
			return { documentControlsAndData: nextState.documentControlsAndData };
		}
	}

	handleDocumentSignFinish = (documentControlData: DocumentSignatureDataViewModel) => {
		let documentDataList: DocumentSignatureDataViewModel[] = [...this.state.documentControlsAndData];

		const index = documentDataList.findIndex(x => x.id === documentControlData.id);
		if (index == NO_INDEX) {
			documentDataList.push(documentControlData);
		}
		else {
			documentDataList[index] = documentControlData;
		}
		this.setState({ documentControlsAndData: documentDataList });
	}

	handleSigningCompleted = () => {

		const documentData: IDocument[] = this._pdfView.getDocumentsData();
		const validation: IEsignValidationModel = EsignManager.validateEsignData(documentData);

		if (validation.status) {
			this.props.onSigningComplete(documentData);
			this.props.disableNextButton(false);

		}
		else {
			this._pdfView.showValidationMessage(validation.document, validation.page, validation.control);
		}
	}

	handleNextButton = (isDisable = false) => {
		this.props.disableNextButton(isDisable);
	}

	onNext(index: number) {
		let param: any = this.props.match.params;
		logger.trackTrace("Esign page continue button clicked", { "ClientId": param.clientId, "DocumentId": this.props.signatureControlsData[0]?.id, "PreviewMode": this.props.isPreviewMode });

		const documentData: IDocument[] = this._pdfView.getDocumentsData();
		const validation: IEsignValidationModel = EsignManager.validateEsignData(documentData);

		if (!validation.status) {
			this._pdfView.showValidationMessage(validation.document, validation.page, validation.control);
		}
		else {
			logger.trackTrace(`isSpouseSignRequired : ${this.props.isSpouseSignRequired}`, { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
			if (this.props.isSpouseSignRequired) {
				this.props.completeSigningStep();
			}
			else {
				this.setState({ showSubmitLoader: true }, () => {
					this.props.disableNextButton(true);
					let param: any = this.props.match.params;
					let documentId = this.props.signatureControlsData[0]?.id;
					logger.trackTrace(`IsPreviewMode : ${this.props.isPreviewMode}`, { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
					if (this.props.isPreviewMode) {
						this.props.completeSigningStep();
					}
					else {
						if (param.controllerId && TaxpayerHelper.bothSignerDeceased(this.props.mutual, this.props.signerData)) {
							this.props.signOnBehalfSpouse && this.props.signOnBehalfSpouse(param.clientId, this.props.signatureControlsData, this.props.mutual.taxpayer.clientGuid, this.props.mutual.spouse.clientGuid, (status: boolean) => this.signCallback(status));
                        }
					  else if (param.controllerId && TaxpayerHelper.anyOneSignerDeceased(this.props.mutual, this.props.signerData)) {
							let Id = param.clientId;
							if (TaxpayerHelper.isTaxpayerDeceased(this.props.mutual, this.props.signerData)) {
								Id = this.props.mutual.spouse.clientGuid;
							}
							logger.trackTrace("Document submitted for signing completion", { "ClientId": param.clientId, "DocumentId": documentId, "PreviewMode": this.props.isPreviewMode });
							this.props.sign && this.props.sign(Id, this.props.signatureControlsData, (status: boolean) => this.signCallback(status));
						}
						else if (this.props.signBehalfSpouse) {
							logger.trackTrace("Document submitted for signing completion", { "ClientId": param.clientId, "DocumentId": documentId, "PreviewMode": this.props.isPreviewMode });
							this.props.signOnBehalfSpouse && this.props.signOnBehalfSpouse(param.clientId, this.props.signatureControlsData, this.props.mutual.taxpayer.clientGuid, this.props.mutual.spouse.clientGuid, (status: boolean) => this.signCallback(status));

						} else {
							logger.trackTrace("Document submitted for signing completion", { "ClientId": param.clientId, "DocumentId": documentId, "PreviewMode": this.props.isPreviewMode });
							this.props.sign && this.props.sign(param.clientId, this.props.signatureControlsData, (status: boolean) => this.signCallback(status));
						}
					}
				});
			}
		}
	}

	signCallback = (status: boolean) => {
		const param: any = this.props.match.params;
		if (status) {
			logger.trackTrace("gotoNextStep : Signing completed successfully", { "ClientId": param.clientId,"DocumentId": this.props.signatureControlsData[0]?.id, "PreviewMode": this.props.isPreviewMode  });
			this.props.completeSigningStep();
		}
		else {
			this.setState({ showSubmitLoader: false }, () => { MarsNotifier.Error("Signing process failed!", ""); });
			logger.trackTrace("gotoNextStep : Signing completion failed", { "ClientId": param.clientId, "DocumentId": this.props.signatureControlsData[0]?.id, "PreviewMode": this.props.isPreviewMode  });
		}
	}

	handleViewModeChange = (viewerMode: ViewerMode) => {

		if (viewerMode == ViewerMode.Read) {
			this.props.onHeaderAndFooterVisibility(true);
		}
		else {
			this.props.onHeaderAndFooterVisibility(false);
		}
	}


	public render() {
		const param: any = this.props.match.params;
		return (
			<Main>
				<PdfView onViewModeChange={this.handleViewModeChange}
					documents={this.props.signatureControlsData}
					onSigningCompleted={this.handleSigningCompleted}
					disableNextButton={this.handleNextButton}
					headerInfo={this.props.headerInfo}
					ref={(ref: PdfView) => this._pdfView = ref}
					doNotAllowToEditSignatureName={isIndividualEngagementType(this.props.engagementType)}
					signBehalfSpouse={this.props.signBehalfSpouse}
					spouseName={TaxpayerHelper.getSecondSignerName(this.props.mutual, this.props.signerData, param.clientId,param.controllerId)}
				/>
			</Main>

		);
	}
}
