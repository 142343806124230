import { connect } from "react-redux";
import { actionCreators as accountAction } from "../../../store/Common/AccountStore";
import { IClientProcessViewModel } from "../../../core/domain/viewModels/IClientProcessViewModel";
import { actionCreators as CompanyStore } from "../../../store/Common/Company/CompanyStore";
import { actionCreators as EsignStore } from "../../../store/Common/EsignStore";
import { actionCreators as HelperStore } from "../../../store/Common/HelperStore";
import { actionCreators as TaxDocumentStore } from "../../../store/Common/TaxDocumentStore";
import { actionCreators as DownloadableDocumentsStore } from "../../../store/DownloadableDocumentsStore";
import { ApplicationState } from "../../../store/index";
import { createLoadingSelector } from "../../../store/selectors";
import { actionCreators as ManualSignStore } from "../../../store/Sign/ManualSignStore";
import { actionCreators as SignProcessStore } from "../../../store/SignProcess/SignProcessStore";
import { actionCreators as PaymentStore } from "../../../store/PaymentStore";
import { actionCreators as PayStore } from "../../../store/PayStore";
import { IPaymentTransactionModel } from "../../../core/domain/models/IPaymentTransactionModel";
import { actionCreators as SummaryStore } from "../../../store/SummaryStore";
import CompletedWizardFlow from "./CompletedWizardFlow";
import { actionCreators as DelegateeSignerStore } from "../../../store/DelegateeSignerStore";
import { IDelegateeSignerDetails } from "../../../core/domain/models/IDelegateeSignerModel";
import { actionCreators as InvoicePaymentStore } from "../../../store/InvoicePaymentStore";
import { IInvoicePayment } from "../../../core/domain/models/InvoicePaymentModel";

const loadingSelector = createLoadingSelector([
  "HEADERINFO",
  "SUMMARY_DETAILS",
  "TAXING_AUTHORITY",
  "DOCUMENT_REVIEW_MODEL",
  "DOCUMENT_SETTINGS",
]);

function mapStateToProps(state: ApplicationState) {
  return {
    summaryData: state.summaryData,
    commonData: state.commonData,
    taxReturn: state.taxReturn.taxDocument,
    signedDocument: state.manualSignData.data,
    headInfoViewModel: state.headerInfo,
    downloadableDocument: state.downloadableDocuments,
    signProcessData: state.signProcessData.data,
    delegateeSigner: state.delegateeSigner,
    paymentData: state.paymentData.data,
    companyData: state.companyData.data,
    invoicePaymentData: state.invoicePaymentData.data,
    clientProcessModel: state.signProcessData.clientprocessmodel,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    requestSummaryDetails: (clientId: string) =>
      dispatch(SummaryStore.requestSummaryDetails(clientId)),

    requestAllTaxingAuthorities: (id: string) =>
      dispatch(HelperStore.requestAllTaxingAuthorities(id)),

    requestNextSigner: (clientGuid: string) =>
      dispatch(TaxDocumentStore.requestNextSigner(clientGuid)),

    sendSigningReminder: (clientId: string) =>
      dispatch(SummaryStore.sendSigningReminder(clientId)),

    requestCompanyDetails: (clientGuid: string, forceRefresh?: boolean) =>
      dispatch(CompanyStore.requestCompanyDetails(clientGuid, forceRefresh)),

    requestTaxDocument: (clientGuid: string, callback?: (data?: any) => void) =>
      dispatch(TaxDocumentStore.requestTaxDocument(clientGuid, callback)),

    requestSigners: (clientGuid: string) =>
      dispatch(EsignStore.requestSigners(clientGuid)),

    downloadSignedEFileDocument: (clientGuid: string, fileName: string) =>
      dispatch(
        DownloadableDocumentsStore.downloadSignedEFileDocument(
          clientGuid,
          fileName
        )
      ),

    requestDownloadableDocuments: (clientGuid: string) =>
      dispatch(
        DownloadableDocumentsStore.requestDownloadableDocuments(clientGuid)
      ),

    downloadAllAdditionEsignDocuments: (clientGuid: string, fileName: string) =>
      dispatch(
        DownloadableDocumentsStore.downloadAllAdditionEsignDocuments(
          clientGuid,
          fileName
        )
      ),

    requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean) =>
      dispatch(
        ManualSignStore.requestSignedDocuments(clientGuid, forceRefresh)
      ),

    downloadEFileDocument: (clientGuid: string, fileName: string) =>
      dispatch(
        DownloadableDocumentsStore.downloadEFileDocument(clientGuid, fileName)
      ),

    requestLastVisitedStep: (
      clientGuid: string,
      successCallback?: (lastVisitedStep: number) => void
    ) =>
      dispatch(
        SignProcessStore.requestLastVisitedStep(clientGuid, successCallback)
      ),

    requestCurrentStepAndUserRole: (
      clientGuid: string,
      successCallback?: (clientProcessState: IClientProcessViewModel) => void
    ) =>
      dispatch(
        SignProcessStore.requestCurrentStepAndUserRole(
          clientGuid,
          successCallback
        )
      ),

    requestMyDownload: (clientId: string) =>
      dispatch(DownloadableDocumentsStore.requestMyDownload(clientId)),

    deleteMyDownloads: (downloadId: string, clientId: string) =>
      dispatch(
        DownloadableDocumentsStore.deleteMyDownloads(downloadId, clientId)
      ),

    logout: (clientGuid: string, callback: () => void) =>
      dispatch(accountAction.logout(clientGuid, callback)),

    clearAllMyDownloads: (clientId: string) =>
      dispatch(DownloadableDocumentsStore.clearAllMyDownloads(clientId)),

    requestHeaderInfo: (id: string) =>
      dispatch(accountAction.requestHeaderInfo(id)),

    updateDelegatee: (
      clientId: string,
      delegatee: IDelegateeSignerDetails,
      callback: () => void
    ) =>
      dispatch(
        DelegateeSignerStore.updateDelegatee(clientId, delegatee, callback)
      ),

    cancelDelegation: (
      clientId: string,
      remarks: string,
      taxYear: number,
      callback: () => void
    ) =>
      dispatch(
        DelegateeSignerStore.cancelDelegation(
          clientId,
          remarks,
          taxYear,
          callback
        )
      ),

    resetLastVisitedSteps: (clientId: string, callback: () => void) =>
      dispatch(SignProcessStore.resetLastVisitedSteps(clientId, callback)),

    requestTaxClientDelegateeDetails: (clientId: string) =>
      dispatch(DelegateeSignerStore.requestTaxClientDelegateeDetails(clientId)),

    downloadTaxInvoice: (clientId: string) =>
      dispatch(PayStore.downloadTaxInvoice(clientId)),

    requestPaymentTransaction: (clientId: string) =>
      dispatch(PaymentStore.requestPaymentTransaction(clientId)),
    requestStripePublicAPIKey: (clientId: string) =>
      dispatch(PaymentStore.requestStripePublicAPIKey(clientId)),
    requestPaymentIntent: (
      clientId: string,
      amount: number,
      callbak?: () => void
    ) => dispatch(PaymentStore.requestPaymentIntent(clientId, amount, callbak)),
    updatePurchaseTransactionStatus: (
      clientId: string,
      paymentTransaction: IPaymentTransactionModel,
      callback?: () => void
    ) =>
      dispatch(
        PaymentStore.updatePurchaseTransactionStatus(
          clientId,
          paymentTransaction,
          callback
        )
      ),
    requestInvoicePaymentDetails: (clientId: string) =>
      dispatch(InvoicePaymentStore.requestInvoicePaymentDetails(clientId)),
    addInvoicePayment: (
      clientId: string,
      invoice: IInvoicePayment,
      callback?: () => void
    ) =>
      dispatch(
        InvoicePaymentStore.addInvoicePayment(clientId, invoice, callback)
      ),
    updateInvoicePayment: (
      clientId: string,
      invoice: IInvoicePayment,
      callback?: () => void
    ) =>
      dispatch(
        InvoicePaymentStore.updateInvoicePayment(clientId, invoice, callback)
      ),
    deleteInvoicePayment: (clientId: string, callback?: () => void) =>
      dispatch(InvoicePaymentStore.deleteInvoicePayment(clientId, callback)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CompletedWizardFlow as any);
