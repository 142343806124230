import * as React from 'react';
import * as PayWizard from '../Pay/Main/Pay';
import * as PayStore from '../../store/PayStore';
import * as TaxDocumentStore from '../../store/Common/TaxDocumentStore';
import { IPayScreenViewModel } from '../../core/domain/viewModels/IPayScreenViewModel';
import { ICommonDataViewModel } from '../../core/domain/viewModels/ICommonDataViewModel';
import { IDocumentSettings } from '../../core/domain/models/IDocumentSettings';
import * as TaxDocument from '../../core/domain/models/ITaxReturn';
import { match, RouteComponentProps } from 'react-router';
import { ICompany } from '../../core/domain/models/company/Company';
import * as CompanyStore from '../../store/Common/Company/CompanyStore';
import { History } from 'history';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import * as PreparerMessageStore from '../../store/PreparerMessageStore';
import * as HelperStore from '../../store/Common/HelperStore';
import { PathConstants, VoucherPaymentConstants, getRoutePathUrl, VoucherSelectorPage } from '../Common/Constants';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { Body } from '../Common/StepLayout/Body';
import { Footer } from '../Common/StepLayout/Footer';
import { Next } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Next';
import { Previous } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Previous';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { Role } from '../../core/common/Enums'
import * as SignProcessStore from '../../store/SignProcess/SignProcessStore';
import { Header } from '../Common/StepLayout/Header';
import { PaymentInstruction } from './PaymentInstruction';
import { LeftPanel } from '../Common/StepLayout/LeftPanel';
import { TaxPayment } from './TaxPayment';
import { IVoucher, VoucherTypes } from '../../core/domain/models/IGroup';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import * as PaymentStore from '../../store/PaymentStore';
import { IPaymentTransactionModel } from '../../core/domain/models/IPaymentTransactionModel';
import { storeControllerIdInMemory } from '../../core/services/dataAccess/DataService.Axios';
import PaymentVoucher from "./PaymentVoucher/PaymentVoucher";
import EstimatedVoucher from "./EstimatedVoucher/EstimatedVoucher";
import { checkVoucherValidation } from "../Helper/HelperFunction";
import { VoucherPayConstant } from "../../components/Common/Constants";
import { ITaxpayerPaymentSettings } from "../../core/domain/models/company/Company"
import * as DocumentStatusStore from '../../store/Common/DocumentStatusStore';
import { BackButtonIcon } from "../Common/Icons/SvgIcons";
import { Button } from 'react-bootstrap';

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);


type PayCompletedProps = {
    payScreenData: IPayScreenViewModel,
    commonData: ICommonDataViewModel,
    documentSettings: IDocumentSettings,
    taxReturn: TaxDocument.ITaxReturn,
    headInfoViewModel: IHeaderInfoViewModel,
    companyData: ICompany,
    match: match;
    history: History;
    paymentData: IPaymentTransactionModel;
    paymentType: VoucherTypes
    taxpayerPaymentSettings: ITaxpayerPaymentSettings;
    DocumentStatusState: DocumentStatusStore.IDocumentStatusState
}
    & typeof CompanyStore.actionCreators
    & typeof HelperStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof PreparerMessageStore.actionCreators
    & typeof PayStore.actionCreators
    & typeof SignProcessStore.actionCreators
    & typeof PaymentStore.actionCreators
    & typeof DocumentStatusStore.actionCreators

interface PayCompletedState {
    isProcessCompleted: boolean;
    isClientView: boolean;
    isPreviewMode: boolean;
}


class PayCompletedVoucher extends React.Component<PayCompletedProps, PayCompletedState> {
    private _params: any;
    private _payRef: any;
    constructor(props: PayCompletedProps) {
        super(props);
        this.state = {
            isProcessCompleted: false,
            isClientView: false,
            isPreviewMode: false
        }
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        if (param && param.controllerId) {
            storeControllerIdInMemory(param.controllerId);
        }
        this.getSignProcessStatus(param.clientId);
        this.props.requestDocumentSettings(param.clientId);
        if (this.props.commonData && this.props.commonData.taxingAuthorities.length == 0) {
            this.props.requestAllTaxingAuthorities(param.clientId);
        }
        if (!this.props.companyData || !this.props.companyData.onlinePaymentUrl) {
            this.props.requestCompanyDetails(param.clientId);
        }
        this.props.requestTaxDocument(param.clientId);
        this.props.getDocumentUpdatedStatus(param.clientId);
    }

    gotoCompletionWizard = () => {
        const param: any = this.props.match.params;
        this._payRef && this._payRef.updateVoucherReminderDetails(
            () => {
                this.props.history.push(getRoutePathUrl(PathConstants.CompletionWizard, param.clientId, param.controllerId));
            });
    }

    backToSelectWizard = () => {
        const param: any = this.props.match.params;
        this._payRef && this._payRef.updateVoucherReminderDetails(
            () => {
                this.props.history.push(getRoutePathUrl(PathConstants.Pay, param.clientId, param.controllerId));;
            });
    }


    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }
    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        this._params = this.props.match.params;
        const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId, this._params.controllerId);

        if (url && url.trim()) {
            this.props.history.push(url);
            this.setState({ isClientView: TaxpayerHelper.isPreviewModeWithWritePermission(clientProcessState) });
        }
        else {
            this.setState({
                isProcessCompleted: true,
                isClientView: TaxpayerHelper.isPreviewModeWithWritePermission(clientProcessState),
                isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState)
            });
        }
    }

    saveVoucherPayment = (voucher: IVoucher) => {
        if (this.state.isClientView) {
            dialogBox.alert(VoucherPaymentConstants.AddingVoucherPaymentNotAllowed);
        }
        else {
            const param: any = this.props.match.params;
            if (voucher !== null && voucher !== undefined) {
                if (!voucher.isPaid) {
                    this.props.addVoucherPayemnt(param.clientId, voucher);
                }
                else {
                    this.props.updateVoucherPayemnt(param.clientId, voucher);
                }
            }
        }
    }

    clearPaymentDetails = (voucher: IVoucher) => {
        if (this.state.isClientView) {
            dialogBox.alert(VoucherPaymentConstants.AddingVoucherPaymentNotAllowed);
        }
        else {
            const param: any = this.props.match.params;
            if (voucher !== null && voucher !== undefined) {
                this.props.clearPaymentDetails(param.clientId, voucher);
            }
        }
    }

    goToSelectedVoucher = () => {
        const param: any = this.props.match.params;
        this._payRef && this._payRef.updateVoucherReminderDetails(
            () => {
                if (this.props.paymentType === VoucherTypes.PaymentVoucher) {
                    return this.props.history.push(`${VoucherSelectorPage.EstimatedVoucherPageURL}/${param.controllerId ? param.controllerId + "/" : ""}${param.clientId}`);
                }
                else {
                    return this.props.history.push(`${VoucherSelectorPage.TaxpaymentVoucherPageURL}/${param.controllerId ? param.controllerId + "/" : ""}${param.clientId}`);

                }
            });
    }


    handleSubmit = () => {
        const param: any = this.props.match.params;

        return this.props.history.push(getRoutePathUrl(PathConstants.Pay, param.clientId, param.controllerId));
    }

    public render() {


        let voucherType: VoucherTypes = this.props.paymentType;

        return ((this.state.isProcessCompleted) &&
            <StepLayout className={"with-footer paycompletedvoucher-container"}>

                <Body
                    lg={12} md={12} sm={12} xs={12}
                    noBackgroundColor={true}
                    containerClassName="pay-panel-body no-padding"
                    bodyClassName={"no-padding no-margin pay-step row"}
                    automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>

                    {this.props.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel && this.props.taxReturn.formGroups.length > 0 &&
                        (
                            voucherType === VoucherTypes.PaymentVoucher ?
                                <PaymentVoucher
                                    ref={(ref: any) => { this._payRef = ref }}
                                    commonData={this.props.commonData} match={this.props.match}
                                    history={this.props.history} payScreenData={this.props.payScreenData}
                                    requestPayScreenDetails={this.props.requestPayScreenDetails}
                                    documentSettings={this.props.documentSettings}
                                    taxReturn={this.props.taxReturn}
                                    downloadAllVouchers={this.props.downloadAllVouchers}
                                    downloadFilingInstructions={this.props.downloadFilingInstructions}
                                    downloadTaxInvoice={this.props.downloadTaxInvoice}
                                    updateVoucherNotificationSettings={this.props.updateVoucherNotificationSettings}
                                    downloadSingleVoucher={this.props.downloadSingleVoucher}
                                    invoicePaymentUrl={this.props.companyData.onlinePaymentUrl}
                                    saveVoucherPayment={this.saveVoucherPayment}
                                    isPreviewMode={this.state.isPreviewMode}
                                    clearPaymentDetails={this.clearPaymentDetails}
                                    onSubmitPay={this.handleSubmit}
                                    taxpayerPaymentSettings={this.props.taxpayerPaymentSettings}
                                    documentUpdated={this.props.DocumentStatusState.documentUpdated}
                                    SetDocumentIsDownloaded={this.props.SetDocumentIsDownloaded}
                                /> :
                                <EstimatedVoucher
                                    ref={(ref: any) => { this._payRef = ref }}
                                    commonData={this.props.commonData} match={this.props.match}
                                    history={this.props.history} payScreenData={this.props.payScreenData}
                                    requestPayScreenDetails={this.props.requestPayScreenDetails}
                                    documentSettings={this.props.documentSettings}
                                    taxReturn={this.props.taxReturn}
                                    downloadAllVouchers={this.props.downloadAllVouchers}
                                    downloadFilingInstructions={this.props.downloadFilingInstructions}
                                    downloadTaxInvoice={this.props.downloadTaxInvoice}
                                    updateVoucherNotificationSettings={this.props.updateVoucherNotificationSettings}
                                    downloadSingleVoucher={this.props.downloadSingleVoucher}
                                    invoicePaymentUrl={this.props.companyData.onlinePaymentUrl}
                                    saveVoucherPayment={this.saveVoucherPayment}
                                    isPreviewMode={this.state.isPreviewMode}
                                    clearPaymentDetails={this.clearPaymentDetails}
                                    onSubmitPay={this.handleSubmit}
                                    taxpayerPaymentSettings={this.props.taxpayerPaymentSettings}
                                    documentUpdated={this.props.DocumentStatusState.documentUpdated}
                                    SetDocumentIsDownloaded={this.props.SetDocumentIsDownloaded}
                                />
                        )
                    }

                </Body>

                <Footer lg={12} md={12} sm={12} xs={12}>
                    <div className={"steps-footer "}>
                        <Previous automationTestId={"334"} text={"Back"} goToPreviousStep={this.backToSelectWizard} isActive={true} disableMobileResponsiveness={true}
                            previousButtonIcon={<BackButtonIcon />}
                        />
                        <span style={{ float: "right", marginLeft: "20px" }}>
                            <Next automationTestId={"324234"}
                                text={"Done"} goToNextStep={this.gotoCompletionWizard} isActive={true} disableMobileResponsiveness={true} className="awesome-multi-steps-btn-done" buttonVariant="success" buttonSize="medium" />
                        </span>

                        <Button className="btn-clear-payment"
                            data-test-auto="334"
                            style={{ float: "right", marginLeft: "20px" }}
                            onClick={() => {
                                if (voucherType === VoucherTypes.PaymentVoucher) {
                                    checkVoucherValidation(this.props.taxReturn, VoucherTypes.EstimatedVoucher, VoucherPayConstant.NoEstimatedVoucher, this.goToSelectedVoucher)
                                }
                                else {
                                    checkVoucherValidation(this.props.taxReturn, VoucherTypes.PaymentVoucher, VoucherPayConstant.NoPaymentVoucher, this.goToSelectedVoucher)
                                }
                            }}
                        >
                            <u>{voucherType === VoucherTypes.PaymentVoucher ? "Go To Estimated Voucher" : "Go To Payment Voucher"}</u>
                        </Button>
                    </div>
                </Footer>

            </StepLayout>);

    }
}


export default PayCompletedVoucher;