import React from "react";
import Footer from "src/components/Layout/Footer";
import OtpInputControl from "../Controls/OTPInputControl";
import { SvgIconAccessCode } from "../Icons/BrandIcons";
import Skeleton from "react-loading-skeleton";
import HeaderComp from "src/components/Header/HeaderComp";
import { ButtonComponent } from "cp-common-ui-components";

interface OTPEmailProps {
  match: any;
  history: any;
  headerInfoState: any;
  OTPPageState: any;
  otpVal: string;
  continueState: boolean;
  isInitialOTPSent: boolean;
  otpError: {
    error: boolean;
    message: string;
  };
  handleOTPChange: (otp: string) => void;
  requestCode: () => void;
  verifyOTP: () => void;
}

export class OTPEmail extends React.Component<OTPEmailProps, any> {
  continueRef: any = null;

  constructor(props: any) {
    super(props);    
  }
  componentDidMount() {}

  render() {
    let continueClass = "button-primary button-medium rounded-pill disabled float-right";
    if (this.props.continueState) {
      continueClass = "button-primary button-medium rounded-pill float-right";
      this.continueRef.focus();
    }

    return (
      <>    
          <div className="layout-container d-flex align-items-center justify-content-center">
            <div className="row">
              <div className="col">
                <div className="row justify-content-center">
                  <SvgIconAccessCode />
                </div>
                <div className="row justify-content-center margin-top-20">
                  <h5 className="otp-page-title">Request Access Code</h5>
                </div>
                <div className="row justify-content-center margin-top-10">
                  {!this.props.isInitialOTPSent ? (
                    <div
                      className="margin-top-10 text-center"
                      data-test-auto="DA23FCDD-9108-46D3-874F-4C65133691C9"
                      style={{ width: "65%" }}
                    >
                      <p>
                        We will send you a One-Time Access Code to your email
                        address.
                      </p>
                    </div>
                  ) : (
                    <div
                      className="margin-top-10 text-center"
                      data-test-auto="16E3BF76-FC56-4B1E-AB29-D1BF5924ED67"
                      style={{ width: "65%" }}
                    >
                      <p>
                        {" "}
                        A One-Time Access Code has been sent to your email
                        address.{" "}
                      </p>
                      <p style={{ color: "#CC4A43" }}>Expires in 20 minutes.</p>
                    </div>
                  )}
                </div>
                {!this.props.isInitialOTPSent && (
                  <div className="row justify-content-center margin-top-20">
                    <div>
                      <ButtonComponent
                        data-test-auto="1337B8DA-F232-427E-8AD1-6279665655EE"
                        style={{ width: 250, height: 56 }}
                        id="btnRequestAccessCode"
                        onClick={this.props.requestCode}
                        variant={"primary"}
                        size={"medium"}
                        buttonClassName={"rounded-pill"}
                      >
                        Send Code
                      </ButtonComponent>
                    </div>
                  </div>
                )}

                {this.props.isInitialOTPSent && (
                  <>
                    <div className="row justify-content-center otp-page-container-input-div margin-top-20"
                    data-test-auto="C4BE0D92-A645-4066-BC13-A710C9F637A7">
                      <OtpInputControl
                        otp={this.props.otpVal}
                        setOtp={this.props.handleOTPChange}
                        isInvalid={this.props.otpError.error}
                        errorText={this.props.otpError.message}
                        numInputs={8}
                      />
                    </div>
                    <div className="row justify-content-center margin-top-20">
                      <div className="col-flex align-items-center text-center">
                        <div className="spam-text">
                          If you did not receive it yet, check your junk/spam.
                        </div>
                        <ButtonComponent
                          id="btn-resend-code"
                          data-test-auto="47AFC106-36F0-42B5-84D9-C7BE60608669"
                          onClick={this.props.requestCode}
                          variant={"link"}
                          size={"small"}
                          buttonClassName="resend-btn"
                        >
                          Resend Code
                        </ButtonComponent>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="margin-top-20">
                        <ButtonComponent
                          ref={(ref) => {
                            this.continueRef = ref;
                          }}
                          type="submit"
                          id="btnMFAContinue"
                          data-test-auto="5066E088-CF8C-4C6F-97F9-795EAA225082"
                          buttonClassName={continueClass}
                          style={{ width: 250, height: 56 }}
                          onClick={this.props.verifyOTP}
                          variant={"primary"}
                          size={"medium"}
                        >
                          Confirm
                        </ButtonComponent>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
      </>
    );
  }
}
export default OTPEmail;
