
import * as React from 'react';
import HeaderComp from "../../Header/HeaderComp";
import Skeleton from "react-loading-skeleton";
import Footer from 'src/components/Layout/Footer';
import OtpInputControl from 'src/components/Common/Controls/OTPInputControl';
import { SvgIconAccessCode } from 'src/components/Common/Icons/BrandIcons';
import OTPEmail from 'src/components/Common/OTP/OTPEmail';
import { DisplayError } from 'src/components/Common/Constants';



export class EmailOtp extends React.Component<any, any> {
    continueRef: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            otpVal: "",
            continueState: false,
            isInitialOTPSent: false,
            otpError: {
              error: false,
              message: "",
            },
        };

        this.handleOTPChange = this.handleOTPChange.bind(this);
        this.requestCode = this.requestCode.bind(this);
        this.verifyOTP = this.verifyOTP.bind(this);
        this.selectPath = this.selectPath.bind(this);
        this.handleOTPVerification = this.handleOTPVerification.bind(this);
    }





    componentDidMount() {
        this.props.requestCCHeaderDetails(this.props.match.params.clientId);
        this.props.CCRClientInfo(this.props.match.params.clientId);
    }

    handlerError = (result?: any) => {
        let errorDesc = result.errorDescription;
        let errorCode = result.errorCode;
        if (errorCode && DisplayError.includes(errorCode)) {
          this.props.history.push("/Error");
        } else {
          this.setState({
            otpError: { error: true, message: errorDesc },
            otpVal: "",
          });
        }
      };


    handleOTPChange(e: any) {
        let otpValue = e;
        if (otpValue.length <= 8 && (!isNaN(otpValue) || otpValue.length === 0)) {
            if (otpValue.length === 8) {
                this.setState({ otpVal: otpValue, continueState: true });
            }
            else {
                this.setState({ otpVal: otpValue, continueState: false });
            }
        }
    }

    selectPath(path:string) {

        if (path === "TextOTP") {
            let clientId: string = this.props.match.params.clientId;
            this.props.history.push(`/MobileOTP/Index/CCRecipient/${clientId}`);
        }

    }

    requestCode() {
        if (!this.state.isInitialOTPSent) this.setState({ isInitialOTPSent: true });
        this.props.generateOTPCCR(this.props.match.params.clientId);
    }

    handleOTPVerification(guid: string) {
        console.log("in handleOTP");
        this.props.history.push(`/Cc/DownloadTaxDocuments/${guid}`);
    }

    verifyOTP() {
        if (this.state.continueState) {
            this.props.verifyOTPCCR(this.state.otpVal, this.props.match.params.clientId, this.handleOTPVerification, this.handlerError);
        }
    }

    render() {
        return (
            <>
              <HeaderComp
                headerInfo={this.props.headerInfoState}
                pathname={this.props.match.path}
                clientId={this.props.match.params.clientId}
                history={this.props.history}
                backButton={true}
                navigateLink="/CoverPage/Index"
              />
              {this.props.OTPPageState.loading === true ? (
                <Skeleton circle={false} height={"100vh"} width={"100vw"} />
              ) : (
                <OTPEmail
                    match={this.props.match}
                    history={this.props.history}
                    headerInfoState={this.props.headerInfoState}
                    OTPPageState={this.props.OTPPageState}
                    otpVal={this.state.otpVal}
                    continueState={this.state.continueState}
                    handleOTPChange={this.handleOTPChange}
                    requestCode={this.requestCode}
                    verifyOTP={this.verifyOTP}
                    otpError={this.state.otpError}
                    isInitialOTPSent={this.state.isInitialOTPSent}
                    />
              )}
              <Footer />
            </>
          );
    }

}
export default EmailOtp;