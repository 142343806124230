import { ClientType } from "src/core/common/Enums";
import { ISignerModel } from "src/core/domain/models/ISignerModel";
import * as React from "react";
import { match } from "react-router";
import { IDocument } from "../../core/domain/models/esign/Document";
import { IMarriedJointTaxReturn } from "../../core/domain/models/ITaxReturn";
import { SiginingConstants } from "../Common/Constants";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { Body } from "../Common/StepLayout/Body";
import { StepLayout } from "../Common/StepLayout/StepLayout";
import { isValidEmailAddress, validatePhone, validateCountryCode } from "../Common/Validations";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import { EditSpouseMail } from "./EditSpouseMail";
import { EditSpouseMobileNumber } from "./EditSpouseMobileNumber";
import { logger } from "../../routes";
import { AddIcon, EditMobileNumberIcon, MailIcon } from "../Common/Icons/SvgIcons";
import { handleKeyDown } from "../Helper/HelperFunction";

export type ISpouseSignRequiredProps = {
    companyName: string;
    mutual: IMarriedJointTaxReturn;
    match: match;
    signerData: ISignerModel[];
    refreshTaxDocument: (clientGuid: string) => void;
    updateSpouseMailChange?(
        clientGuid: string,
        spouseGuid: string,
        id: string,
        mail: string,
        type: ClientType,
        step?: boolean,
        callback?: (step: boolean) => void
    ): void;
    signatureControlsData: IDocument[];
    sign?: (clientId: string, documentData: IDocument[], callback: (status: boolean) => void) => void;
    signOnBehalfSpouse?: (
        clientId: string,
        documentData: IDocument[],
        taxpayerGuid: string,
        spouseGuid: string,
        callback: (status: boolean) => void
    ) => void;
    gotoNextStep: () => void;
    signingCompleted?: () => boolean | undefined;
    isPreviewMode: boolean;
    signBehalfSpouse: boolean;
    updateSpouseMobileChange?(
        clientGuid: string,
        id: string,
        newMobileNumber: string,
        newCountryCode: string,
        ssn: string,
        type: ClientType,
        step?: boolean,
        callback?: (step: boolean) => void
    ): void;
};

interface ISpouseSignRequiredState {
    emailAddress: string;
    name: string;
    showAlert: boolean;
    showEditSpouseEmailPopUp: boolean;
    showSubmitLoader: boolean;
    mobileNumber: string;
    countryCode: string;
    showEditSpouseMobileNumber: boolean;
}

export class SpouseSignRequired extends React.Component<ISpouseSignRequiredProps, ISpouseSignRequiredState> {
    constructor(props: any) {
        super(props);
        const param: any = this.props.match.params;
        this.state = {
            emailAddress: TaxpayerHelper.getSecondSignerEmail(props.mutual, props.signerData, param.clientId),
            name: TaxpayerHelper.getSecondSignerName(props.mutual, props.signerData, param.clientId),
            showAlert: true,
            showEditSpouseEmailPopUp: false,
            showSubmitLoader: false,
            mobileNumber: TaxpayerHelper.getSecondSignerMobileNumber(props.mutual, props.signerData, param.clientId),
            countryCode: TaxpayerHelper.getSecondSignerMobileCountryCode(
                props.mutual,
                props.signerData,
                param.clientId
            ),
            showEditSpouseMobileNumber: false
        };
    }

    componentDidUpdate(
        prevProps: Readonly<ISpouseSignRequiredProps>,
        prevState: Readonly<ISpouseSignRequiredState>,
        snapshot?: any
    ): void {
        const param: any = this.props.match.params;
        if (
            this.state.emailAddress !==
            TaxpayerHelper.getSecondSignerEmail(this.props.mutual, this.props.signerData, param.clientId)
        ) {
            this.setState({
                emailAddress: TaxpayerHelper.getSecondSignerEmail(
                    this.props.mutual,
                    this.props.signerData,
                    param.clientId
                )
            });
        }

        if (
            this.state.name !==
            TaxpayerHelper.getSecondSignerName(this.props.mutual, this.props.signerData, param.clientId)
        ) {
            this.setState({
                name: TaxpayerHelper.getSecondSignerName(this.props.mutual, this.props.signerData, param.clientId)
            });
        }

        if (
            this.state.mobileNumber !==
            TaxpayerHelper.getSecondSignerMobileNumber(this.props.mutual, this.props.signerData, param.clientId)
        ) {
            this.setState({
                mobileNumber: TaxpayerHelper.getSecondSignerMobileNumber(
                    this.props.mutual,
                    this.props.signerData,
                    param.clientId
                )
            });
        }

        if (
            this.state.countryCode !==
            TaxpayerHelper.getSecondSignerMobileCountryCode(this.props.mutual, this.props.signerData, param.clientId)
        ) {
            this.setState({
                countryCode: TaxpayerHelper.getSecondSignerMobileCountryCode(
                    this.props.mutual,
                    this.props.signerData,
                    param.clientId
                )
            });
        }
    }

    static getDerivedStateFromProps(props: ISpouseSignRequiredProps, state: ISpouseSignRequiredState) {
        if (!props.mutual || !props.mutual.spouse || !props.mutual.spouse.email || !props.mutual.spouse.name) {
            return null;
        } else {
            if (
                (props.mutual.spouse &&
                    props.mutual.spouse.email &&
                    props.mutual.spouse.email !== state.emailAddress) ||
                (props.mutual.spouse && props.mutual.spouse.name && props.mutual.spouse.name !== state.name)
            ) {
                return {
                    emailAddress: state.emailAddress
                        ? state.emailAddress
                        : props.mutual.spouse.email
                        ? props.mutual.spouse.email
                        : "",
                    name: state.name ? state.name : props.mutual.spouse.name ? props.mutual.spouse.name : ""
                };
            }
        }
        return null;
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        logger.trackTrace("Spouse Esign Page View", {
            ClientId: param.clientId,
            PreviewMode: this.props.isPreviewMode
        });
    }

    onCancelEditSpouseEmailPopUp = () => {
        this.setState({
            showEditSpouseEmailPopUp: false
        });
    };

    updateSpouseEmail = (emailAddress: string) => {
        if (emailAddress.trim() === "" || !isValidEmailAddress(emailAddress)) {
            MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidEmailAddress, null);
            return false;
        } else {
            let param: any = this.props.match.params;
            let clientId: number = this.props.mutual && this.props.mutual.spouse ? this.props.mutual.spouse.id : 0;
            this.setState({ emailAddress: emailAddress }, () => {
                this.props.updateSpouseMailChange &&
                    this.props.updateSpouseMailChange(
                        param.clientId,
                        TaxpayerHelper.getSecondSignerGuid(
                            this.props.mutual,
                            this.props.signerData,
                            param.clientId
                        ).toString(),
                        TaxpayerHelper.getSecondSignerId(
                            this.props.mutual,
                            this.props.signerData,
                            param.clientId
                        ).toString(),
                        this.state.emailAddress,
                        TaxpayerHelper.getSecondSignerType(this.props.mutual, this.props.signerData, param.clientId),
                        undefined,
                        () => this.props.refreshTaxDocument(param.clientId)
                    );
            });
            this.setState({ showEditSpouseEmailPopUp: false });
        }
    };

    onChangeEmailClick = () => {
        this.setState({
            showEditSpouseEmailPopUp: true,
            showAlert: true
        });
    };

    updateSpouseMobileNumber = (spouseMobileNumber: string, spouseCountryCode: string) => {
        if (!validateCountryCode(spouseCountryCode) || !validatePhone(spouseMobileNumber)) {
            return false;
        } else {
            let param: any = this.props.match.params;
            let clientId: number = this.props.mutual && this.props.mutual.spouse ? this.props.mutual.spouse.id : 0;
            this.setState({ mobileNumber: spouseMobileNumber, countryCode: spouseCountryCode }, () => {
                this.props.updateSpouseMobileChange &&
                    this.props.updateSpouseMobileChange(
                        param.clientId,
                        TaxpayerHelper.getSecondSignerId(
                            this.props.mutual,
                            this.props.signerData,
                            param.clientId
                        ).toString(),
                        this.state.mobileNumber,
                        this.state.countryCode,
                        TaxpayerHelper.getSecondSignerSSN(this.props.mutual, this.props.signerData, param.clientId),
                        TaxpayerHelper.getSecondSignerType(this.props.mutual, this.props.signerData, param.clientId),
                        undefined,
                        () => this.props.refreshTaxDocument(param.clientId)
                    );
            });
            this.setState({ showEditSpouseMobileNumber: false });
        }
    };

    onCancelEditSpouseMobileNumberPopUp = () => {
        this.setState({
            showEditSpouseMobileNumber: false
        });
    };

    onChangeMobileNumberClick = () => {
        this.setState({
            showEditSpouseMobileNumber: true,
            showAlert: true
        });
    };

    onNext(index: number) {
        this.setState({ showSubmitLoader: true });
        let param: any = this.props.match.params;
        logger.trackTrace(`IsPreviewMode: ${this.props.isPreviewMode}`, {
            ClientId: param.clientId,
            PreviewMode: this.props.isPreviewMode
        });
        logger.trackTrace("Spouse Sign Required page continue button clicked", {
            ClientId: param.clientId,
            DocumentId: this.props.signatureControlsData[0]?.id,
            PreviewMode: this.props.isPreviewMode
        });
        if (this.props.isPreviewMode) {
            this.props.gotoNextStep();
        } else {
            if (this.props.signingCompleted && this.props.signingCompleted()) {
                logger.trackTrace("Signing Completed since visited steps indicates signing completion", {
                    ClientId: param.clientId,
                    PreviewMode: this.props.isPreviewMode
                });
                this.props.gotoNextStep();
            } else {
                if (this.props.signBehalfSpouse) {
                    logger.trackTrace("Document submitted for signing completion", {
                        ClientId: param.clientId,
                        DocumentId: this.props.signatureControlsData[0]?.id,
                        PreviewMode: this.props.isPreviewMode
                    });
                    this.props.signOnBehalfSpouse &&
                        this.props.signOnBehalfSpouse(
                            param.clientId,
                            this.props.signatureControlsData,
                            this.props.mutual.taxpayer.clientGuid,
                            this.props.mutual.spouse.clientGuid,
                            (status: boolean) => this.signCallback(status)
                        );
                } else {
                    logger.trackTrace("Document submitted for signing completion", {
                        ClientId: param.clientId,
                        DocumentId: this.props.signatureControlsData[0]?.id,
                        PreviewMode: this.props.isPreviewMode
                    });
                    this.props.sign &&
                        this.props.sign(param.clientId, this.props.signatureControlsData, (status: boolean) =>
                            this.signCallback(status)
                        );
                }
            }
        }
    }

    signCallback = (status: boolean) => {
        const param: any = this.props.match.params;
        if (status !== undefined && status === true) {
            logger.trackTrace("gotoNextStep : Signing completed successfully", {
                ClientId: param.clientId,
                DocumentId: this.props.signatureControlsData[0]?.id,
                PreviewMode: this.props.isPreviewMode
            });
            this.props.gotoNextStep();
        } else {
            this.setState({ showSubmitLoader: false });
            MarsNotifier.Error("Signing process failed!", "");
            logger.trackTrace("gotoNextStep : Signing completion failed", {
                ClientId: param.clientId,
                DocumentId: this.props.signatureControlsData[0]?.id,
                PreviewMode: this.props.isPreviewMode
            });
        }
    };

    render() {
        let mobileNumberDisplay = this.state.mobileNumber;

        if (this.state.mobileNumber !== undefined && this.state.mobileNumber !== "") {
            const format = ("" + mobileNumberDisplay).replace(/\D/g, "");
            const match = format.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                mobileNumberDisplay = "(" + match[1] + ") " + match[2] + "-" + match[3];
            }
        }

        return (
            <StepLayout className="spouse-sign-required">
                <Body
                    xl={7}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    containerClassName={"content-wrapper-full"}
                    title={"Spouse Electronic Signature Required"}
                >
                    {this.state.emailAddress ? (
                        <React.Fragment>
                            <div className="content-paragraph" data-test-auto="8CEFEB12-51FB-45C0-BF1B-EF2FDD76C03D">
                                <span className="font-weight-normal-bold font-weight-normal-bold-color">
                                    {this.state.name}
                                </span>
                                <span style={{ marginLeft: "5px", lineHeight: "1.4" }}>
                                    is still required to sign and will receive an email notification sent to:
                                </span>

                                <table style={{ marginTop: "20px" }}>
                                    <tr>
                                        <td>
                                            <span className="font-weight-normal-bold">{this.state.emailAddress}</span>
                                        </td>
                                        <td>
                                            <span
                                                style={{ margin: "0 30px", cursor: "pointer" }}
                                                data-test-auto="CCF78AE0-CFD1-49C7-AB9C-34BD35B4C6A9"
                                                onClick={() => {
                                                    this.onChangeEmailClick();
                                                }}
                                                tabIndex={0}
                                                onKeyDown={(e: any) => handleKeyDown(e, this.onChangeEmailClick)}
                                            >
                                                <MailIcon />
                                                <span className="links">Change Email</span>
                                            </span>
                                        </td>
                                        {this.state.mobileNumber === "" ? (
                                            <td>
                                                <span
                                                    style={{ cursor: "pointer" }}
                                                    data-test-auto="CCF78AE0-CFD1-49C7-AB9C-34BD35B4C6A9"
                                                    onClick={() => {
                                                        this.onChangeMobileNumberClick();
                                                    }}
                                                    tabIndex={0}
                                                    onKeyDown={(e: any) => handleKeyDown(e, this.onChangeMobileNumberClick)}
                                                >
                                                    <AddIcon />
                                                    <span className="links">Add Mobile Number</span>
                                                </span>
                                            </td>
                                        ) : (
                                            <></>
                                        )}
                                    </tr>

                                    {this.state.mobileNumber !== "" ? (
                                        <tr>
                                            <td>
                                                <span className="font-weight-normal-bold">
                                                    {this.state.countryCode}&nbsp;{mobileNumberDisplay}
                                                </span>
                                            </td>
                                            <td>
                                                <span
                                                    style={{ margin: "0 30px", cursor: "pointer" }}
                                                    className="links"
                                                    data-test-auto="CCF78AE0-CFD1-49C7-AB9C-34BD35B4C6A9"
                                                    onClick={() => {
                                                        this.onChangeMobileNumberClick();
                                                    }}
                                                    tabIndex={0}
                                                    onKeyDown={(e: any) => handleKeyDown(e, this.onChangeMobileNumberClick)}
                                                >
                                                    <EditMobileNumberIcon />
                                                    <span className="links">Change Mobile Number</span>
                                                </span>
                                            </td>
                                        </tr>
                                    ) : (
                                        <></>
                                    )}
                                </table>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="content-paragraph" data-test-auto="8CEFEB12-51FB-45C0-BF1B-EF2FDD76C03D">
                                <span className="font-weight-normal-bold font-weight-normal-bold-color">
                                    {this.state.name}
                                </span>
                                <span style={{ marginLeft: "5px", lineHeight: "1.4" }}>is still required to sign</span>
                            </div>
                        </React.Fragment>
                    )}

                    <div
                        style={{ marginTop: "43px" }}
                        className="content-paragraph"
                        data-test-auto="EDF130A0-C32F-433E-94B5-A5B65CA933B6"
                    >
                        <div style={{ lineHeight: "1.4", marginBottom: "20px" }}>
                            Once they have completed the signing process, your documents will be forwarded to:
                        </div>
                        <div className="font-weight-normal-bold" data-test-auto="EDF130A0A5B65CA933B6">
                            {this.props.companyName}
                        </div>
                    </div>

                    <EditSpouseMail
                        onHide={this.onCancelEditSpouseEmailPopUp}
                        onSaveButtonClick={this.updateSpouseEmail}
                        showAlert={this.state.showAlert}
                        spouseEmail={this.state.emailAddress}
                        showState={this.state.showEditSpouseEmailPopUp}
                    />
                    <EditSpouseMobileNumber
                        onHide={this.onCancelEditSpouseMobileNumberPopUp}
                        onSaveButtonClick={this.updateSpouseMobileNumber}
                        showAlert={this.state.showAlert}
                        spouseMobileNumber={this.state.mobileNumber}
                        spouseCountryCode={this.state.countryCode}
                        isEdit={this.state.mobileNumber !== ""}
                        showState={this.state.showEditSpouseMobileNumber}
                    />
                </Body>
            </StepLayout>
        );
    }
}

export default SpouseSignRequired;
