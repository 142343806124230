import * as React from 'react';
import { Viewer } from '../../../components/Common/PdfView/Viewer';
import { IDocumentReviewModel } from '../../../core/domain/viewModels/IDocumentReviewModel';
import { DocumentGroups } from '../../../core/domain/models/IGroup';
import { Shimmer } from '../../Common/Shimmer/Shimmer';
import { match } from 'react-router';
import { StepLayout } from '../../../components/Common/StepLayout/StepLayout';
import { Body } from '../../../components/Common/StepLayout/Body';
import { PdfViewer } from './PdfViewer';
import { SelectiveDownload } from '../../Download/SelectiveDownload';
import { ICCRecipientDownloadableDocuments } from '../../../core/domain/models/ICCRecipientModel';
import { StripePayment } from '../../Invoice/StripePayment';
import { ITaxpayerPaymentSettings } from '../../../core/domain/models/company/Company';
import { IPaymentTransactionModel } from '../../../core/domain/models/IPaymentTransactionModel';
import { logger } from '../../../routes';

interface IInvoiceProps {
    invoiceDocumentReviewModel: IDocumentReviewModel[];
    requestPreviewDocuments?: (clientId: string) => void;
    match: match;
    ccRecipientDownloadableDocuments?: ICCRecipientDownloadableDocuments[];
    downloadAllDocuments?: () => void;
    downloadSelectedDocuments?: (documentGroups: number[]) => void;
    showDownloadButton?: boolean;
    invoiceAmount: number;
    paymentData: IPaymentTransactionModel;
    paymentSetting: ITaxpayerPaymentSettings;
    requestPaymentIntent: (
        clientId: string,
        amount: number,
        callback: () => void
    ) => void; 
    updatePurchaseTransactionStatus: (
        clientId: string,
        paymentTransaction: IPaymentTransactionModel,
        callback?: () => void
    ) => void;
    isPreviewMode: boolean;
}

interface IInvoiceStates {
    showDownloadModal: boolean;
    showPaymentPopup: boolean;
}

export class Invoice extends React.Component<IInvoiceProps, IInvoiceStates> {
    constructor(props: IInvoiceProps) {
        super(props);
        this.state = {
            showDownloadModal: false,
            showPaymentPopup: false,
        }
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        (this.props.requestPreviewDocuments && this.props.invoiceDocumentReviewModel.length === 0) && this.props.requestPreviewDocuments(param.clientId);
        logger.trackPageView('Review Invoice Page', { "ClientId": param.clientId, "PreviewMode": this.props.isPreviewMode });
    }

    getInvoiceDetails() {
        let document: IDocumentReviewModel;
        document = this.props.invoiceDocumentReviewModel.filter(x => x.documentGroup == DocumentGroups.Invoice)[0];
        return (<Viewer url={document && document.fileLink} />)
    }

    onDownloadClick = () => {
        this.setState({ showDownloadModal: true });
    }

    onHide = () => {
        this.setState({ showDownloadModal: false });
    }

    onClosePaymentModal = () => {
        this.setState({
            showPaymentPopup: false
        });
    }

    onPayNowClick() {
        const param: any = this.props.match.params;
        this.props.requestPaymentIntent(param.clientId, this.props.invoiceAmount, () => this.openStripePopup())
    }

    private openStripePopup() {
        this.setState({
            showPaymentPopup: true
        })
    }

    public render() {
        const param: any = this.props.match.params;

        return (
            <div style={{ height: '100%' }}>
                {
                    this.props.invoiceDocumentReviewModel.length == 0 ?
                        <Shimmer height={600} /> :
                        <StepLayout className={"no-padding"}
                            lg={12} md={12} sm={12} xs={12}
                            title={`Tax Preparation Fees`} 
                            showDownloadButton={this.props.showDownloadButton}
                            handleDownload={this.onDownloadClick}
                        >
                            <Body lg={12} md={12} sm={12} xs={12}
                                containerClassName={"row"}
                                automationTestId={"5EB8ED34-27C6-4E27-9CAE-57DFE471A146"}>
                                <div className="row" style={{ height: 'inherit' }}>
                                    <PdfViewer data-test-auto="2FFA8EF8-18C6-47F2-86C9-FE6F85B76C42">
                                        {this.getInvoiceDetails()}
                                    </PdfViewer>
                                </div>
                            </Body>
					<SelectiveDownload
                        showModal={this.state.showDownloadModal}
                        downloadableDocuments={this.props.ccRecipientDownloadableDocuments}
                        onHide={this.onHide}
                        downloadAllDocuments={this.props.downloadAllDocuments}
                        downloadSelectedDocuments={this.props.downloadSelectedDocuments}
                    />
                        </StepLayout>
                }
                {this.props.paymentData !== undefined && window.Variables.publicKey ?
                    <StripePayment
                        clientId={param.clientId}
                        showState={this.state.showPaymentPopup}
                        onCancel={this.onClosePaymentModal}
                        invoiceAmount={this.props.invoiceAmount}
                        paymentData={this.props.paymentData}
                        paymentSetting={this.props.paymentSetting}
                        updatePurchaseTransactionStatus={this.props.updatePurchaseTransactionStatus}
                    />
                    : ""}
            </div>
        );
    }
}