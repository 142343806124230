import { IDocumentSettings } from 'src/core/domain/models/IDocumentSettings';
import { ITaxReturn } from 'src/core/domain/models/ITaxReturn';
import { ICommonDataViewModel } from 'src/core/domain/viewModels/ICommonDataViewModel';
import { IPayScreenViewModel } from 'src/core/domain/viewModels/IPayScreenViewModel';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { IPaymentVoucherNotificationSettings } from '../../../core/domain/models/IDocumentSettings';
import { DocumentGroups, IVoucher, IGroup, VoucherTypes } from '../../../core/domain/models/IGroup';
import { ILoader } from '../../../core/utilities/ui/Loader';
import { container } from '../../../startup/inversify.config';
import { TYPES } from '../../../startup/types';
import { LeftPanel } from '../../Common/StepLayout/LeftPanel';
import PaymentVoucherDetails from "./PaymentVoucherDetails";
import PaymentVoucherPay from "./PaymentVoucherPay";
import { ITaxpayerPaymentSettings } from "../../../core/domain/models/company/Company";
import { logger } from '../../../routes';




export interface PayProps {
    commonData: ICommonDataViewModel,
    match: match,
    history: History,
    payScreenData: IPayScreenViewModel,
    documentSettings: IDocumentSettings,
    requestPayScreenDetails: (clientId: string) => any;
    downloadAllVouchers: (clientId: string) => void;
    downloadFilingInstructions: (clientId: string) => void;
    downloadTaxInvoice: (clientId: string) => void;
    downloadSingleVoucher: (clientId: string, fileName: string, pageNo: string, bookMarks: string) => void;
    prevStep?: () => void;
    nextStep?: (updated: boolean) => void;
    taxReturn: ITaxReturn,
    updateVoucherNotificationSettings?: (clientId: string, setting: IPaymentVoucherNotificationSettings, callback: () => void) => any;
    invoicePaymentUrl?: string;
    saveVoucherPayment: (voucher: IVoucher) => void;
    isPreviewMode: boolean;
    onSubmitPay?: () => void;
    clearPaymentDetails: (voucher: IVoucher) => void;
    setVoucherState?: (voucherState: string) => void;
    taxpayerPaymentSettings: ITaxpayerPaymentSettings;
    documentUpdated?: boolean,
    SetDocumentIsDownloaded?: (clientId: string) => void
}

interface PayState {
    groupedVouchers: { duedate: string, vouchers: IVoucher[] }[],
    selectedTab: string;
    selectedPaymentType: string;
    initialPaymentVoucherModel: IPaymentVoucherNotificationSettings;

}

const loader = container.get<ILoader>(TYPES.ILoader);

class PaymentVoucher extends React.Component<PayProps, PayState> {
    private clientId: string = "";
    private VoucherReminderDays: number = 0;
    private IsVoucherRemainderEnable: boolean = false;
    constructor(props: PayProps) {
        super(props);
        this.state = {
            groupedVouchers: [],
            selectedTab: "",
            selectedPaymentType: "",
            initialPaymentVoucherModel: { enablePaymentVoucherReminder: false, noOfDaysForVoucherReminder: 0 },
        }
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        logger.trackPageView('Payment Voucher Page', { "ClientId": param.clientId, "DocumentId": this.props.taxReturn.id, "PreviewMode": this.props.isPreviewMode });
        if (param.clientId && this.props.payScreenData.documentId === 0) {
            this.props.requestPayScreenDetails(param.clientId);
        }
        this.clientId = param.clientId;
        this.VoucherReminderDays = this.props.documentSettings?.documentNotificationSetting?.paymentVoucherNotificationSettingsModel?.noOfDaysForVoucherReminder;
        this.IsVoucherRemainderEnable = this.props.documentSettings?.documentNotificationSetting?.paymentVoucherNotificationSettingsModel?.enablePaymentVoucherReminder;
        this.setState({
            initialPaymentVoucherModel: this.props.documentSettings?.documentNotificationSetting?.paymentVoucherNotificationSettingsModel
        });
    }


    componentDidUpdate() {
        this.isValidVouchers(this.props.taxReturn);
    }


    componentWillReceiveProps(nextProps: PayProps) {
        if (nextProps && nextProps.documentSettings && nextProps.taxReturn.formGroups) {
            let reminderSettings = nextProps.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel;
            if (reminderSettings && (reminderSettings.enablePaymentVoucherReminder !== this.state.initialPaymentVoucherModel?.enablePaymentVoucherReminder ||
                reminderSettings.noOfDaysForVoucherReminder !== this.state.initialPaymentVoucherModel.noOfDaysForVoucherReminder)) {
                this.setState({
                    initialPaymentVoucherModel: nextProps.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel
                })
            }
            this.isValidVouchers(nextProps.taxReturn);
        }
    }

    isValidVouchers = (taxReturn: ITaxReturn) => {
        const formGroup: IGroup = taxReturn.formGroups.find(x => x.group === DocumentGroups.Vouchers) || {
            group: DocumentGroups.Vouchers,
            forms: []
        };
        let validVouchers = false;
        let vouchers: IVoucher[] = formGroup.forms as IVoucher[];
        for (var i = 0; i < vouchers.length; i++) {
            if (vouchers[i].amount > 0 && vouchers[i].paymentType === VoucherTypes.PaymentVoucher) {
                validVouchers = true;
                break;
            }
        }
        if (!validVouchers) {

            if (this.props.onSubmitPay) {
                this.props.onSubmitPay();
            }

        };
    }

    handleDueDateSelection = (selectedDate: string, selectedPaymentType: string) => {

        this.setState({ selectedTab: selectedDate, selectedPaymentType: selectedPaymentType });
    }

    updateVoucherReminderDetails = (callback?: () => void) => {
        let updated = false;
        const initialValue = this.state.initialPaymentVoucherModel
        let paymentVoucherDetails: IPaymentVoucherNotificationSettings = { ...initialValue };
        if (initialValue?.enablePaymentVoucherReminder !== this.IsVoucherRemainderEnable) {
            updated = true;
            paymentVoucherDetails.enablePaymentVoucherReminder = this.IsVoucherRemainderEnable;
        }
        if (this.IsVoucherRemainderEnable === true && this.state.initialPaymentVoucherModel.noOfDaysForVoucherReminder !== this.VoucherReminderDays) {
            updated = true;
            paymentVoucherDetails.noOfDaysForVoucherReminder = this.VoucherReminderDays;
        }
        if (updated) {
            loader.show();
            this.props.updateVoucherNotificationSettings && this.props.updateVoucherNotificationSettings(this.clientId, paymentVoucherDetails, () => {
                callback ? this.completePayStep(updated, callback) : this.completePayStep(updated);
                loader.hide();
            });
        }
        else {
            callback ? this.completePayStep(updated, callback) : this.completePayStep(updated);
        }

    }

    completePayStep = (updated: boolean, callback?: () => void) => {
        if (callback) {
            callback();
        }
        else {
            this.props.nextStep && this.props.nextStep(updated);
        }
    }

    HandleVoucherReminderEnable = (value: boolean) => {
        this.IsVoucherRemainderEnable = value;
    }
    HandleVoucherReminderDays = (value: number) => {
        this.VoucherReminderDays = value;
    }

    public render() {
        const params: any = this.props.match.params;

        return (<React.Fragment>
            <LeftPanel donotApplyDefaultWidth={true} className='voucher-payment-left-panel'>
                <PaymentVoucherPay
                    payScreenData={this.props.payScreenData}
                    documentDisplaySetting={this.props.documentSettings.documentDisplaySetting}
                    handleDueDateSelection={this.handleDueDateSelection}
                    taxReturn={this.props.taxReturn}
                    selectedTab={this.state.selectedTab}
                    paymentInstruction={this.props.payScreenData.paymentInstructions}
                />
            </LeftPanel>

            <div className="no-padding pay-content-right voucher-payment-container">
                <PaymentVoucherDetails
                    payScreenData={this.props.payScreenData}
                    documentNotificationSetting={this.props.documentSettings.documentNotificationSetting}
                    taxingAuthorities={this.props.commonData.taxingAuthorities}
                    selectedDate={this.state.selectedTab}
                    selectedPaymentType={this.state.selectedPaymentType}
                    taxReturn={this.props.taxReturn}
                    clientId={params.clientId}
                    downloadAllVouchers={this.props.downloadAllVouchers}
                    downloadFilingInstructions={this.props.downloadFilingInstructions}
                    downloadSingleVoucher={this.props.downloadSingleVoucher}
                    downloadTaxInvoice={this.props.downloadTaxInvoice}
                    HandleVoucherReminderEnable={this.HandleVoucherReminderEnable}
                    HandleVoucherReminderDays={this.HandleVoucherReminderDays}
                    invoicePaymentUrl={this.props.invoicePaymentUrl}
                    saveVoucherPayment={this.props.saveVoucherPayment}
                    isPreviewMode={this.props.isPreviewMode}
                    clearPaymentDetails={this.props.clearPaymentDetails}
                    taxpayerPaymentSettings={this.props.taxpayerPaymentSettings}
                    documentUpdated={this.props.documentUpdated}
                    SetDocumentIsDownloaded={this.props.SetDocumentIsDownloaded}
                />
            </div>
        </React.Fragment>);

    }
}

export default PaymentVoucher;