import * as React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { match } from 'react-router';
import { History } from 'history';
import { Link } from 'react-router-dom';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import { TYPES } from '../../startup/types';
import { container } from '../../startup/inversify.config';
import { IUtilities } from '../../core/utilities/Utilities';
import { Shimmer } from '../Common/Shimmer/Shimmer';
import {
    ArrowIcon,
    SvgIconAccountHeader,
    SvgIconCalendarHeader, SvgIconContactHeader
} from '../Common/Icons/SvgIcons';
import { validatePhone } from '../Common/Validations';
import { DelegateeAccountModal } from '../Delegatee/Account/DelegateeAccountModal';
import { ChangeNumber } from '../../components/Layout/ChangeNumber';
import { IDelegateeInfo } from '../../core/domain/models/IDelegateeSignerModel';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { DelegateeSignerConstants } from '../Common/Constants';
import { PathConstants } from '../Common/Constants';
import { handleKeyDown } from '../Helper/HelperFunction';
const utilities = container.get<IUtilities>(TYPES.IUtilities);


export interface DelegateeHeaderProps {
    delegateeHeaderInfo: IHeaderInfoViewModel;
    delegateeSigner: IDelegateeInfo;
    updateDelegateeMobileNumber: (clientId: string, countryCode: string, mobileNumber: string, callback?: () => void) => void;
    match: match;
    history: History;
    logout: (clientGuid: string, callback: (id: string) => void) => void;
}

export interface DelegateeHeaderState {
    headerInfo: IHeaderInfoViewModel,
    clientName: string,
    showMyAccountPopUp: boolean,
    showAccountPopover: boolean,
    showContactPopover: boolean,
    windowWidth: number
}

export class DelegateeHeader extends React.Component<DelegateeHeaderProps, DelegateeHeaderState> {
    popoverRef: any;
    contactRef: any;
    inputRef: any;

    constructor(props: DelegateeHeaderProps, states: DelegateeHeaderState) {
        super(props, states);
        this.state = {
            headerInfo: initialHeaderInfoViewModel,
            clientName: "",
            showMyAccountPopUp: false,
            showContactPopover: false,
            showAccountPopover: false,
            windowWidth: window.innerWidth
        }

        this.popoverRef = React.createRef();
        this.contactRef = React.createRef();
        this.inputRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps: DelegateeHeaderProps, prevState: DelegateeHeaderState) {
        if (nextProps.delegateeHeaderInfo && (nextProps.delegateeHeaderInfo.brandingSettings.coverPageSetting.bgColorCode !== prevState.headerInfo.brandingSettings.coverPageSetting.bgColorCode
            || nextProps.delegateeHeaderInfo.brandingSettings.coverPageSetting.foreColorCode !== prevState.headerInfo.brandingSettings.coverPageSetting.foreColorCode)) {
            utilities.applyBrandingSettings(nextProps.delegateeHeaderInfo.brandingSettings);
            window.Variables.companyName = nextProps.delegateeHeaderInfo.companyName;
            return {
                headerInfo: nextProps.delegateeHeaderInfo,
                clientName: nextProps.delegateeSigner.firstName + " " + nextProps.delegateeSigner.lastName
            }
        }
        else {
            return null;
        }
    }

    getMyaccount = () => {
        this.setState({
            showMyAccountPopUp: true,
        })
    }

    onCancelMyAccountPopUp = () => {
        this.setState({
            showMyAccountPopUp: false,
        })
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        document.addEventListener("mousedown", this.handleClickOutsideContact);
        document.addEventListener("keydown", this.handleEscapeKeyEvent);
        if (screen && screen.orientation && screen.orientation.addEventListener) {
            screen.orientation.addEventListener('change', this.handleOrientationChange);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.removeEventListener("mousedown", this.handleClickOutsideContact);
        document.removeEventListener("keydown", this.handleEscapeKeyEvent);
        if (screen && screen.orientation && screen.orientation.removeEventListener) {
            screen.orientation.removeEventListener('change', this.handleOrientationChange);
        }
    }

    handleOrientationChange = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    handleClickOutside = (event: any) => {
        if (this.popoverRef && this.state.showAccountPopover &&
            this.popoverRef.current && !this.popoverRef.current.contains(event.target)) {
            this.setState({ showAccountPopover: false });
        }
    }

    handleClickOutsideContact = (event: any) => {
        if (this.contactRef && this.state.showContactPopover &&
            this.contactRef.current && !this.contactRef.current.contains(event.target)) {
            this.setState({ showContactPopover: false });
        }
    }

    handleEscapeKeyEvent = (event: any) => {
        handleKeyDown(event, () => {
            if (this.state.showAccountPopover) {
                this.setState({ showAccountPopover: false });
            }
            if (this.state.showContactPopover) {
                this.setState({ showContactPopover: false });
            }
        }, "Escape")
    }

    onSaveDelegateeNumberClick = (changedMobileNumber: string, changedCountryCode: string) => {
        if (validatePhone(changedMobileNumber)) {
            const param: any = this.props.match.params;
            this.props.updateDelegateeMobileNumber(param.clientId, changedCountryCode, changedMobileNumber, () => {
                MarsNotifier.Success(DelegateeSignerConstants.SuccessMessage.UpdateMobileNumberSuccess, null);
            });
            this.setState({ showMyAccountPopUp: false });
        }
    }

    Logout = () => {
        let param: any = this.props.match.params;
        this.props.logout(param.clientId, this.onLogOutCompletion);
    }
    onLogOutCompletion = (id: string) => {
        const url = PathConstants.DelegateeLogin + id;
        this.props.history.push(url);
    }


    public render() {
        let param: any = this.props.match.params;
        let contactPersonFullName: string = "";
        const contactPerson = this.state.headerInfo.contactPerson;
        contactPersonFullName += contactPerson.firstName;

        const toggleMenuWidth = this.state.windowWidth > 0 ? this.inputRef.current?.getBoundingClientRect()?.width : 0;

        let companyLogo = null;
        if (this.state.headerInfo.companyWhiteLogoPath == "") {
            companyLogo = <span style={{ marginLeft: `${toggleMenuWidth > 0 ? '30px' : ''}` }} className={`company-name ${this.props.delegateeHeaderInfo.companyName.length > 50 ? "long-text" : ""}`}>{this.state.headerInfo.companyName}</span>;
        }
        else {
            companyLogo = <img style={{ marginLeft: `${toggleMenuWidth > 0 ? '30px' : ''}` }} className="logo-img" src={this.state.headerInfo.companyWhiteLogoPath} alt="company-white-logo" />;
        }

        if (contactPerson.middleName && contactPerson.middleName.length > 0) {
            contactPersonFullName += " " + contactPerson.middleName;
        }

        if (contactPerson.lastName && contactPerson.lastName.length > 0) {
            contactPersonFullName += " " + contactPerson.lastName;
        }

        const accountPopover = (
            <Popover id="accountPopover" placement="bottom-start">
                <div className="popover-body" ref={this.popoverRef}>
                    <div className="account-menu">
                        <div className='profile-name' title={contactPersonFullName}>
                            Hi, {contactPersonFullName}!
                        </div>
                        <div
                            className='my-account dropdown-item'
                            onClick={() => {
                                this.getMyaccount();
                                this.setState({ showAccountPopover: false });
                            }}
                            onKeyDown={(e: any) => handleKeyDown(e, () => {
                                this.getMyaccount();
                                this.setState({ showAccountPopover: false });
                            })}
                            data-test-auto="65AD7EA3-7B05-43C9-B862-F079DE711606"
                            tabIndex={4}
                        >
                            Profile
                        </div>
                        <hr className='dropdown-divider' />
                        <div
                            className='log-out dropdown-item'
                            onClick={() => this.Logout()}
                            onKeyDown={(e: any) => handleKeyDown(e, () => this.Logout())}
                            tabIndex={5}
                            data-test-auto="767609CB-7931-49FF-A431-CBF72F015D70"
                        >
                            Log Out
                        </div>
                    </div>
                </div>
            </Popover>
        );


        const contactInfoPopover = (
            <Popover id="contactInfoPopover" placement="bottom">
                <div ref={this.contactRef}>
                    <h3 className="popover-header">Contact Information</h3>
                    <div className="popover-body">
                        <div className='medium'>{contactPersonFullName} </div>
                        <div>
                            <strong>{utilities.formateFax(contactPerson.phoneNumber)}</strong>
                            {contactPerson && contactPerson.extension && contactPerson.extension.length > 0 && <strong> Ext: {contactPerson.extension}  </strong>}
                        </div>
                        <div className='mail'>
                            <a tabIndex={2} style={{ color: '#0973BA' }} href={"mailto:" + contactPerson.email}>{contactPerson.email}</a>
                        </div>
                    </div>
                </div>
            </Popover>
        );

        return (
            this.props.delegateeHeaderInfo.brandingSettings &&
                this.props.delegateeHeaderInfo.brandingSettings.coverPageSetting.bgColorCode != "" ?
                <header className="app-header">
                    <div className="header-left-container">

                        <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" aria-label='Toggle Side Menu' />
                        <label ref={this.inputRef} htmlFor="openSidebarMenu" className="sidebarIconToggle" aria-label='Side Menu Icon'>
                            <div className="spinner diagonal part-1"></div>
                            <div className="spinner horizontal"></div>
                            <div className="spinner diagonal part-2"></div>
                        </label>
                        <div id="sidebarMenu">
                            <ul className="sidebar navbar-nav">
                                <li className="nav-item layout-dropdown mobile-header-taxYear show" data-test-auto="7cf514cf-80fb-433d-b3fd-f04c41f5c053">
                                    <span className="nav-link layout-dropdown-toggle" role="button" data-toggle="layout-dropdown" aria-haspopup="true" aria-expanded="false" id="pageslayout-dropdown">
                                        <i className="fa fa-calendar margin-right-10"></i>
                                        <span>Tax Year</span></span>
                                    <div className="layout-dropdown-menu show" aria-labelledby="pageslayout-dropdown">
                                        <a className="layout-dropdown-item active" >{this.state.headerInfo.taxYear}</a>
                                    </div>
                                </li>
                                <li className="nav-item layout-dropdown show" data-test-auto="f9393d57-b0d1-4372-bcff-7a89d8863491">
                                    <span className="nav-link layout-dropdown-toggle" role="button" data-toggle="layout-dropdown" aria-haspopup="true" aria-expanded="false" id="pageslayout-account">
                                        <i className="fa fa-cog margin-right-10"></i>
                                        <span >Account</span></span>
                                    <div className="layout-dropdown-menu show" aria-labelledby="pageslayout-account">
                                        <a className="layout-dropdown-item" onClick={() => this.getMyaccount()} data-test-auto="0eb84df0-1d7b-4855-8e29-ed1fb26f1e67"><i className="fa fa-user ddl-icon headerForeColor"></i>My Account</a>
                                        <a className="layout-dropdown-item" onClick={() => this.Logout()} data-test-auto="8a7dd9bc-39be-4802-abf6-1df63c1a4652"><i className="fa fa-sign-out ddl-icon headerForeColor"></i>Logout</a>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {companyLogo}
                    </div>
                    <div className="header-right-container">

                        <span className="header-account">
                            <OverlayTrigger
                                rootClose trigger="click"
                                onEnter={() => document.body?.click()}
                                overlay={accountPopover}
                                placement="bottom"
                                show={this.state.showAccountPopover}>
                                <a
                                    tabIndex={3}
                                    onClick={() => this.setState({ showAccountPopover: !this.state.showAccountPopover })}
                                    onKeyDown={(e: any) => handleKeyDown(e, () => this.setState({ showAccountPopover: !this.state.showAccountPopover }))}
                                >
                                    <span style={{ fontWeight: '700' }}>{this.state.clientName}</span>
                                    <ArrowIcon fillColor="var(--headerForeColor)" />
                                </a>
                            </OverlayTrigger>
                        </span>

                        <span className="header-contact-info" title="Contact Person's Information">
                            <OverlayTrigger
                                rootClose trigger="click"
                                onEnter={() => document.body?.click()}
                                overlay={contactInfoPopover}
                                placement="bottom"
                                show={this.state.showContactPopover}
                            >
                                <a
                                    tabIndex={1}
                                    onClick={() => this.setState({ showContactPopover: !this.state.showContactPopover })}
                                    onKeyDown={(e: any) => handleKeyDown(e, () => this.setState({ showContactPopover: !this.state.showContactPopover }))}
                                >
                                    <SvgIconContactHeader
                                        fillColor="var(--headerForeColor)"
                                        height={20} />
                                    <span>Contact</span>
                                </a>
                            </OverlayTrigger>
                        </span>

                        <span className="header-taxYear">
                            <a>
                                <SvgIconCalendarHeader fillColor="var(--headerForeColor)" />
                                <span>Tax year: {this.state.headerInfo.taxYear}</span>
                            </a>
                        </span>

                        {
                            this.state.showMyAccountPopUp &&
                            <DelegateeAccountModal
                                delegateeSigner={this.props.delegateeSigner}
                                showState={this.state.showMyAccountPopUp}
                                onHide={this.onCancelMyAccountPopUp}
                                onSaveNumberClick={this.onSaveDelegateeNumberClick}
                            />
                        }

                    </div>
                </header> : <Shimmer height={55} />)
    }
}