import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { match } from 'react-router';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { SvgDownloadIcon, ArrowIcon } from '../Common/Icons/SvgIcons';
import { k1DownloadOptions } from '../Helper/HelperFunction';

interface DownloadK1sProps {
    match: match;
    onSelect: (selected: any) => void;
    taxReturn: ITaxReturn; 
}

export const DownloadK1s: React.FC<DownloadK1sProps> = (props:DownloadK1sProps) => { 
    const options = k1DownloadOptions();

    const handleChange = (item: any) => {
        props.onSelect(item);
    }
    return (
        <Dropdown>
            <Dropdown.Toggle
                size={'lg'}
                className={"k1-step-layout-dropdown"}
                variant="outline-primary"> 
                    <SvgDownloadIcon width={16} height={16} /> 
                <div className="k1-step-layout-text">
                    Download
                </div> 
                    <ArrowIcon fillColor="#0973ba" height={16} width={16} className="k1-arrow-button" /> 
            </Dropdown.Toggle>
            <Dropdown.Menu className="k1-step-layout-menu">
                {options.map((item, index) => {
                    return <Dropdown.Item as="button" key={item.value} onClick={() => handleChange(item)}>{item.label}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}