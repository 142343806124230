import React from "react";
import { Modal } from "react-bootstrap";
import { IMarriedJointTaxReturn } from "src/core/domain/models/ITaxReturn";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import { match } from "react-router";
import { ISignerModel } from "src/core/domain/models/ISignerModel";
import { isValidEmailAddress, validateCountryCode, validatePhone } from "../Common/Validations";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { SiginingConstants } from "../Common/Constants";
import { ClientType } from "src/core/common/Enums";
import { CloseIcon } from "../Common/Icons/SvgIcons";
import PhoneInput from "react-phone-input-2";
import { ButtonComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../Helper/HelperFunction";

export interface ISpouseSignRequiredProps {
    updateSpouseMailChange?(
        clientGuid: string,
        spouseGuid: string,
        id: string,
        mail: string,
        type: ClientType,
        step?: boolean,
        callback?: (step: boolean) => void
    ): void;
    updateSpouseMobileChange?(
        clientGuid: string,
        id: string,
        newMobileNumber: string,
        newCountryCode: string,
        ssn: string,
        type: ClientType,
        step?: boolean,
        callback?: (step: boolean) => void
    ): void;
    onCancel?: () => void;
    onContinue?: () => void;
    refreshTaxDocument?: (clientGuid: string) => void;
    taxReturn?: IMarriedJointTaxReturn;
    match: match;
    show: boolean;
    signerData: ISignerModel[];
    isPreviewMode: boolean;
}

export interface ISpouseSignRequiredState {
    spouseName?: string;
    spouseEmail?: string;
    spouseMobileNumber?: string;
    spouseCountryCode?: string;
}

export default class SpouseSignRequiredModal extends React.Component<
    ISpouseSignRequiredProps,
    ISpouseSignRequiredState
> {
    constructor(props: ISpouseSignRequiredProps) {
        super(props);
        const param: any = this.props.match.params;
        this.state = {
            spouseCountryCode: TaxpayerHelper.getSecondSignerMobileCountryCode(
                props.taxReturn,
                props.signerData,
                param.clientId
            ),
            spouseEmail: TaxpayerHelper.getSecondSignerEmail(props.taxReturn, props.signerData, param.clientId),
            spouseMobileNumber: TaxpayerHelper.getSecondSignerMobileNumber(
                props.taxReturn,
                props.signerData,
                param.clientId
            ),
            spouseName: TaxpayerHelper.getSecondSignerName(props.taxReturn, props.signerData, param.clientId)
        };
    }

    componentDidMount() {}

    componentDidUpdate(
        prevProps: Readonly<ISpouseSignRequiredProps>,
        prevState: Readonly<ISpouseSignRequiredState>,
        snapshot?: any
    ): void {
        if (prevProps.show !== this.props.show) {
            const param: any = this.props.match.params;
            this.setState({
                spouseCountryCode: TaxpayerHelper.getSecondSignerMobileCountryCode(
                    this.props.taxReturn,
                    this.props.signerData,
                    param.clientId
                ),
                spouseEmail: TaxpayerHelper.getSecondSignerEmail(
                    this.props.taxReturn,
                    this.props.signerData,
                    param.clientId
                ),
                spouseMobileNumber: TaxpayerHelper.getSecondSignerMobileNumber(
                    this.props.taxReturn,
                    this.props.signerData,
                    param.clientId
                ),
                spouseName: TaxpayerHelper.getSecondSignerName(
                    this.props.taxReturn,
                    this.props.signerData,
                    param.clientId
                )
            });
        }
    }

    private onSpouseMailChange = (e: any) => {
        this.setState({ spouseEmail: e.target.value });
    };

    private handleMobileChanges = (fullValue: string, countryObject: CountryData) => {
        this.setState({
            spouseMobileNumber: fullValue.slice(countryObject.dialCode.length),
            spouseCountryCode: `+${countryObject.dialCode}`
        });
    };

    private onSaveEmail = () => {
        const param: any = this.props.match.params;
        if (!this.props.isPreviewMode) {
            if (
                this.props.taxReturn &&
                TaxpayerHelper.isMarried(this.props.taxReturn) &&
                param.clientId == TaxpayerHelper.getFirstSignerId(this.props.taxReturn, this.props.signerData)
            ) {
                if (
                    !TaxpayerHelper.isNextSignerDeceased(this.props.taxReturn, this.props.signerData) &&
                    (!this.state.spouseEmail || !isValidEmailAddress(this.state.spouseEmail))
                ) {
                    MarsNotifier.Warning(SiginingConstants.WarningMessage.InvalidEmailAddress, null);
                    return;
                }

                if (this.state.spouseCountryCode.trim() !== "" || this.state.spouseMobileNumber.trim() !== "") {
                    if (
                        !validateCountryCode(this.state.spouseCountryCode) ||
                        !validatePhone(this.state.spouseMobileNumber)
                    ) {
                        return false;
                    }
                }
                if (
                    TaxpayerHelper.getSecondSignerEmail(this.props.taxReturn, this.props.signerData, param.clientId) !==
                    this.state.spouseEmail
                ) {
                    this.props.updateSpouseMailChange &&
                        this.props.updateSpouseMailChange(
                            param.clientId,
                            TaxpayerHelper.getSecondSignerGuid(
                                this.props.taxReturn,
                                this.props.signerData,
                                param.clientId
                            ).toString(),
                            TaxpayerHelper.getSecondSignerId(
                                this.props.taxReturn,
                                this.props.signerData,
                                param.clientId
                            ).toString(),
                            this.state.spouseEmail,
                            TaxpayerHelper.getSecondSignerType(
                                this.props.taxReturn,
                                this.props.signerData,
                                param.clientId
                            )
                        );
                }

                if (
                    TaxpayerHelper.getSecondSignerMobileNumber(
                        this.props.taxReturn,
                        this.props.signerData,
                        param.clientId
                    ) !== this.state.spouseMobileNumber ||
                    TaxpayerHelper.getSecondSignerMobileCountryCode(
                        this.props.taxReturn,
                        this.props.signerData,
                        param.clientId
                    ) !== this.state.spouseCountryCode
                ) {
                    this.props.updateSpouseMobileChange &&
                        this.props.updateSpouseMobileChange(
                            param.clientId,
                            TaxpayerHelper.getSecondSignerId(
                                this.props.taxReturn,
                                this.props.signerData,
                                param.clientId
                            ).toString(),
                            this.state.spouseMobileNumber,
                            this.state.spouseCountryCode,
                            TaxpayerHelper.getSecondSignerSSN(
                                this.props.taxReturn,
                                this.props.signerData,
                                param.clientId
                            ),
                            TaxpayerHelper.getSecondSignerType(
                                this.props.taxReturn,
                                this.props.signerData,
                                param.clientId
                            )
                        );
                }

                this.props.onContinue && this.props.onContinue();
                this.props.refreshTaxDocument(param.clientId);
            }
        } else {
            this.props.onContinue && this.props.onContinue();
        }
    };

    render() {
        return (
            <div>
                <Modal className="spouse-sign-required-modal" show={this.props.show} onHide={this.props.onCancel}>
                    <Modal.Header data-test-auto="3D98AA35-A613-41CC-BB62-196CFDD18308">
                        <Modal.Title>
                            <span>Spouse's Signature Required</span>
                        </Modal.Title>
                        <div
                            data-test-auto="827fcba4-d935-40b7-aeb4-eeaf18e6cd1c"
                            className="closeIcon"
                            onClick={this.props.onCancel}
                            tabIndex={0}
                            onKeyDown={(e: any) => handleKeyDown(e, this.props.onCancel)}
                        >
                            <CloseIcon />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="content-paragraph">
                            Please provide your spouse's email address. It can be the same as yours if you share an
                            email account. They will receive a similar request to sign their forms electronically.
                        </div>
                        <div data-test-auto="1E159A61-7FBA-4834-8C24-A699C22567FB">
                            {this.state.spouseName ? (
                                <>
                                    <div className="spouseField">
                                        <span>Spouse's Name: </span>
                                        <span className="spouseField-value">{this.state.spouseName}</span>
                                    </div>

                                    <div className="flex">
                                        <div className="spouseEmail">
                                            <label className="spouseEmail-label" htmlFor="spouseEmailAddress">
                                                Email<span className="asterisk">*</span>
                                            </label>
                                            <input
                                                id="spouseEmailAddress"
                                                className="form-control"
                                                type="text"
                                                data-test-auto="775EFAE8-8131-44B3-843E-D90FEED860DF"
                                                aria-label="Enter Spouse Email Address"
                                                value={this.state.spouseEmail}
                                                onChange={(e) => this.onSpouseMailChange(e)}
                                            />
                                        </div>

                                        <div className="spouseMobile">
                                            <label className="spouseMobile-label" htmlFor="spouseMobileNumber">Mobile</label>
                                            <div style={{ width: "225px" }} className="mobileSection">
                                                <PhoneInput
                                                    inputProps={{ id: "spouseMobileNumber" }}
                                                    containerClass={`inputCCMobile`}
                                                    country={"us"}
                                                    autoFormat={true}
                                                    placeholder="() -"
                                                    value={`${this.state.spouseCountryCode}${this.state.spouseMobileNumber}`}
                                                    onChange={this.handleMobileChanges}
                                                    data-test-auto="60C2F95D-3958-4C68-AB2D-ED470C2AA428"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonComponent
                            onClick={this.props.onCancel}
                            variant={"outline-tertiary"}
                            buttonClassName={"button-margin-right"}
                            size={"medium"}
                        >
                            Cancel
                        </ButtonComponent>

                        <ButtonComponent
                            onClick={this.onSaveEmail}
                            disabled={
                                (this.state.spouseEmail == null ||
                                    this.state.spouseEmail == undefined ||
                                    this.state.spouseEmail.trim() === "") &&
                                !this.props.isPreviewMode
                            }
                            variant={"primary"}
                            size={"medium"}
                        >
                            Continue
                        </ButtonComponent>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
