import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { CloseIcon } from '../../Common/Icons/SvgIcons';
import { ButtonComponent } from 'cp-common-ui-components';
import { handleKeyDown } from 'src/components/Helper/HelperFunction';
var htmlencode = require('htmlencode');

export interface AttachmentsInstructionProps {
    show: boolean;
    attachmentInstruction: string;
    fileName: string;
    closeAttachmentsInstructionModal: () => void;
}

export class AttachmentsInstruction extends React.Component<AttachmentsInstructionProps, {}>{
    
    constructor(props: any) {
        super(props);
    }

    handleClose = () => {
        this.props.closeAttachmentsInstructionModal();
    }

    public render() {

        return (
            <Modal show={this.props.show && this.props.show} id="attachmentInstructionPopup">
                <Modal.Header data-test-auto="11897DA0-02F5-45C6-9D29-E0C2F0C4E7C9">
                    <Modal.Title>
                        <div style={{ fontSize: "20px" }}>Note</div>
                        <div
                            data-test-auto="95613D09-8326-4FE6-B256-F30085B56534"
                            className="closeIcon"
                            onClick={this.handleClose}
                            tabIndex={0}
                            onKeyDown={(e: any) => handleKeyDown(e, this.handleClose)}
                        >
                            <CloseIcon />
                        </div> 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div data-test-auto="AAD25332-6C0A-46C0-88DA-6FD34DDD0F69">
                            <div dangerouslySetInnerHTML={{ __html: htmlencode.htmlDecode(this.props.attachmentInstruction) }}></div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonComponent
                        data-test-auto="B927BDC4-A00E-4A67-9D5D-F5CA7CC46BBB"
                        onClick={this.handleClose}
                        variant={"primary"}
                        size={"medium"}
                    >
                        OK
                    </ButtonComponent>
                </Modal.Footer>
            </Modal >

        )
    }
}