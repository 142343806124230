
import { IGroupedReturnTableModel } from '../../core/domain/models/groupedReturns/IGroupedReturnTableModel';
import { DocumentStatus, SignatureStatus } from '../../core/common/Enums';


export const getMFJTableData = (model: IGroupedReturnTableModel, index: number, ref: any) => {

    let names: string[] = model.name.split(",");
    let clientTypes: string[] | undefined = model.clientType?.split(",");
    let signatureStatus: string[] = model.signatureStatus.split(",");
    let disableActionsList: string[] = model.disableActions.split(",");
    let delegateeGuidList: any = model.delegateeGuid ? model.delegateeGuid.split(",") : model.delegateeGuid;
    let delegateeEmailList: any = model.delegateeEmail ? model.delegateeEmail.split(",") : model.delegateeEmail;
    let clientGuidList: any = model.clientGuid ? model.clientGuid.split(",") : model.clientGuid;


    let returnObj: any = {};
    if (clientTypes && clientTypes[0] === '1') {
        returnObj.name = names[0];
        returnObj.signatureStatus = getMFJSignatureStatus(signatureStatus[0], signatureStatus[1], model.documentStatus,ref);
        returnObj.disableActions = disableActionsList[0] === "1" ? true : false;
        returnObj.delegateeGuid = delegateeGuidList !== null ? delegateeGuidList[0] : delegateeGuidList;
        returnObj.delegateeEmail = delegateeEmailList !== null ? delegateeEmailList[0] : delegateeEmailList;
        returnObj.clientGuid = clientGuidList !== null ? clientGuidList[0] : clientGuidList;
    }
    else {
        returnObj.name = names[1];
        returnObj.signatureStatus = getMFJSignatureStatus(signatureStatus[1], signatureStatus[0], model.documentStatus,ref);
        returnObj.disableActions = disableActionsList[1] === "1" ? true : false;
        returnObj.delegateeGuid = delegateeGuidList !== null ? delegateeGuidList[1] : delegateeGuidList;
        returnObj.delegateeEmail = delegateeEmailList !== null ? delegateeEmailList[1] : delegateeEmailList;
        returnObj.clientGuid = clientGuidList !== null ? clientGuidList[1] : clientGuidList;

    }





    return {
        ...returnObj,
        taxYear: model.taxYear,
        nextPaymentDue: ref.state.paymentDueOptions ? ref.state.paymentDueOptions[index] : [],
        k1DistributionStatus: model.k1DisttributionStatus,
        invoiceStatus: index % 2 === 0,
        button: 'Actions',
        rowIndex: index,
        clientType: 1,
        isDocumentUpdated: model.isDocumentUpdated

    }
}




const checkSignCompletion = (currentSignState: number) => {

    return  currentSignState === SignatureStatus.ESigned || currentSignState === SignatureStatus.ManuallySigned || currentSignState === SignatureStatus.SignedAndESigned || currentSignState === SignatureStatus.Uploaded || currentSignState === SignatureStatus.ESignedInOffice;
}

export const getMFJSignatureStatus = (taxpayerSignatureStatus: string, spouseSignatureStatus: string, documentStatus: DocumentStatus,ref:any) => {
    let signStatus: string = '';

    let TPSignState = parseInt(taxpayerSignatureStatus);
    let SPSignState = parseInt(spouseSignatureStatus);

    if (documentStatus === DocumentStatus.USERSIGNED) {

        if (TPSignState === SignatureStatus.ESigned || SPSignState === SignatureStatus.ESigned) {
            signStatus = "E Signed";
        }
        if (TPSignState === SignatureStatus.ESignedInOffice || SPSignState === SignatureStatus.ESignedInOffice) {
            signStatus = "E-Signed – In Office";
        }
        else if (TPSignState === SignatureStatus.ManuallySigned || SPSignState === SignatureStatus.ManuallySigned) {
            signStatus = "Manually Signed";
        }
        else if (TPSignState === SignatureStatus.AwaitingESign || SPSignState === SignatureStatus.AwaitingESign) {
            signStatus = "Awaiting E-Sign";
        }
        else if (TPSignState === SignatureStatus.AwaitingUpload || SPSignState === SignatureStatus.AwaitingUpload) {
            signStatus = "Awaiting Upload";
        }
        else {
            signStatus = "Uploaded";
        }

        
    }
    else if (TPSignState === SignatureStatus.AwaitingESign || TPSignState === SignatureStatus.AwaitingUpload) {
        signStatus = ref.getSignatureStatus(TPSignState);
    }
    else if(TPSignState === SignatureStatus.SignatureStampingFailed || SPSignState === SignatureStatus.SignatureStampingFailed)
    {
        signStatus = "Signature Stamping Failed";
    }
    else if (checkSignCompletion(TPSignState)) {

        //check for Spouse
        if (checkSignCompletion(SPSignState)) {
            signStatus = ref.getSignatureStatus(SPSignState);
        }
        else {
                signStatus = "Partially Signed";
            }
    }

    return signStatus;
}



