import { Next } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Next';
import { Previous } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Previous';
import { CustomButton } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/CustomButton';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
import { ICompany } from '../../../core/domain/models/company/Company';
import { IK1ShareHolderDetails } from '../../../core/domain/models/IK1Distribute';
import * as TaxDocument from '../../../core/domain/models/ITaxReturn';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel';
import { IHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel';
import { ILoggedInUserInfo } from '../../../core/domain/viewModels/ILoggedInUserInfo';
import * as CompanyStore from '../../../store/Common/Company/CompanyStore';
import * as TaxDocumentStore from '../../../store/Common/TaxDocumentStore';
import * as K1DistributeStore from '../../../store/K1/K1DistributeStore';
import * as SignProcessStore from '../../../store/SignProcess/SignProcessStore';
import { PathConstants, getRoutePathUrl } from '../../Common/Constants';
import { Body } from '../../Common/StepLayout/Body';
import { Footer } from '../../Common/StepLayout/Footer';
import { StepLayout } from '../../Common/StepLayout/StepLayout';
import TaxpayerHelper from '../../Helper/TaxpayerHelper';
import { DistributeElectronic } from '../../K1Distribution/DistributeElectronic';
import { IDocumentSettings } from '../../../core/domain/models/IDocumentSettings';
import * as PreparerMessageStore from '../../../store/PreparerMessageStore';
import { storeControllerIdInMemory } from '../../../core/services/dataAccess/DataService.Axios';
import { Role } from '../../../core/common/Enums';
import * as TrialK1DistributeStore from '../../../store/K1/TrialK1DistributeStore';
import { PreviousButtonIcon } from "../../Common/Icons/SvgIcons";

type ElectronicDistributionProps = {
    taxReturn: TaxDocument.ITaxReturn,
    headInfoViewModel: IHeaderInfoViewModel,
    companyData: ICompany,
    match: match;
    history: History;
    k1Data: IK1ShareHolderDetails[];
    loggedInUserInfo: ILoggedInUserInfo;
    documentSettings: IDocumentSettings;
    requestMyDownload: (clientId: string) => void;
    isK1Loading: boolean;
}
    & typeof CompanyStore.actionCreators
    & typeof K1DistributeStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof SignProcessStore.actionCreators
    & typeof PreparerMessageStore.actionCreators
    & typeof TrialK1DistributeStore.actionCreators;

interface ElectronicDistributionState {
    isProcessCompleted: boolean;
    isPreviewMode: boolean;
    isTrialView: boolean;
    isClientView: boolean;
}


export class ElectronicDistribution extends React.Component<ElectronicDistributionProps, ElectronicDistributionState> {
    private _distributeElectronicRef: any = React.createRef();
    private _params: any;
    constructor(props: ElectronicDistributionProps) {
        super(props);
        this.state = {
            isProcessCompleted: false,
            isPreviewMode: false,
            isTrialView: false,
            isClientView: false
        }
    }

    componentDidMount() {
        const param: any = this.props.match.params;
        if (param && param.controllerId) {
            storeControllerIdInMemory(param.controllerId);
        }
        this.getSignProcessStatus(param.clientId);
        this.props.requestTaxDocument(param.clientId);
        this.props.requestCompanyDetails(param.clientId);
        this.props.requestDocumentSettings(param.clientId);
    }
    gotoCompletionWizard = () => {
        const param: any = this.props.match.params;
        this.props.history.push(getRoutePathUrl(PathConstants.CompletionWizard, param.clientId, param.controllerId));
    }

    gotoPreviousStep = () => {
        const param: any = this.props.match.params;
        this.props.history.push(getRoutePathUrl(PathConstants.DistributeK1, param.clientId, param.controllerId));
    }

    private getSignProcessStatus = (clientId: any) => {
        this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
    }

    private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
        this._params = this.props.match.params;
        this.setState({ 
            isClientView: TaxpayerHelper.isPreviewModeWithWritePermission(clientProcessState),
            isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState),
            isTrialView: TaxpayerHelper.isTrialView(),
        });
        const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId, this._params.controllerId);
        if (url && url.trim()) {
            this.props.history.push(url);
        }
        else {
            this.setState({ isProcessCompleted: true });
        }
    }

    public render() {
        const { isPreviewMode, isClientView } = this.state;
        const { companyData: { name } } = this.props;
        const role = this.props.headInfoViewModel ?.loggedInUserInfo ?.role;
        var downloadSelectedK1DocumentAsync= (role.toString() == Role[Role.CPA] || role == Role.CPA) ? 
            this.props.downloadSelectedK1DocumentForPreviewAsync : this.props.downloadSelectedK1DocumentAsync
        var downloadBulkSelectedK1DocumentAsync= (role.toString() == Role[Role.CPA] || role == Role.CPA) ?
            this.props.downloadBulkSelectedK1DocumentForPreviewAsync : this.props.downloadBulkSelectedK1DocumentAsync
        var downloadAllK1Document = (role.toString() == Role[Role.CPA] || role == Role.CPA) ? 
        this.props.downloadK1DocumentForPreviewAsync : this.props.downloadK1DocumentAsync
        return (
            (this.state.isProcessCompleted) &&
            <StepLayout className={"with-footer"}>
                <Body lg={12} md={12} sm={12} xs={12} bodyClassName={"no-padding"}
                    automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>
                    {this.props.taxReturn.formGroups.length > 0 &&
                        <DistributeElectronic
                            ref={(ins) => this._distributeElectronicRef = ins}
                            requestK1ShareHolderDetails={this.props.requestK1ShareHolderDetails}
                            k1Data={this.props.k1Data}
                            match={this.props.match}
                            taxReturn={this.props.taxReturn}
                            updateK1ShareHolderEmail={this.props.updateK1ShareHolderEmail}
                            updateK1ShareHolderDetail={this.props.updateK1ShareHolderDetail}
                            downloadK1DocumentAsync={downloadAllK1Document}
                            downloadSelectedK1DocumentAsync={downloadSelectedK1DocumentAsync}
                            downloadBulkSelectedK1DocumentAsync={downloadBulkSelectedK1DocumentAsync}
                            downloadIndividualK1DocumentAsync={this.props.downloadIndividualK1DocumentAsync}
                            sendMailToShareHolder={this.props.sendMailToShareHolder}
                            loggedInUserInfo={this.props.loggedInUserInfo}
                            companyName={name || ''}
                            isPreviewMode={isPreviewMode}
                            requestMyDownload={this.props.requestMyDownload}
                            isTrialView={this.state.isTrialView}
                            downloadAllK1DocumentsAsync={this.props.downloadAllK1DocumentsAsync}
                            isK1Loading={this.props.isK1Loading}
                            bulkSendMailToShareHolder={this.props.bulkSendMailToShareHolder}
                            isClientView={isClientView }
                        />
                    }
                </Body>
                <Footer lg={12} md={12} sm={12} xs={12}>
                    <div className={"steps-footer "}>
                        <Previous automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"}
                                text={"Back"} goToPreviousStep={this.gotoPreviousStep} isActive={true}
                                className="awesome-multi-steps-completed-btn-previous" previousButtonIcon={<PreviousButtonIcon />} />

                        <Next automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"}
                                text={"Done"} goToNextStep={this.gotoCompletionWizard} isActive={true}
                                className="awesome-multi-steps-btn-done" buttonVariant="success" buttonSize="medium" />                              
                    </div>
                </Footer>

            </StepLayout>);

    }
}
