import { logger } from "../../routes";

export class DisplayDownloadFile {

	public showFile = (blob: Blob, fileName: string) => {
		var newBlob = new Blob([blob], { type: blob.type })

		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(newBlob, fileName);
			return;
		}

		const data = window.URL.createObjectURL(newBlob);
		var link = document.createElement('a');
		link.href = data;
		link.download = fileName;
		link.target = "_blank";
		document.body.appendChild(link);
		link.click();
		logger.trackTrace("CC receipient linked clicked");
		setTimeout(function () {
			window.URL.revokeObjectURL(data);
			document.body.removeChild(link);
			logger.trackTrace("CC receipient link timeout");
		}, 100);
	}

	public directDownload = (url: string, fileName?: string) => {
		var link = document.createElement('a');
		link.href = url;
		link.target = "_blank";
		if (fileName) {
			link.download = fileName;
		}
		document.body.appendChild(link);
		link.click();
		setTimeout(function () {
			document.body.removeChild(link);
		}, 100);
	}
}