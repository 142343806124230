import * as React from "react";
import HeaderComp from "../../Header/HeaderComp";
import Skeleton from "react-loading-skeleton";
import Footer from "src/components/Layout/Footer";
import OtpInputControl from "src/components/Common/Controls/OTPInputControl";
import { SvgIconAccessCode } from "src/components/Common/Icons/BrandIcons";
import OTPEmail from "src/components/Common/OTP/OTPEmail";
import { DisplayError } from "src/components/Common/Constants";

export class EmailOTP extends React.Component<any, any> {
  continueRef: any = null;

  constructor(props: any) {
    super(props);
    this.state = {
      otpVal: "",
      continueState: false,
      isInitialOTPSent: false,
      otpError: {
        error: false,
        message: "",
      },
    };

    this.handleOTPChange = this.handleOTPChange.bind(this);
    this.requestCode = this.requestCode.bind(this);
    this.verifyOTP = this.verifyOTP.bind(this);
    this.selectPath = this.selectPath.bind(this);
    this.handleOTPVerification = this.handleOTPVerification.bind(this);
  }

  componentDidMount() {
      this.props.requestDelHeaderDetails(this.props.match.params.clientId);
      this.props.pageValidityDelegatee(this.props.match.params.clientId, null, this.handleError);
      this.props.delegateeClientInfo(this.props.match.params.clientId);
  }

  handleError = (result?: any) => {
    let errorDesc = result.errorDescription;
    let errorCode = result.errorCode;
    if (errorCode && DisplayError.includes(errorCode)) {
        this.props.history.push("/Error/" + errorDesc);
    } else {
      this.setState({
        otpError: { error: true, message: errorDesc },
        otpVal: "",
      });
    }
  };

  handleOTPChange(e: any) {
    let otpValue = e;
    if (
     otpValue.length <= 8 &&
      (!isNaN(otpValue) ||otpValue.length === 0)
    ) {
      if (otpValue.length === 8) {
        this.setState({ otpVal:otpValue, continueState: true });
      } else {
        this.setState({ otpVal:otpValue, continueState: false });
      }
    }
  }

  requestCode() {
    this.setState({ isInitialOTPSent: true });
    this.props.generateOTPDelegatee(this.props.match.params.clientId);
  }

  handleOTPVerification(guid: string) {
    console.log("in handleOTP");
    this.props.history.push(`/Delegatee/Initialize/${guid}`);
  }

  verifyOTP() {
    if (this.state.continueState) {
      this.props.verifyOTPDelegatee(
        this.state.otpVal,
        this.props.match.params.clientId,
        this.handleOTPVerification,
        this.handleError
      );
    }
  }

  selectPath(path: string) {
    if (path === "TextOTP") {
      let clientId: string = this.props.match.params.clientId;
      this.props.history.push(`/MobileOTP/Index/Delegatee/${clientId}`);
    }
  }

  render() {
    return (
      <>
        <HeaderComp
          headerInfo={this.props.headerInfoState}
          pathname={this.props.match.path}
          clientId={this.props.match.params.clientId}
          history={this.props.history}
          backButton={true}
          navigateLink="/CoverPage/Index"
        />
        {this.props.OTPPageState.loading === true ? (
          <Skeleton circle={false} height={"100vh"} width={"100vw"} />
        ) : (
          <OTPEmail
            match={this.props.match}
            history={this.props.history}
            headerInfoState={this.props.headerInfoState}
            OTPPageState={this.props.OTPPageState}
            otpVal={this.state.otpVal}
            continueState={this.state.continueState}
            isInitialOTPSent={this.state.isInitialOTPSent}
            otpError={this.state.otpError}
            handleOTPChange={this.handleOTPChange}
            requestCode={this.requestCode}
            verifyOTP={this.verifyOTP}
          />
        )}
        <Footer />
      </>
    );
  }
}
export default EmailOTP;
