
import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../Common/NotificationStore';
import { AppThunkAction } from '../index';
import { SignProcessSteps } from '../../components/Home/TaxpayerSignFlowManager';
import { IClientProcessViewModel, initialClientProcessModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { ErrorMessages } from '../../components/Common/Constants';
import { GroupCoverConstants,DisplayError } from '../../components/Common/Constants';



const clientData = {
	Name: "",
	companyName: ""
}


export interface IClientCoverState {
	companyName: string,
	logoPath: string,
	taxYear: number | null,
	engagementType: EngagementTypes | null,
	clientName: string,
	contactAddress: any,
	mobileVerification: any,
	loaderState: boolean,
	mfaSettings: any

}


interface RequestSignProcessAction {
	type: actionTypes.SIGN_PROCESS_REQUEST;
}
interface RequestCurrentStepAction {
	type: actionTypes.SIGN_PROCESS_STEP;
	data: IClientProcessViewModel;
}


interface ResponseSignProcessAction {
	type: actionTypes.SIGN_PROCESS_RESPONSE;
	data: number;
}

interface FailureSignProcessAction {
	type: actionTypes.SIGN_PROCESS_FAILURE;
	data: number;
}

interface UpdateLastVisitedStep {
	type: actionTypes.UPDATE_LAST_VISITED_PROCESS_STEP;
	data: SignProcessSteps;
}

export interface SignProcessState {
	data: number;
	clientprocessmodel: IClientProcessViewModel;
}

export const initialSignProcessState: SignProcessState = {
	data: 0,
	clientprocessmodel: initialClientProcessModel
}

interface CoverState {
	type: actionTypes.RECEIVE_CLIENT_DETAILS,
	data: typeof clientData
}

type KnownAction =
	DispatchAction |
	NotificationAction;

type DispatchAction =
	ResponseSignProcessAction
	| RequestSignProcessAction
	| FailureSignProcessAction
	| RequestCurrentStepAction
	| UpdateLastVisitedStep
	| CoverState


export enum EngagementTypes {
	None = 0,
	E1040 = 1,
	E1065 = 2,
	E1120 = 3,
	E1120S = 4,
	E1041 = 5,
	E1040NR = 6
}

export const initialCoverState: IClientCoverState = {
	companyName: "",
	logoPath: "",
	taxYear: null,
	engagementType: null,
	clientName: "",
	contactAddress: {},
	mobileVerification: null,
	loaderState: false,
	mfaSettings: null

}




export const actionCreators = {
	requestGroupClientCover: (clientId: string, handleError: () => void): AppThunkAction<any> => (dispatch, getState) => {
		dispatch({
			type: actionTypes.FETCH_GROUP_CLIENT_DETAILS
		})
		return initializeAxios().get<any>("api/GroupedReturns/Coverpage/GetClientInfo/" + clientId)
			.then(function (response: AxiosResponse<any>) {
				let result = response.data;
				if (result.isSuccess) {
					dispatch({
						type: actionTypes.RECEIVE_GROUP_CLIENT_DETAILS,
						data: result.data
					});
				}
				else {
					let errorDesc = result.errorDescription;
					let errorCode = result.errorCode;

					if (errorCode && DisplayError.includes(errorCode)) {
						dispatch({
							type: actionTypes.SET_ERROR_MSG,
							data: errorDesc
						});

						handleError();

					}
					else {
						dispatch({
							type: actionTypes.NOTIFICATION,
							statusMessage: errorDesc,
							statusType: StatusType.Error
						})

                    }

					
                }

				

			})
			.catch((e: any) => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: GroupCoverConstants.ErrorMessage.ServerError,
					statusType: StatusType.Error
				})
			}
			);

	},
	getGUID: (clientId: string, navigate: (guid: string) => void): AppThunkAction<any> => (dispatch, getState) => {
		return initializeAxios().get<any>("api/Coverpage/GetGUID/" + clientId)
			.then(function (response: AxiosResponse<any>) {
				let result = response.data;
				navigate(result);
			})
			.catch((e: any) => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: GroupCoverConstants.ErrorMessage.ServerError,
					statusType: StatusType.Error
				})
			}
			);

	}

}

export const reducer: Reducer<typeof initialCoverState> = (state: typeof initialCoverState = initialCoverState, incomingAction: Action) => {
	const action = incomingAction as any;
	const currentState = Object.assign({}, state);
	console.log("in reducers", action);
	switch (action.type) {
		case actionTypes.FETCH_GROUP_CLIENT_DETAILS:
			return ({ ...currentState, loaderState: true });

		case actionTypes.RECEIVE_GROUP_CLIENT_DETAILS:
			let data = action.data;

			currentState.companyName = data.companyName;
			currentState.logoPath = data.logoPath;
			currentState.taxYear = data.taxYear;
			currentState.engagementType = data.engagementType;
			currentState.clientName = data.clientName;
			currentState.contactAddress = data.contactAddress;
			currentState.loaderState = false;
			currentState.mfaSettings = data.mfaSettings
			currentState.mobileVerification = data.mobileVerification;

			return { ...currentState };
		default:
			return currentState || initialSignProcessState;
	}
}; 