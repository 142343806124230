import { IUserModel, initialUserModel } from "../models/IUserModel";
import { IBrandingSettings, initialBrandingSettings } from "../models/IBrandingSettings";
import { ITaxDocumentModel, initialTaxDocumentModel } from '../models/ITaxDocumentModel';
import { ILoggedInUserInfo, initialLoggedInUserInfo } from '../viewModels/ILoggedInUserInfo';

export interface IHeaderInfoViewModel {
	clientName: string,
	taxYear: number;
	companyName: string,
	companyLogoPath: string,
	companyWhiteLogoPath: string,
	contactPerson: IUserModel,
	brandingSettings: IBrandingSettings,
	data: ITaxDocumentModel,
	loggedInUserInfo: ILoggedInUserInfo
}

export const initialHeaderInfoViewModel: IHeaderInfoViewModel = {
	clientName: "",
	taxYear: 0,
	companyName: "",
	companyLogoPath: "",
	companyWhiteLogoPath: "",
	contactPerson: initialUserModel,
	brandingSettings: initialBrandingSettings,
	data: initialTaxDocumentModel,
	loggedInUserInfo: initialLoggedInUserInfo,
}